export enum PolicyStatusEnum {
    All = 0,
    Pending = 1,
    InProcess = 2,
    Approved=3,
    Rejected=4,
    Active=5,
    Expired=6,
    Return=7
}

export enum PolicyAndQuotationStatusEnum {
    All = 0,    
    NewRequest=1,
    Return=2,
    Resubmitted=3,
    WaitingForPayment=4,
    WaitingForApproval = 5,
    Active = 6,
    Rejected=7,
    Cancelled=8,
    Requested=9,
    Delivered=10,
    Expired=11
    
}