import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import {RequestAuthentication} from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

@Injectable({
    providedIn: 'root'
  })
  export class AccountService {
constructor(
    private jsonApiService: JsonApiService,
    private apiService: ApiService) {}
  
  
  /** Get DropDownList  from the server */
  GetDropDownList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
  }
 
  /** Get policyList  from the server */
  GeMyPolicyList(RequestAuthentication):Observable<any> {
      return this.apiService.post(config.apiTravel+"/GetMyPolicies",RequestAuthentication);
  }
  
  
  /** Get PolicyDetails  from the server */
  getPolicyDetails(RequestAuthentication):Observable<any> {
    return this.apiService.post(config.apiTravel+"/GetMyPoliciesDetails",RequestAuthentication);
  }
  /** Get PolicyHolder  from the server */
  GetPolicyHolder(RequestAuthentication):Observable<any> {
    return this.apiService.post(config.apiTravel+"/GetPolicyHolder",RequestAuthentication);
  }

  /** Get PolicyAssuredPerson  from the server */
  GetPolicyAssuredPerson(RequestAuthentication):Observable<any> {
    return this.apiService.post(config.apiTravel+"/GetPolicyAssuredPerson",RequestAuthentication);
  }

  /**  update PolicyDetails  from the server */
  updatePolicyDetails(RequestAuthentication):Observable<any> {
    return this.apiService.post(config.apiTravel+"/SetPolicyHolder",RequestAuthentication);
  }
  /** update PolicyAssuredPerson  from the server */
  updatePolicyAssuredPerson(RequestAuthentication):Observable<any> {
    return this.apiService.post(config.apiTravel+"/SetPolicyAssuredPerson",RequestAuthentication);
  }

  /** Verify mobile number during policy purchasing **/
  verifyMobileNumber(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/VerifyMobileNumber",request);
  }

  verifyMobileNumberWeb(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/VerifyMobileNumberWeb",request);
  }

  /**Resend otp **/
  resendOtp(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/ResendOtp",request);
  }

  /** Verify otp for mobile number verification during policy purchasing **/
  verifyOtp(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/VerifyOtp",request);
  }

  verifyOtpWeb(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/VerifyOtpWeb",request);
  }

  /** login during policy purchasing **/
  loginWithPolicyPurchase(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/LoginWithPolicyPurchase",request);
  }

  /** Update customer details **/
  updateCustomerDetails(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/UpdateCustomerDetails",request);
  }

  /** Forgot password during policy purchase **/
  forgotTravelPassword(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/ForgotTravelPassword",request);
  }

  forgotTravelPasswordWeb(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/ForgotTravelPasswordWeb",request);
  }

  /** My profile details **/
  myProfile(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/MyProfile", request);
  }

  /** Update my profile details **/
  updateMyProfile(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/UpdateMyProfile", request);
  }

  /** Update email **/
  updateEmail(UpdateEmailRequest): Observable<any> {
    return this.apiService.post(config.apiAuthentication +"/UpdateCustomerEmail", UpdateEmailRequest);
  }

  /** Update customer contact details **/
  updateContactDetails(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/UpdateCustomerContactDetails", request);
  }

  UploadProfileImage(formData: FormData): Observable<any> {

    return this.apiService.PostMultiPart(config.apiAuthentication + "/UploadProfileImage", formData);
}

  /** Get notification list **/
  getNotificationList(request): Observable<any> {
    return this.apiService.post(config.apiAuthentication+"/GetCustomerNotificationList",request);
  }

  /** Set notification seen **/
  SetNotificationSeen(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/SetNotificationSeen",request);
  }

  /** Send email for verification **/
  SentEmailForVerification(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/SendEmailForVerification",request);
  }

  /** Change mobile number  **/
  UpdateMobileNumber(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/UpdateMobileNumber",request);
  }

  /** Verify otp for mobile number verification during policy purchasing **/
  VerifyOtpForUpdateMobileNumber(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/VerifyOtpForUpdateMobileNumber",request);
  }

  /** Change customer password **/
  changeCustomerPassword(request):Observable<any>{
    return this.apiService.post(config.apiAuthentication+"/ChangeCustomerPassword",request);
  }

}