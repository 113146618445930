<!-- Modal content-->
<!-- <div [hidden]="(authActiveStepIndex!==1| async)"> -->



<!-- cc -->
<div class="emn-box popupall" *ngIf="authActiveStepIndex==1">
  <div class="modal-header">
    <h4 class="modal-title">{{'Enter_Mobile_Number_to_Continue' | translate}}</h4>
    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close"
      (click)="bsModalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img src="assets/img/new-close.svg" alt="" />
    </button>

  </div>

  <form action="javascript:void(0);" [formGroup]="verifyMobileForm" id="verify-form" class=""
    (ngSubmit)="verifyMobileNumber()" autocomplete="off">
    <div class="modal-body login_modal">
      <div class="form-group">
        <div class="">

          <label class="">{{'Mobile_No' | translate}}</label>
          <!-- <div class="col-md-3 pr0md" style="margin-bottom: 10px;">
            <select style="background: #f9f9f9;" class="form-control" id="drpcountrycode" name="cucountrycode" formControlName="CountryCodes_Fk_Id"
              [ngClass]="{ 'is-invalid': submitted && f.CountryCodes_Fk_Id.errors }">
              <option value="{{code.Value}}" *ngFor="let code of CountryCodeResult">{{code.Text}}</option>

            </select>
          </div> -->
          <!-- #cphone  -->

          <!--<div class="col-md-12 d-flex inpfcs" style="border-left: 1px solid transparent;direction: ltr;">
          
           <div class="">
            <select name="" style="min-width: 53px;padding: 6px;" class="form-control" disabled>
              <option value="">+ 965</option>
            </select>
          </div> 
           <input id="input1" type="text"   name="cphone"class="form-control" pattern="[0-9]*" inputmode="numeric" placeholder="{{'Enter_mobile_number'|translate}}" minlength="8" autofocus
              maxlength="12" formControlName="MobileNo" [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }" pattern="[0-9]*" inputmode="numeric" (keypress)="numberOnly($event)">
            </div> -->
            <div [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
              <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" 
              [tooltipField]="TooltipLabel.Name"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" 
              [selectFirstCountry]="false"
              [separateDialCode]="true"
              [enablePlaceholder]="false"	
              [maxLength]="12"
              (keypress)="firstWhiteSpace($event)" 
              [selectedCountryISO]="selectedCountryISO" [phoneValidation]="false" name="MobileNo" formControlName="MobileNo"
              
              [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }" >
            </ngx-intl-tel-input>
          </div>
       
          
        </div>
      </div>

      <div class="response" id="cresponse"
        style="text-align: left; margin: 0;   margin-bottom: 0px;color:red; font-size: 12px;">{{ErrorMessage}}</div>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary b1 detailsbtn " id="btn1" *ngIf="IsFormSubmit == false"
        style="background-color: #098b41;">{{'Continue'|translate}}</button>
      <a href="javascript:void(0)" class="btn btn-primary b1 detailsbtn processing" *ngIf="IsFormSubmit == true"
        style="background-color: #098b41;">{{'Continue'|translate}}</a>
    </div>

    <div class="clearfix mb30"></div>


    <div class="ftr-icon">
      <div class="text-center">
        <p style="color:#060606; font-size:15px;">{{'Already_have_an_account' | translate}} <a href="javascript:void();"
            style="color: #098B41;" (click)="gotostep('login')">{{'User_Login' | translate}}</a></p>
      </div>

      <div class="clearfix"></div>
      <!-- 
<div class="or-box ">
  <span>or</span>  
</div>


  <div class="sharemedia-icon">
    <a class="fb" href="javascript:void(0);"><i class="fa fa-facebook"></i></a>
    <a class="gp" href="javascript:void(0);"><i class="fa fa-google-plus"></i></a>
  </div> -->
    </div>
  </form>
</div>


<div hidden>
<div  class="emn-box popupall" *ngIf="authActiveStepIndex==2">

  <div class="modal-header">
    <h4 class="modal-title">
      <!-- <button type="button" class="close pull-left mr5" aria-label="Close" (click)="authActiveStepIndex=1">
            <i class="fa fa-angle-left" aria-hidden="true" style="font-size: 26px;position: relative;top: 2px;margin-right: 7px;"></i>        
          </button> -->
      {{'Verify_OTP' | translate}}
    </h4>
    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close"
      (click)="bsModalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img src="assets/img/new-close.svg" alt="" />
    </button>
  </div>
  <div class="vow-box">
    <app-verify-otp  [child]="ModalRequest" (messageEvent)="receiveMessage($event)"></app-verify-otp>
  </div>
</div>
</div>





<div class="emn-box popupall" *ngIf="authActiveStepIndex===3">
  <div class="modal-header">

    <h4 class="modal-title">{{'Please_log_in_to_your_account' | translate}} </h4>
    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close"
      (click)="bsModalRef.hide()">
      <img src="assets/img/new-close.svg" alt="" />
    </button>
  </div>

  <div class="vow-box">
    <app-login [child]="ModalRequest" (messageEvent)="receiveMessage($event)"></app-login>
  </div>



  <div class="ftr-icon">
    <div class="text-center">
      <p style="color:#060606; font-size:15px;">{{'Dont_have_an_account' | translate}} <a href="javascript:void();"
          style="color: #098B41;" (click)="gotostep('signup')">{{'signup' | translate}}</a></p>
    </div>

    <div class="clearfix"></div>

    <!-- <div class="or-box ">
    <span>or</span>  
  </div>
  
  
    <div class="sharemedia-icon">
      <a class="fb" href="javascript:void(0);"><i class="fa fa-facebook"></i></a>
      <a class="gp" href="javascript:void(0);"><i class="fa fa-google-plus"></i></a>
    </div> -->
  </div>




</div>


<div *ngIf="authActiveStepIndex===4">
  <!-- 
<div class="emn-box popupall"> 
              <div class="modal-header">
                  <h4 class="modal-title"> 
                    
                      <span class=" pull-left mr5" >
                          We’d like to know you a little better...
                      </span>
                    </h4> 
                    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close" (click)="bsModalRef.hide()">
                       <span aria-hidden="true">&times;</span> 
                      <img src="assets/img/new-close.svg" alt="">
                    </button>  
                </div>
                <div class="vow-box">
                  -->

  <app-customer-details [child]="ModalRequest"></app-customer-details>
  <!-- </div> -->
</div>

<div class="emn-box popupall" *ngIf="authActiveStepIndex===5">

  <div class="modal-header">
    <h4 class="modal-title">{{'Forgot_Password' | translate}}</h4>
    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close"
      (click)="bsModalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img src="assets/img/new-close.svg" alt="" />
    </button>
  </div>
  <div class="vow-box">
    <app-forgot-password [child]="ModalRequest" (messageEvent)="receiveMessage($event)"></app-forgot-password>
  </div>

  <div class="clearfix mb30"></div>


  <div class="col-md-12 text-center">
    <a href="javascript:void(0);" (click)="authActiveStepIndex=3"
      style="color: #006FFF;font-weight: 500;font-size: 15px;">{{'Back_to_Login' | translate}}</a>
  </div>

</div>
<div class="emn-box popupall" *ngIf="authActiveStepIndex===6">

  <div class="modal-header">
    <h4 class="modal-title">{{'Reset_Password' | translate}}</h4>
    <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close"
      (click)="bsModalRef.hide()">
      <!-- <span aria-hidden="true">&times;</span> -->
      <img src="assets/img/new-close.svg" alt="" />
    </button>
  </div>
  <div class="vow-box">
    <app-reset-password [child]="ModalRequest" (messageEvent)="receiveMessage($event)"></app-reset-password>
  </div>

  <div class="clearfix mb30"></div>


  <!-- <div class="col-md-12 text-center">
                          <a href="javascript:void(0);" (click)="authActiveStepIndex=3" style="color: #006FFF;font-weight: 500;font-size: 15px;">Back to Login</a>
                        </div> -->

</div>