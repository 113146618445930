import { Injectable ,LOCALE_ID, Inject} from '@angular/core'; 

@Injectable({
  providedIn: 'root'
})
export class LocaleService { 

    constructor(@Inject(LOCALE_ID) public  localeId: string) {

        // console.log(this.localeId);

    }

    public getCurrentLocale: number= (this.localeId=="ar"?2:1); 
   
}
