export class ComposeMailRequest
{
    LanguageTypeId: number;
    MailTypeId: number;
    Subject: string;    
    Body:string;
}

export class ReplyMailRequest
{
    MailConversationId: number;
    Body:string;
}