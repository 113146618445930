import { Component, TemplateRef, Input, Output, EventEmitter, OnInit,AfterViewInit, ViewChild, ElementRef  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, TravelService, CurrentLanguage } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryCodeResult } from '../../../../core/models/home/contact.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PlatformLocation } from '@angular/common';
import { debug } from 'util';
import { $ } from 'protractor';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-modal-mobile',
  templateUrl: './mobile-modal.component.html',
  styleUrls: ['./mobile-modal.component.css']
})
export class MobileModalComponent extends CurrentLanguage implements //AfterViewInit,
OnInit{//OnInit {
  //@ViewChild('cphone') cphoneElement: ElementRef;

 //@ViewChild('input1') phoneElement: ElementRef;
  rid: string;
  rtype: number;
  travelTo:number;
  policyid: number;
  loading=false;
  policytype: number;
  // @Input()child: {rid: string, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, VerifiedOtpRef: string, PolicyId: number};

  authActiveStepIndex = 1;/** 1 for verifyMobileStep, 2 for verifyOtpStep,*/


  submitted = false;
  showSuccess = false;
  IsFormSubmit = false;
 

  verifyMobileForm: FormGroup;
  request = { LanguageTypeId: 1, RequestId: "", RequestType: 0, CountryCodes_Fk_Id: 0, MobileNo: "", CountryDialCode:"" };
  CountryCodeResult: CountryCodeResult[];

  phonePattern = "^((\\+91-?)|0)?[0-9]{8,12}$";

  ModalRequest = { rid: "", rtype: 0, policytype: 0,travelTo:0, CountryCodes_Fk_Id: 0, CountryCode: "string", MobileNo: "", VerifiedOtpRef: "", PolicyId: 0 , EmailMobileNo: "",IsforgotPass:false, MobileObject: null }

  ErrorMessage = "";
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Kuwait
  ];
  selectedCountryISO:any=CountryISO.Kuwait;
  PhoneReg:RegExp= new RegExp("^[0-9]{8,12}$");
  constructor(
    public bsModalRef: BsModalRef,
    private location: PlatformLocation,
    //  public modalRef: BsModalRef,
    private accountService: AccountService,
    private travelService: TravelService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder, private router: Router) {
      super(translate);
    this.verifyMobileForm = this.verifyMobileFormValidation();
    // closes modal when back button is clicked
    location.onPopState(() => {

      // example for a simple check if modal is opened
      if (this.bsModalRef !== undefined) {
        //console.log('modal is opened - cancel default browser event and close modal');
        // TODO: cancel the default event

        // close modal
        this.bsModalRef.hide();
      }
      else {
        //console.log('modal is not opened - default browser event');
      }
    });
  }
  ngOnInit() { 
    
          this.ModalRequest.rid = this.rid;
          this.ModalRequest.rtype = this.rtype;
          this.ModalRequest.travelTo = this.travelTo;
          this.ModalRequest.policytype = this.policytype;
    this.getCountryCode();
   
   
  }


ngAfterViewInit() {
  setTimeout(()=>{
      document.getElementById("input1").focus();
  },100);
}
  // ngAfterViewInit() {
  //   alert(4);
  //   this.cphoneElement.nativeElement.focus();
  // }

  get f() { return this.verifyMobileForm.controls; }


  verifyMobileNumber() {
 
    this.submitted = true;
    this.request = this.verifyMobileForm.value;
    this.request.CountryCodes_Fk_Id = 1;
    this.request.RequestId = this.rid;
    this.request.RequestType = this.rtype;
    this.ModalRequest.IsforgotPass = false;
    this.ModalRequest.policytype = this.policytype;
    this.request.LanguageTypeId = this.appLangId;
    this.ModalRequest.MobileObject = this.verifyMobileForm.get("MobileNo").value;
    this.request.CountryDialCode = this.verifyMobileForm.get("MobileNo").value.dialCode;
    this.request.MobileNo = this.verifyMobileForm.get("MobileNo").value.number;
    if(this.request.MobileNo.length < 8 || this.request.MobileNo.length > 12 || !this.PhoneReg.test(this.request.MobileNo)) 
    {
      
      this.verifyMobileForm.controls['MobileNo'].setErrors({'incorrect': true});
    }   
    if (this.verifyMobileForm.invalid) {
      return;
    } else {
      this.IsFormSubmit = true;
      var result = this.accountService.verifyMobileNumberWeb(this.request);
      result.subscribe(resp => {
        if (resp.ResponseStatus == 1) {
          // this.emitPass.next();
          this.ModalRequest.rid = this.rid;
          this.ModalRequest.rtype = this.rtype;
          this.ModalRequest.travelTo = this.travelTo;
          this.ModalRequest.policytype = this.policytype;
          this.ModalRequest.CountryCodes_Fk_Id = resp.Response.CountryCodes_Fk_Id;

          this.ModalRequest.CountryCode = this.request.CountryDialCode;//"+965";//resp.Response.CountryCode;
          this.ModalRequest.MobileNo = resp.Response.MobileNo;
          this.ModalRequest.PolicyId = this.policyid;
          if (resp.Response.IsNumberExists) {
            //alert("login");
            this.authActiveStepIndex = 3 /* Show Login pop up */
          }
          else {
            // alert("verify-otp");
            this.authActiveStepIndex = 2; /* Show verify OTP pop up */

          }
          //this.router.navigate([ 'travel/buy/travelstep3', this.request.RequestId]);

        }
        else {
          //this.submitted = false;
          //this.IsFormSubmit = false;
          this.ErrorMessage = resp.ResponseMessage;
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);

        }
        this.submitted = false;
        this.IsFormSubmit = false;
      });
    }
  }

  gotostep(stepname: string) {
    this.submitted=false;
    switch (stepname) {
      case "login": {
        this.authActiveStepIndex = 3;
        break;
      }
      case "signup": {
        this.authActiveStepIndex = 1;
        this.selectedCountryISO=CountryISO.Kuwait;
       this.ngAfterViewInit();
        break;
      }
      default: {

        this.authActiveStepIndex = this.authActiveStepIndex;
      }
    }
    /** Reset Child view parameters..*/ 
    this.ModalRequest.rid=this.rid;
    this.ModalRequest.CountryCodes_Fk_Id = 0;
    this.ModalRequest.CountryCode = "";
    this.ModalRequest.MobileNo = "";
    /** Reset Enter Mobile Number screen..*/
    this.verifyMobileForm = this.verifyMobileFormValidation();
  }

  getCountryCode() {
    let request = {
      LanguageTypeId: 1,
      Flag: "CountryCode"
    }
    var result = this.travelService.GetDropDownList(request);
    result.subscribe(resp => {
      this.CountryCodeResult = resp.ResponseCollection;
    });
  }

  verifyMobileFormValidation() {
    return this.formBuilder.group({
      //CountryCodes_Fk_Id: ['1', Validators.required],
      //MobileNo: ['', [Validators.required, Validators.pattern(this.phonePattern)]]
      MobileNo: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }



  receiveMessage($event) {
  
    this.ModalRequest.rid = $event.rid;
    this.ModalRequest.VerifiedOtpRef = $event.VerifiedOtpRef;
    this.ModalRequest.PolicyId = $event.PolicyId;
    this.ModalRequest.rtype = $event.rtype;
    this.ModalRequest.PolicyId = $event.PolicyId;
    this.ModalRequest.travelTo = $event.travelTo;
    this.authActiveStepIndex = $event.authActiveStepIndex;
    this.ModalRequest.CountryCode = $event.CountryCode;
    if(this.authActiveStepIndex==5){
      this.ModalRequest.MobileNo=$event.MobileNo;
    }
    else{    
      this.verifyMobileForm.controls["MobileNo"].setValue($event.MobileNo);
     }
     if(!($event.MobileNo == null || $event.MobileNo == undefined || $event.MobileNo == "")){
      this.selectedCountryISO = $event.selectedISO;
     }  
     else{
      this.selectedCountryISO=CountryISO.Kuwait;
     }   
    //this.selectedCountryISO = $event.selectedISO;
    this.ModalRequest.IsforgotPass=$event.IsforgotPass;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  firstWhiteSpace(event: any) {
    
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }

  // onCountryCodeChange(){
  //   this.LoginWithNumberForm.patchValue({
  //     MobileNumber:""
  //   });
  // }

}
