import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
 
import { CurrentAccessToken } from '../classes';
// import { debug } from 'util';
// import { LayoutHeaderComponent } from '../../shared';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router, 
        private currentAccessToken:CurrentAccessToken

    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
        if (!this.currentAccessToken.isTokenExpired()) { 
            //  /** Reload user information... */
            // LayoutHeaderComponent.updateUserStatus.next(true); // here!
            return true;
          }
       
       /** not logged in so redirect to login page with the return url...*/ 
       this.router.navigate(['/auth/dologout'], { queryParams: { returnUrl: state.url } });
    return false;
    // return true;
    }
}