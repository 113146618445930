<div *ngIf="IsLoaded">
<div *ngIf="!IsShowAboutUsData && !showMergerData" class="container newacc" id="target21">
  <h2>{{Title}}</h2>
  <div class="panel-body">
    <!-- <div [innerHTML]="aboutDescription"></div> -->
    <div [innerHTML]="aboutDescription?(aboutDescription | safeHtml):''"></div>
  </div>
</div>
<div *ngIf="!IsShowAboutUsData && showMergerData" class="contactcontainer newacc" id="contactus">
    <h2>{{Title}}</h2>
  <div class="panel-body" *ngIf="aboutDescription">
    <div [innerHTML]="aboutDescription?(aboutDescription | safeHtml):''"></div>
  </div>
</div>

<div *ngIf="!IsShowAboutUsData && showMergerData" class="container newacc" id="visitus">  
  <div class="panel-body" id="visitussub">
    <div class="visitussubcls" [innerHTML]="aboutDescriptionData"></div>
  </div>
</div>

<div *ngIf="IsShowAboutUsData && !showMergerData" class="container newacc" id="target21">

  <div class="panel-body">
    <div>
      <h2 style="margin-left: -15px;margin-bottom: 7px;">Company Profile</h2>
      <p>KFH takaful was established as a Closed Joint-Stock Takaful Kuwaiti Insurance Company in 2007 with an
        authorized capital of KD 10 million.

        The work in KFH Takaful revolves around providing comprehensive, innovative and Sharia-compliant insurance
        services by examining customer needs and working to provide the best services through multiple distribution
        channels that serve customers on a large scale.</p>

      <h2 style="margin-left: -15px;margin-bottom: 7px;">Takaful Principle</h2>
      <p>The Takaful System is a Shariah-Compliant system in which the concept of Takaful is based on several
        principles, the most important of which is the joint solidarity between a number of individuals in their joint
        participation in the Takaful Program so that the group bears the costs incurred by one of its members when
        exposed to a risk.

        In this spirit, the Takaful Insurance comes to be a real embodiment of the idea of solidarity work and a
        developed model on the groundthat commensurate with the changes and developments in our contemporary life.</p>

      <h2 style="margin-left: -15px;margin-bottom: 7px;">Vision</h2>
      <p>Pioneering in providing insurance services in accordance with the provisions and principles of Islamic Law.</p>

      <h2 style="margin-left: -15px;margin-bottom: 7px;">Mission</h2>
      <p>Pioneering in providing insurance services in accordance with the provisions and principles of Islamic Law.</p>

      <h2 style="margin-left: -15px;margin-bottom: 7px;">Values</h2>
      <p>
        1. Trust and Credibility
        2. Innovation
        3. Excellence
      </p>
    </div>
  </div>
</div>
</div>
<div *ngIf="!IsLoaded">
  
</div>