import { Component, OnInit, OnDestroy } from '@angular/core';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { environment as env } from '@env/environment';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { BsModalService } from 'ngx-bootstrap';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  ActivatedRoute

} from '@angular/router';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { $ } from 'protractor';
import { CurrentAccessToken } from './core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Client'; loading = false;
  isArabic: boolean = false;
  location: Location;
  ismobile = false;
  mobileurl: string;
  IsPopupLoaded: boolean = false;
  //IsLoggedin: boolean = false;
  //isOpen: any = 0;
  //toDayDate = new Date();
  //localOnloadDate: any;
  //localOnloadPopup: any;
  constructor(
    public modalService: BsModalService,
    private _loadingBar: SlimLoadingBarService,
    private activerout: ActivatedRoute,
    private _router: Router, private translate: TranslateService,
    private currentAccessToken: CurrentAccessToken,//private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    this.isArabic = (this.translate.currentLang === env.lang.ArText);
    // subscribe to our router's event here
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);

      // if (_router.url.indexOf('/ismobile') >= 0 || _router.url.indexOf('/mobilereceipt') >= 0) {
      //   this.ismobile = true;
      // } else {
      //   this.ismobile = false;
      // }


      if (_router.url.indexOf('/mobilereceipt') >= 0) {
        this.ismobile = true;
      } else {
        this.ismobile = false;
      }

      if (_router.url.indexOf('aboutcms') >= 0) {

        this.ismobile = true;


      }



    });

    /** Capture language change event and reload your resources..*/
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      setTimeout(function () {
        /** Reload component...*/
        location.reload();
        // this.ngOnInit();
      }, 10);
    });



  }
  private _routerSub = Subscription.EMPTY;
  ngOnInit() {

    // console.log(env.production);

    if (env.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http://', 'https://');
      }
    }

    this._routerSub = this._router.events
      .filter(event => event instanceof NavigationEnd)
      // .do(event => console.log(event))
      .subscribe((value) => {
        /** Close all modal popups... */
        this.closeAllModals();
      });
    // if (!this.currentAccessToken.isTokenExpired()) {
    //   this.IsLoggedin = true;
    // }
    //
    // this.localOnloadPopup = localStorage.getItem("PopupOnLoaded");
    // this.localOnloadDate = localStorage.getItem("OnLoadedDate");
    // if (this.localOnloadPopup === 1 &&  this.localOnloadDate == String(this.toDayDate )) {
    //   this.IsPopupLoaded = true;
    // }
  }

  private closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
      this.modalService.removeBackdrop();
      document.getElementById('ins-body').classList.remove("modal-open");


    }
  }

  private setDefaultTranslation(): void {

    this.translate.setDefaultLang(env.lang.EnText);
    // const browserLang = this.translate.getBrowserLang();
    // this.translate.addLangs(['en', 'ar']);
    // this.translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    // if (['en', 'ar'].indexOf(this.translate.getBrowserLang()) > -1) {
    //   this.translate.setDefaultLang(this.translate.getBrowserLang());
    // } else {
    //   this.translate.setDefaultLang('en');
    // }
  }


  /**
    * This is used to intercept and show Loading bar based on the current state of our
    * Router navigation
    * @param {Event} event
    */
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this._loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this._loadingBar.complete();
    }

    // Set loading state to false in both of the below events to hide the loader in case a request fails
    if (event instanceof NavigationCancel) {
      this._loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this._loadingBar.stop();
    }
  }
  onActivate(event: any) {
    /** Scroll to top on route change...*/
    setTimeout(() => {
      window.scroll(0, 0);
    }, 100);
  }

  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
  CloseOnload() {

    this.IsPopupLoaded = true;
    //this.isOpen = 1;
    // localStorage.setItem('PopupOnLoaded', this.isOpen);
    // localStorage.setItem('OnLoadedDate', String(this.toDayDate));
  }

}