import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';


// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class TplMotorInsuranceService {
    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetDropDownList", RequestAuthentication);
    }

    /** Get Policy step1 information from the server */
    GetPolicyStep1(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetTplMotorInsurancePolicyStep1", Request);
    }

    GetPolicyPrice(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetTplPolicyPrice", Request);
    }

    /** Save Policy step1 information from the server */
    SavePolicyStep1(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/SaveTplMotorInsurancePolicyStep1", Request);
    }

    GetPolicyStep2(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetTplMotorInsurancePolicyStep2", Request);
    }

    SavePolicyStep2(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/SaveTplMotorInsurancePolicyStep2", Request);
    }

    GetPolicyStep3(request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetTplMotorInsurancePolicyStep3", request);
    }

    SavePolicyStep3(Request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/SaveTplMotorInsurancePolicyStep3", Request);
    }

    GetPolicyStep4(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiTplMotor + "/GetTplMotorInsurancePolicyStep4", request);
    }

    SaveTplMotorPolicyAttachmentInformation(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiTplMotor + "/SaveTplMotorPolicyAttachmentInformation", formData);
    }

    SaveTplMotorUploadDocumentDescription(damageRequest): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/SaveTplMotorUploadDocumentDescription", damageRequest);
    }

    /** GET addone from the server */
    GetTplMotorPolicyReviewPolicyInformation(request): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/GetTplMotorPolicyReviewPolicyInformation", request);
    }

    CreateCustomerPolicy(policyPaymentRequest): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/CreateCustomerPolicy", policyPaymentRequest);
    }

    GetCustomerTplMotorPolicyDetails(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiTplMotor + "/GetCustomerTplMotorPolicyDetails", RequestAuthentication);
    }
    GetManuFacturerDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiShared + "/GetTplBrandDropDownList", RequestAuthentication);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyPaymentRequest): Observable<any> {
        return this.apiService.post(config.apiTplMotor + "/CreatePreCustomerPolicy", policyPaymentRequest);
    }

    GetPolicyReceipt(RequestAuthentication): Observable<any> {
            
        return this.apiService.PostMultiPart(config.apiTplMotor + "/GetPolicyReceipt", RequestAuthentication);
      }

    GenerateIssueCertificate(request): Observable<any> {
            
     return this.apiService.PostMultiPart(config.apiTplMotor + "/GenerateCertificate", request);
    }

    GetReceiptOnMail(RequestAuthentication): Observable<any> {      
        return this.apiService.PostMultiPart(config.apiTplMotor + "/GetReceiptOnMail", RequestAuthentication);
      }

      
}