import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, TravelService, CurrentLanguage } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService, ILoginContext } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LayoutHeaderComponent } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { PasswordValidators } from '@app/shared/PasswordValidators';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends CurrentLanguage implements OnInit {
  isShowError = true;
  isShowSuccess = true;
  submitted = false;
  error: string;
  isLoading: boolean;
  resetPasswordForm: FormGroup;
  returnUrl: string;
  ErrorMessage = "";
  SuccessMessage = "";
  
  Password: string = '';
 showPassword: boolean = false;
 ConformshowPassword: boolean = false;
 StrongPasswordRegx: RegExp = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-])(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;

  @Input() child: { rid: string, rtype: number, travelTo: number, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, VerifiedOtpRef: string, PolicyId: number, EmailMobileNo: string, IsforgotPass: boolean };
  @Output() messageEvent = new EventEmitter<any>();
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrService,
    private localize: LocalizeRouterService,
    private translate: TranslateService
  ) {
    super(translate);
    
  }

  ngOnInit() {
    this.buildForm();
  }
  get f() { return this.resetPasswordForm.controls; }
  resetpassword() {
   
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      if (this.f.Password.value === this.f.ConfirmPassword.value) {

        // const credentials = this.loginForm.value;

        let credentials = {
          AuthenticationKey: this.child.VerifiedOtpRef,
          Password: this.f.Password.value,
          ConfirmPassword: this.f.ConfirmPassword.value,
          LanguageTypeId : this.appLangId
        };

        this.isLoading = true;
        var result = this.authService.ResetPassword(credentials);

        result.subscribe(resp => {

        if(resp.ResponseStatus==10){
        this.ErrorMessage = this.translate.instant('Invalid_password_formate');
        this.toastr.error(this.ErrorMessage);
        setTimeout(() =>{
          this.ErrorMessage = "";
          this.isShowError = true;
          this.isLoading = false;
          }, 4000);
        return false;
          }
        else{
          if (resp.ResponseStatus == 1) {
          
            
            this.isLoading = false;
            this.SuccessMessage = this.translate.instant('Password_reset_successfully');
            this.toastr.success(this.SuccessMessage);

            this.submitted = false;
            this.isShowSuccess = false;
            this.bsModalRef.hide();
            /** Reload user information... */
            LayoutHeaderComponent.updateUserStatus.next(true);
            if (this.child.PolicyId > 0) {
              let translatedPath: any = this.localize.translateRoute('/travel/buy/travelstep2');
              this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid, this.child.CountryCodes_Fk_Id, this.child.MobileNo, '1', this.child.travelTo]);
              // this.router.navigate(['travel/buy/travelstep2', this.child.PolicyId, this.child.rid, this.child.CountryCodes_Fk_Id, this.child.MobileNo, '1', this.child.travelTo]);
            }
            else {
              let translatedPath: any = this.localize.translateRoute('/account/dashboard');
              this.router.navigate([translatedPath]);
            }
            this.buildForm();
          }
          else {
            this.isLoading = false;
            this.isShowError = false;
            this.ErrorMessage = resp.ResponseMessage;
            this.toastr.error(resp.ResponseMessage);
            this.submitted = false;
            // setTimeout(() => {
            //   this.ErrorMessage = "";
            //   this.isShowError = true;
            // }, 4000);
          }
        }
        });
      } else {
        this.isShowError = false;
        this.ErrorMessage = this.translate.instant('Password_and_confirm_password_does_not_matched');
        this.toastr.error(this.ErrorMessage);
        setTimeout(() =>{
          this.ErrorMessage = "";
          this.isShowError = true;
          }, 4000);
        return false;
      }
    }
  }



  get requiredValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("required");
  }

  get minLengthValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.resetPasswordForm.controls["Password"].hasError("requiresSpecialChars");
  }

  private buildForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      // Password: [Validators.required, Validators.pattern(this.StrongPasswordRegx)],
      // Password: ['', Validators.required, Validators.pattern(this.StrongPasswordRegx)],
      Password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
          requiresDigit: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
          requiresUppercase: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
          requiresLowercase: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
          requiresSpecialChars: true
        })
      ])],
      ConfirmPassword: ['', Validators.required]
    });
  }

  get passwordFormField() {
    return this.resetPasswordForm.get('Password');
  }

  togglePasswordVisibility() {
     
    this.showPassword = !this.showPassword;
    
  }

  
   ConformtogglePasswordVisibility() {
  
   this.ConformshowPassword = !this.ConformshowPassword;
   
 }




  
}
