

  <!-- Modal content-->

  
<div class="emn-box popupall">
    
    <div class="modal-header">
        <h4 class="modal-title"> 
          
            <span class=" mr5" >
                <!-- <i class="fa fa-angle-left" aria-hidden="true" style="font-size: 26px;position: relative;top: 2px;margin-right: 7px;"></i> -->
                {{'Please_Create_Your_Account' | translate}} 
            </span>
          </h4> 
          <button style="position: absolute;right: 7px;top: 8px;" type="button" class="close optn1" aria-label="Close" (click)="bsModalRef.hide()">
            <!-- <span aria-hidden="true">&times;</span> -->
            <img src="assets/img/new-close.svg" alt="" />
          </button> 
          <div style="margin: 15px 0px 0px 0px; font-weight: 500;direction:ltr;">{{displayMobileNo}}</div> 
      </div>
      <div class="vow-box">
  <!-- <jw-modal id="custom-modal-2">
      <div class="modal-content23">
        <div class="modal-header34">
          <h4 class="modal-title">Kindly Fill Your Details</h4>
        </div> -->
 


       
          <form action="javascript:void(0);" [formGroup]="customerDetailsForm" id="customer-details-form" class="" (ngSubmit)="updateCustomerDetails()" autocomplete="off">  
              <div class="modal-body modal-body34">
                <div class="form-group">
              <div class="row">
                <div class="col-md-12">
                  <label>{{'Enter_name' | translate}}</label>
                  <input type="text" name="cpname" maxlength="50" class="form-control" placeholder="" formControlName="Name" [ngClass]="{ 'is-invalid': submitted && f.Name.errors }" autocomplete="off">
                </div>
              </div>
            </div>
            
              <div class="form-group">
              <div class="row">
                  <div class="col-md-12">
                    <label>{{'Enter_email_id' | translate}}</label>
                    <input type="text" name="cpemail" class="form-control" placeholder="" formControlName="EmailId" [ngClass]="{ 'is-invalid': submitted && f.EmailId.errors }" autocomplete="off">
                  </div>
                </div>
            </div>


            <div class="form-group">
                <div class="row">
                    <div class="col-md-12">
                      <label>{{'Reg_Enter_password' | translate}}</label>

                      <input type="{{showPassword ? 'text' : 'Password' }}" name="cppassword"  maxlength="15" class="form-control" placeholder="" formControlName="Password" [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" autocomplete="new-password">
                    </div>
                    <!-- <button (click)="togglePasswordVisibility()"  class="eye_icon">
                      <i class="fa" [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"></i>
                    </button> -->
                    <span class="input-group-text" class="eye_icon">
                      <i  class="fa" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"   (click)="togglePasswordVisibility()" ></i>
                    </span>

                  </div>
              </div>

            <div class="response" id="cresponse" style="text-align: left;color:red;font-size: 12px;position: absolute; top: -100px;">{{ErrorMessage}}</div>

            <div  *ngIf="passwordFormField?.dirty" class="invalid-feedback">
              <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
                <!-- minlength -->
                <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }" class="valid_pass">
                  <i [attr.class]="minLengthValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                  {{'Six_characters_long' | translate}}
                </div>
    
                <!-- requires digit --> 
                <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }" class="valid_pass">
                  <i [attr.class]="requiresDigitValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                  {{'One_number' | translate}}
                </div>
    
                <!-- requires uppercase -->
                <div [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }" class="valid_pass">
                  <i [attr.class]="requiresUppercaseValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                  {{'One_upper_case_letter' | translate}}
                </div>
    
                <!-- requires lowercase -->
                <div [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }" class="valid_pass">
                  <i [attr.class]="requiresLowercaseValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                  {{'One_lower_case_letter' | translate}}
                </div>
    
                <!-- requires special characters -->
                <div [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }" class="valid_pass">
                  <i [attr.class]="requiresSpecialCharsValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                  {{'One_special_character' | translate}}
                </div>
              </div>
            </div>




            <!-- <div class="passwrd_wrap">

              <ul class="text-red-400 text-sm passwrd_verfy" *ngIf="passwordFormField?.dirty" >
    
                <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('.{6,}')}">{{'Password_Lenght'|translate}}</li>
             
                <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[a-zA-Z])')}">{{'Password_char'|translate}}</li >
             
                <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(.*[0-9].*)')}">{{'Password_digit'|translate}}</li>
             
                <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">{{'Password_special_character'|translate}}</li>
             
        
              </ul>
            </div> -->


            

            
           
        </div>
        <div class="clearfix mb10"></div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary b1 detailsbtn" id="btn1" *ngIf="IsFormSubmit == false" style="background-color: #098b41;">{{'Continue' | translate}}</button>
            <a href="javascript:void(0)" class="btn btn-primary b1 detailsbtn processing" *ngIf="IsFormSubmit == true" style="background-color: #098b41;">{{'Processing' | translate}}</a>
              
        </div>



      </form>  
    </div>
  </div>
      <!-- </div>      
    </jw-modal> -->