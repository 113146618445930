import { NumberFormatStyle, NumberSymbol } from '@angular/common';

export class CarInsurancePolicyRequest {
    LanguageTypeId: number;
    Customer_Id: number;
    RequestedId: number;
    Brands_Fk_Id: number;
    Models_Fk_Id: number;
    YearOfManufacturing: number;
    InsuranceType_Fk_Id: number;
}

export class CarPolicyPlansList {
    public Id: number;
    public Models_Fk_Id: number;
    public PlanName: string;
    public Cost: number;

}

export class CarAddons {
    public Id: number;
    public AddOnName: String;
    public CostType: String;
    public Cost: number;
    public IsChecked: boolean;
}
export class CarPolicyPrices {
    public PolicyPrice: number;
    public ExtraAssuredMembersPrice: number;
    public TotalPolicyPrice: number;
    public SupervisionFees: number;
    public IssueFees: number;
    public ExtraFees: number;
    public NetPrice: number;
    public Haserror: boolean;
    public ErrorMsg: string;
}
export class CarPolicyStep2 {
    LanguageTypeId: number;
    public PolicyId: number;
    public RequestedId: String;
    public PolicyStartDate: string;
    public CarPolicyPlanId: number;
    public adOns: CarAdOns[];
}

export class CarAdOns {
    public Id: number;

}

export class CarPolicyStep3 {

    public PlanType: string;
    public PurchaseDate: Date;
    public PolicyStartDate: Date;
    public PolicyExpiryDate: Date;
    public Name: string;
    public EmailId: string;
    public MobileNo: string;
    public BrandName: string;
    public ModelName: string;
    public Cost: number;
    public NetPrice: number;
    public YearOfManufacturing: number;
    public SupervisionFees: number;
    public IssueFees: number;
    public ExtraFees: number;
    
public IsCouponAvailable:number; 
public CouponCode: string;
public CouponDiscount:number;
    public PolicyPremium: number;
    public TotalAddOnsPrice: number;
    public CarPolicyAddOnList: CarPolicyAddOnDetails[] = [];
    public PaymentModeList: CarPaymentMode[]
}

export class CarPaymentMode {
    public PaymentModeId: number;
    public PaymentModeType: string;

}
export class CarPolicyAddOnDetails {

    public AddOnName: string;
}

export class CarPolicyStep4 {
    LanguageTypeId: number;
    public RequestedId: String;
    public PaymenType: number;

}

export class CarPolicysuccess {
    TransactionNo: string;
    PolicyNo: string;
    Name: string;
    MobileNo: string;
    EmailId: string;
    NetPrice: number;
    PolicyStatus: string;
    PaymentMode: string;
}

export class CarPolicyInsuranceCoverage {
    public Id: number;
    public PolicyNo: string;
    public PolicyName: string;
    public Date: Date
    public TransactionNo: string;
    public RefrenceNo: string;
    public PaymentType: string;
    public OnlyPolicyPrice: number;
    public NetPrice: number;
    public TotalAddonPrce: number;
    public SupervisionFees: number;
    public IssueFees: number;
    public ExtraFees: number;
    public AddOn: CarAddons[];
    public IssueCertificate: string;
    public IssueCertificateUrl: string;
}

export class CarPolicyHolder {
    LanguageTypeId: number;
    Id: number;
    CustomerPolicy_Fk_Id: number;
    PolicyId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    CivilId: string;
    DOB: string;
    CountryCodes_Fk_Id: number;
    MobileNumber: string;
    EmailAddress: string;
    Occupation: string;
    Address: string;
    City: string;
    CompleteStatus: boolean;
    Nationality: string;
}

export class CarPolicyAssuredPerson {
    LanguageTypeId: number;
    public Id: number;
    public CustomerPolicy_Fk_Id: number
    public FirstName: string;
    public Relation: string;
    public PassportNumber: string;
    public CivilId: string;
    public DOB: string;
    public IsPolicyHolder: boolean;
}

export class CarAssuredPersonRequest {
    LanguageTypeId: number;
    public Id: number;
    public PolicyId: number
    public CustomerId: number
    public Brand_Fk_Id: number;
    public Models_Fk_Id: number;
    public ModelNo: number;
    public EngineNo: string;
    public CC: string;
    public FuelType_Fk_Id: number;
    public ManufacturingYear: string;
    public RegistrationNo: string;
    public RegistrationDate: string;
    public RegisteredCity: string;
    public VehicleManufacture: string;
    public VehicleShape: string;
    public VehicleColor: string;
    public NumberOfPassengers: number;
    public PurposeOfLicense: string;
    public ChasisNumber: string;
    public CurrentCarValue: number;
}

export class CarPolicyRequiredDocuments {
    public Id: number;
    public AttachmentNo: string;
    public CivilName: string;
    public Document: string
    public DocumentUrl: string;
    public IssueDate: Date;
    public ExpiryDate: Date;
    public RemindMeB4Expiry: boolean;
    public IsCheckedAndApproved: boolean;
    public Status: number;

}


export class CarRequiredDocumentRequest {
    public Id: number;
    public IssueDate: string;
    public ExpiryDate: string;
    public PolicyRequiredDocumentList_Fk_Id: number;
    public UploadRequiredFile: File;
    public CustomerPolicy_Fk_Id: number;
    public InsuranceType_Fk_Id: number;
}

export class SetCarRequiredDocumentRequest {
    public LanguageTypeId: number;
    public PolicyAttachmentId: number;
    public UploadRequiredFile: File;
    public CustomerPolicy_Fk_Id: number;
    public RequiredAttachment_Fk_Id: number;
    public Flag: string;
}

export class CarPolicyDocumentRequest {
    Id: number;
}








/** Model classes for new car policy after rejection of previous (28032019) demo **/
export class PersonalInformationResult {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    CivilId: string;
    Address: string;
    MobileNo: string;
    EmailId: string;
    IsRejectFirstName: boolean;
    IsRejectMiddleName: boolean;
    IsRejectLastName: boolean;
    IsRejectMobileNo: boolean;
    IsRejectEmailId: boolean;
    IsRejectCivilId: boolean;
    IsRejectAddress: boolean;
}

export class CarPolicyPersonalInformationRequest {
    LanguageTypeId: number;
    InsuranceTypeId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    CivilId: string;
    Address: string;
    MobileNo: string;
    EmailId: string;
    BatchNo: string;
}

export class CarInformationResult {
    CarNumber: string;
    Brand_Fk_Id: number;
    BrandName: string;
    Model_Fk_Id: number;
    VehicleShape_Fk_Id: number;
    FuelType_Fk_Id: number;
    PurposeOfLicense_Fk_Id: NumberSymbol;
    NoOfPassenger: number;
    ModelNo: number;
    //ModelsName: string;
    ChasisNumber: string;
    CurrentCarValue: number;
    Color: string;
    IsBrandReject: boolean;
    IsCarTypeReject: boolean;
    IsModelNoReject: boolean;
    IsFuelTypeReject: boolean;
    IsVehicleShapeReject: boolean;
    IsPurposeOfLicenseReject: boolean;
    IsCarNumberReject: boolean;
    IsChasisNumberReject: boolean;
    IsNoOfPassengersReject: boolean;
    IsColorReject: boolean;
    IsCurrentCarValueReject: boolean;
}

export class CarInformationRequest {
    LanguageTypeId: number;
    CarNumber: string;
    BrandId: number;
    ModelId: number;
    ChasisNumber: string;
    CurrentCarValue: number;
    Color: string;
    BatchNo: string;
    ModelNo: number;
}

export class CarPolicyPaymentRequest {
    LanguageTypeId: number;
    PolicyStartDate: string;
    OnlyPolicyPrice: number;
    TotalAddOnsPrice: number;
    NetPrice: number;
    SupervisionFees: number;
    IssueFees: number;
    ExtraFees: number;
    BatchNo: String;
    CarAdOns: CarAdOns[];
}

export class UpdatePersonalInformationRequest {
    Id: number;
    Pre_CustomerPolicyId: number;
    LanguageTypeId: number;
    FirstName: string;
    MiddleName: string;
    LastName: string;
    CivilId: string;
    Address: string;
    MobileNo: string;
    EmailId: string;
}

export class UpdateCarInformationRequest {
    Id: number;
    Pre_CustomerPolicyId: number;
    LanguageTypeId: number;
    PolicyId: number;
    Carnumber: string;
    BrandId: number;
    ModelId: number;
    ModelNo: number;
    olor: string;
    ChasisNumber: string;
    CurrentCarValue: number;
}

export class CarReviewPersonalInformation {
    FullName: string;
    CivilId: string;
    Address: string;
    MobileNo: string;
    EmailId: string;
}

export class CarReviewInformation {
    CarNumber: string;
    BrandName: string;
    ModelsName: string;
    VehicleShapeName: string;
    DamageDescription: string;
    FuelTypeName: string;
    NoOfPassenger: number;
    ModelNo: string;
    ChasisNumber: string;
    CurrentCarValue: number;
    Color: string;
    PurposeOfLicenseName: string;
    IsCouponAvailable:number; 
    CouponCode: string;
    CouponDiscount:number;
}