import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class CarService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

    /** Save car policy step 1 */
    SaveCarPolicyStep1(carInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SaveCarInsurancePolicyStep1", carInsurancePolicyRequest);
    }

    /** Get car policy plans **/
    GetCarPolicyPlans(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarPolicyPlans", requestAuthentication);
    }

    /** GET policy cost from the server */
    getCarPolicyPlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarCost", policyplanRequest);
    }

    /** GET addone from the server */
    getAddOnsList(addonRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetAddOnsList", addonRequest);
    }

    /** GET Policy step2 from the server */
    PolicyStep2(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SaveCarInsurancePolicyStep2", policyplanStep2Request);
    }

    /** GET Policy  from the server */
    GetCarInsurancePolicyHolderDetails(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarInsurancePolicyHolderDetails", policyplanStep2Request);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/CreatePreCustomerPolicy", policyplanStep2Request);
    }

    GetPolicyReceipt(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCarPolicyReceipt", RequestAuthentication);
    }

    /** Get PolicyDetails  from the server */
    getCarPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarPolicyDetails", RequestAuthentication);
    }

    /** Get Policy Car Holder Details  from the server */
    GetPolicyCarHolderDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetPolicyCarHolderDetails", RequestAuthentication);
    }

    /**  update car Policy holder Details  from the server */
    updatePolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SetPolicyHolder", RequestAuthentication);
    }

    /** Get Policy Car Holder Details  from the server */
    GetPolicyAssuredCarDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetPolicyAssuredCarDetails", RequestAuthentication);
    }

    /**  update car Policy holder Details  from the server */
    updatePolicyAssuredPerson(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SetPolicyAssuredPerson", RequestAuthentication);
    }

    /** Get Required Attachment from the server */
    GetCarRequiredDocumentslist(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarRequiredDocumentslist", RequestAuthentication);
    }

    /** Update Attachment */
    UpdateCarDocumentApproved(request): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/UpdateCarDocumentApproved", request);
    }
    /** Delete policy required document **/
    DeleteCarPolicyDocument(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/DeleteCarPolicyDocument", request);
    }

    UploadCarRequiredDocuments(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/UploadCarRequiredDocuments_V2", formData);
    }

    /** Get Insurance Coverage from the server */
    GetInsuranceCoverage(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetInsuranceCoverage", RequestAuthentication);
    }

    /** Get PolicyFee and Payment  from the server */
    GetPolicyFeeANDPayment(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetPolicyFeeANDPayment", RequestAuthentication);
    }


    getPolicyCompleteStatus(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetPolicyCompleteStatus", request);
    }
    GetPersonalInformation(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetPersonalInformation", request);
    }

    SavePersonalInformation(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/SavePersonalInformation", request);
    }

    GetCarInformation(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCarInformation", request);
    }

    SaveCarInformation(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/SaveCarInformation", request);
    }

    GetCarPolicyRequiredAttachmentList(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCarPolicyRequiredAttachmentList", request);
    }

    SaveCarPolicyAttachmentInformation(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/SaveCarPolicyAttachmentInformation", formData);
    }

    SaveCarPolicyPriceDetails(policyPaymentRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SaveCarPolicyPriceDetails", policyPaymentRequest);
    }

    GetCustomerCarPolicyDetails(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCustomerCarPolicyDetails", RequestAuthentication);
    }

    /** Check car policy cost from the server */
    CheckCarPolicyPlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/CheckCarPolicyPlanCost", policyplanRequest);
    }


    SaveUploadDocumentDescription(damageRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/SaveUploadDocumentDescription", damageRequest);
    }

    GetCarPolicyAttachmentList(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCarPolicyRequiredDocumentList", request);
    }

    GetPaymentScreenInfo(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetPaymentScreenInfo", RequestAuthentication);
    }

    BuyCarPolicy(RequestAuthentication): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/BuyCarPolicy", RequestAuthentication);
    }

    MobileBuyCarPolicy(RequestAuthentication): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/MobileBuyCarPolicy", RequestAuthentication);
    }

    GetCarPolicyInvoice(RequestAuthentication): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GetCarPolicyInvoice", RequestAuthentication);
    }

    MobileGetCarPolicyInvoice(RequestAuthentication): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/MobileGetCarPolicyInvoice", RequestAuthentication);
    }

    /** GET addone from the server */
    GetCarReviewPolicyInformation(addonRequest): Observable<any> {
        return this.apiService.post(config.apiCarInsurance + "/GetCarReviewPolicyInformation", addonRequest);
    }

    /** Get terms and conditions simple steps details **/
    getTermsAndContitions(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiCorporate + "/GetTermsAndConditions", RequestAuthentication);
    }

    GenerateIssueCertificate(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiCarInsurance + "/GenerateCertificate", request);
    }

    GetStepCompleteStatus(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarInsurance + '/PolicyStatus', request);
    }
    GetBrandDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiShared + "/GetBrandDropDownList", RequestAuthentication);
    }
}
