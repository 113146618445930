import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import 'rxjs/add/operator/filter';
import { Observable, throwError, Subject } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Router, ActivatedRoute } from '@angular/router'; 
 
import { CurrentAccessToken } from '../classes';
// import { AuthService } from './auth/auth.service';
import { AuthService } from '../services/auth-api/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  returnUrl:string;

  constructor(
    private currentAccessToken:CurrentAccessToken,private router: Router,private route:ActivatedRoute) {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    let currentUser = this.currentAccessToken.loggedInUserInfo;
    if (currentUser && currentUser.AccessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `${currentUser.AccessToken.token_type} ${currentUser.AccessToken.access_token}`
          //   'Authorization': `${currentUser.AccessToken.token_type} ${currentUser.AccessToken.access_token}`,
          //   'Set-Cookie':'new session 90439043'
        }
      });
    }

    // return next.handle(request);
    //send the newly created request
    return next.handle(request).catch(err => {
      // onError
      //console.log(err);
      if (err instanceof HttpErrorResponse) {
        //console.log(err.status);
        //console.log(err.statusText);
        if (err.status === 401) {
             /** not logged in so redirect to login page with the return url...*/ 
            // this.router.navigateByUrl(this.returnUrl);
            // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: this.returnUrl } });
            this.router.navigate(['/home']);
          // window.location.href = "/auth/login?returnUrl=";
        }
      }
      return Observable.throw(err);
    }) as any;
     
  }
}