import { Component,Input, Output, ViewChild, EventEmitter,ElementRef, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { AccountService, TravelService } from '@app/core';
import { AuthService, TravelService, ApiRequestTypesEnum, InsuranceTypeEnum, CurrentLanguage} from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryCodeResult } from '../../../../core/models/home/contact.model';

import {  BsModalRef } from 'ngx-bootstrap/modal'; 
import { LayoutHeaderComponent } from '../../../../shared';
//import { TravelInsuranceStep1Component } from '@app/modules/travel/pages/buy/step1/first.component';
// import { ModalService } from '../../../../shared/services/modal-popup.service';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router'; 
import { Guid } from 'guid-typescript';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginModalComponent extends CurrentLanguage implements OnInit {

@ViewChild('inputphone') cphoneElement: ElementRef;

  
  @Input()child: {rid: string,rtype:number,policytype: number, travelTo:number, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, PolicyId: number, IsforgotPass:boolean, MobileObject:any};
  @Output() messageEvent = new EventEmitter<any>();  
  CountryCodeResult: CountryCodeResult[];  
  returnUrl: string;
  submitted = false;
  showSuccess= false;
  IsFormSubmit = false;
  IsShowRighrIcon = false;
  loginForm: FormGroup;
  polictype:number = 0;
  request = {LanguageTypeId:1, RequestId: "",RequestType:0, CountryCodes_Fk_Id:0,MobileNo:"", OneTimePassword:"", PolicyId: 0, CountryDialCode:""};
  ErrorMessage = "";
  phonePattern = "^((\\+91-?)|0)?[0-9]{8,12}$";
  lang:string;
  CountryDialCode:any="";

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountryISO:any=CountryISO.Kuwait;
  //PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Kuwait
  ];
  PhoneReg:RegExp= new RegExp("^[0-9]{8,12}$");

  constructor(
    public bsModalRef: BsModalRef,
    // private modalService: ModalService,
    private authService: AuthService,
    private formBuilder: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    private travelService: TravelService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private localize: LocalizeRouterService) {
      super(translate);
   
      // get return url from route parameters or default to '/'
      this.lang=this.translate.currentLang;
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    } 

  ngOnInit() { 
      this.getCountryCode();
      this.IsShowRighrIcon = false;
      this.loginForm = this.loginFormValidation();
  }


  getCountryCode() {
    let request = {
      LanguageTypeId: 1,
      Flag: "CountryCode"
    }
    var result = this.travelService.GetDropDownList(request);
    result.subscribe(resp => {
      this.CountryCodeResult = resp.ResponseCollection;
    });
  }

  LoginWithPolicyPurchase() {  
    
    this.submitted = true;
    this.request = this.loginForm.value;
    this.request.CountryCodes_Fk_Id= this.child.CountryCodes_Fk_Id;//=1;
    this.request.RequestId = this.child.rid;
    this.request.RequestType = this.child.rtype;
    this.request.MobileNo = this.loginForm.get("MobileNo").value? this.loginForm.get("MobileNo").value.number : "";
    this.request.CountryDialCode = this.loginForm.get("MobileNo").value? this.loginForm.get("MobileNo").value.dialCode:"";  
    this.child.IsforgotPass=false;
    this.request.LanguageTypeId = this.appLangId;
    if(this.child != undefined && this.child.policytype == 5){
      this.polictype = 5;
    }
    // this.request.CountryCodes_Fk_Id = this.child.CountryCodes_Fk_Id;
    // this.request.MobileNo = this.child.MobileNo;  
    if(this.request.MobileNo.length < 8 || this.request.MobileNo.length > 12 || !this.PhoneReg.test(this.request.MobileNo)) 
    {
      this.loginForm.controls['MobileNo'].setErrors({'incorrect': true});
    }    
    if (this.loginForm.invalid) {
      //console.log("invalid");
      return;
    }
    else{
      this.IsFormSubmit = true;
      var result = this.authService.loginWithPolicyPurchaseWeb(this.request);          
      result.subscribe(resp => {

        if( resp.ResponseStatus===12)
        {
        this.ErrorMessage = this.translate.instant('Invalid_password_formate');
        this.IsFormSubmit = false;
         setTimeout(() => {
           this.ErrorMessage = "";
         }, 4000);
        }
        else if(resp.ResponseStatus == 1){
        
          if(this.polictype == 5){
            this.child.rtype = 5;
          }


          /** Reload user information... */
          LayoutHeaderComponent.updateUserStatus.next(true);

          if (this.child.rtype === undefined || this.child.rtype === null) {
            let translatedPath: any = this.localize.translateRoute('/account/dashboard');
            this.router.navigate([translatedPath]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.Travel) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/travel/buy/travelstep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId,1,'0','1',this.child.travelTo]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.Domestic) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/domestic/buy/domesticstep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId,1,'0','1',0]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.OtherDomestic) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/domestic/buy/usdstep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId,1,'0','1',0]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.House) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/homeinc/buy/homestep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId,1,'0','1',0]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.Car) {
            /** Route to next step... */            
            //this.router.navigate([ 'car/buy/carstep2', this.child.PolicyId, this.request.RequestId,1,'0','1',0]);
            // let translatedPath: any = this.localize.translateRoute('/car/buy/carstep1');
            let translatedPath: any = this.localize.translateRoute('/car/buy/carstep2');
            this.router.navigate([translatedPath, InsuranceTypeEnum.CarInsurance,this.request.RequestId]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.MarineHull) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/marine/buy/marinehullstep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId,1,'0','1',0]);
          }
          else if (this.child.rtype == ApiRequestTypesEnum.TplMotor) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/tplmotor/buy/tplmotorstep2');
            this.router.navigate([translatedPath, this.child.PolicyId, this.request.RequestId]);
          }
          else if(this.child.rtype == ApiRequestTypesEnum.Manasek){
            let translatedPath: any = this.localize.translateRoute('/manasek/buy/manasekstep2');
            this.router.navigate([translatedPath, 9, Guid.create().toString()]);
          }
          else if(this.child.rtype == ApiRequestTypesEnum.CarClaim)
          {
            let translatedPath: any = this.localize.translateRoute('/carclaim/claimstep1');
            this.router.navigate([translatedPath, 2, Guid.create().toString()]);
          }          
          else if (this.returnUrl !== "" && this.returnUrl !== "/"  && this.returnUrl !== "//" && this.returnUrl !== "\\"  ) {
            this.router.navigateByUrl(this.returnUrl);
          }
          else {
            let translatedPath: any = this.localize.translateRoute('/account/dashboard');
            this.router.navigate([translatedPath]);
          }
          /** Hide modal popup... */
          //this.bsModalRef.hide();
        }
        else {          
          this.IsFormSubmit = false;
          this.ErrorMessage = resp.ResponseMessage;
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);
        }
        
        this.submitted = false;
        
      });
    }
  }
  get f() { return this.loginForm.controls; }
  loginFormValidation(){
   
    if(this.child===undefined || this.child===null|| this.child.MobileNo===""){
      
      this.IsShowRighrIcon = false; 
             
      return this.formBuilder.group({     
       // CountryCodes_Fk_Id: ['1', Validators.required],
        // MobileNo: ['', [Validators.required, Validators.pattern(this.phonePattern)]], 
        MobileNo: ['', [Validators.required]], 
        Password: ['', Validators.required]     
      });
    }else{ 
      this.CountryDialCode= this.child.CountryCode;
      this.selectedCountryISO = this.child.MobileObject.countryCode; 
      this.IsShowRighrIcon = true;          
    return this.formBuilder.group({     
      CountryCodes_Fk_Id: [this.child.CountryCodes_Fk_Id, Validators.required],
      // MobileNo: [this.child.MobileObject,[Validators.required, Validators.pattern(this.phonePattern)]], 
      MobileNo: [this.child.MobileNo,[Validators.required]],
      Password: ['', Validators.required]     
    });
  }
  }
  ngAfterViewInit() {
    setTimeout(()=>{
    var phoneNo= ( document.getElementById("inputphone") as HTMLTextAreaElement).value;
      if(phoneNo=="")
      { document.getElementById("inputphone").focus();
      }
      else
      {document.getElementById("inputpassword").focus();

      }
    
    });
  }

  ChangeMobileNumber() {    
    const obj ={
      VerifiedOtpRef : "",
      PolicyId: this.child.PolicyId,
      authActiveStepIndex: 1
    }
    this.messageEvent.emit(obj);
  }

  ForgotPassword() {
    
    const obj ={
      VerifiedOtpRef : "",
      PolicyId: this.child.PolicyId,
      authActiveStepIndex: 5,
      MobileNo: this.child.MobileNo,
      IsforgotPass:true
    }
    this.messageEvent.emit(obj);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  firstWhiteSpace(event: any) {
   
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }

}
