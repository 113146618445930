export class Addons {
    public Id: number;
    public AddOnName: String;
    public CostType: String;
    public Cost: number
}
export class PolicyPrices {
    public PolicyPrice: number;
    public ExtraAssuredMembersPrice: number;
    public TotalPolicyPrice: number;
}
export class PolicyPlanCost {
    public Id: number;
    public PolicyPrice: number;
    public PlanName: string;
    public LanguageTypeId: number;
}
export class PolicyStep2 {
    LanguageTypeId: number;
    public PolicyId: number;
    public RequestedId: String;
    public InceptionDate: string;
    public PolicyEndDate: string;
    public Duration_Fk_Id: number;
    public PolicyPlanId: number;
    public adOns: AdOns[];
    public TravelTypeId: number;
}

export class PolicyStep4 {
    LanguageTypeId: number;
    public InsuranceType_Fk_Id: number;
    public RequestedId: String;
    public PaymenType : number;
    public PayModeId: number;
    public PaymentId : string;
    public Result : string;
    public TransactionNo : string;
    public TranId : string;
    public Authid : string;
    public Refid : string;
    public Ref: string;
    public Trackid : string;
   
}
export class AdOns {
    public Id: number;

}

export class DropDownlist {
        public Value: number;
    public Text: String;

}

export class PolicyStep3 {
    public PolicyGroup: string;
    public TravelTo: string;
    public PlanType: string;
    public InceptionDate: Date;
    public DurationInDays: number;
    public TotalTravellers: number;
    public Name: string;
    public EmailId: string;
    public MobileNo: string;
    public PassportNumber: string;
    public NetPrice: number;
    public SupervisionFees:number;
    public IssueFees:number;
    public ExtraFees:number;
    public PolicyPremium: number;
    public TotalAddOnsPrice: number;
    public CouponCode: string;
    public CouponDiscount: number;
    public IsCouponAvailable:number
    public TravelPolicyAddOnList: TravelPolicyAddOnDetails[]=[];
    public PaymentModeList: PaymentMode[]
}

export class PaymentMode {
        public PaymentModeId: number;
    public PaymentModeType: string;

}
export class TravelPolicyAddOnDetails {

    public AddOnName: string;
}

export class TravelInsurancePolicyRequest{
    LanguageTypeId: number;
    Customer_Id: number;
    SessionId: string;
    RequestedId: string;
    PolicyGroup_Fk_Id: number;
    PolicyPlanType_Fk_Id: number;
    InsuranceType_Fk_Id: number;
    TravellerDetailsRequest: TravellerDetailsRequest[];
    // constructor() {
    //     this.TravellerDetailsRequest = new TravellerDetailsRequest();
    // }
}

export class TravellerDetailsRequest {
    Relationship_Fk_Id: number;
    RelationName: string; 
    Age: number;
    Dob: string;
    Id: number;
    TravellerId: number;
}

export class BenefitsTypeResult{
    Id: number;
    CountBenefits: number;
    CountSchengenBenefits: number;
    BenefitsTypeName: number;
    TravelIncBenefitsList: TravelIncBenefitsResult[];
}

export class TravelIncBenefitsResult{
    BenefitsType_Fk_Id: number;
    BenefitsName: string;
    PlatinumPlan: string;
    GoldPlan: string;
    SilverPlan: string;
    BasicPlan: string;
    SchengenValue: string;
    IsSchengen: boolean;


}

export class PolicyHolder{
        LanguageTypeId: number;
        public Id:number;
        public CustomerPolicy_Fk_Id:number
        public  FirstName: string;
        public  MiddleName :string;
        public  LastName :string;
        public  MaritalStatus:boolean;
        public  Gender :number;
        public  PhoneNumber:string;
        public  MobileNumber: string;
        public  EmailAddress: string;
        public  CountryCodes_Fk_Id :number;
        public  Address : string;
        public  PassportNumber : string;        
        public  CivilId: string;
        public  DOB :string;
        public IsPolicyHolder:boolean;
}
export class AssuredPersonRequest { LanguageTypeId: number;
    AssuredPerson:PolicyAssuredPerson[]=[];
      };

export class PolicyAssuredPerson{
    LanguageTypeId: number;
    public Id:number;
    public CustomerPolicy_Fk_Id:number
    public  FirstName: string;
    public  Relation:string;
    public  PassportNumber : string;        
    public  CivilId: string;
    public  DOB :string;
    public IsPolicyHolder:boolean;
}

export interface DropdwonResult{
    Value: number;
    Text: string;
}

export interface OptGroupDropdwonResult{
    Value: number;
    Text: string;
    OptionValue: number;
}

export class PolicyISuranceCoverage
{
    public  Id :number;
    public  PolicyNo: string;
    public  PolicyName :string;
    public  InsuranceType :string;
    public  Date :Date
    public  TransactionNo:string;
    public  RefrenceNo:string;
    public  PaymentType :string;
    public  OnlyPolicyPrice:number;
    public  NetPrice:number;
    public TotalAddonPrce :number;
    public SupervisionFees: number;
    public IssueFees: number;
    public ExtraFees: number;
    public IssueCertificate: string;
    public IssueCertificateUrl: string;
    public Trackid: string;
    public Refid: string;
    public PaymentID: string;
    public Result: string;
    public  AddOn:Addons[];
    public KDCountry_FK_ID:number;
        
}

export class PolicyRequiredDocuments
{
    public  Id :number;
    public  AttachmentNo: string;
    public  CivilName :string;
    public  Document :string
    public  DocumentUrl:string;
    public  IssueDate:Date;
    public  ExpiryDate :Date;
    public  RemindMeB4Expiry:boolean;
    public  IsCheckedAndApproved:boolean;
    public  Status: number;

}


export class RequiredDocumentRequest{
    public LanguageTypeId:number;
    public Id: number;
    public IssueDate: string;
    public ExpiryDate: string;
    public PolicyRequiredDocumentList_Fk_Id: number;
    public CustomerPolicyAssuredPerson_Fk_Id: number;
    public InsuranceType_Fk_Id: number;
    public UploadRequiredFile: File;
    public CustomerPolicy_Fk_Id:number;
   
}

export class Policysuccess
{ TransactionNo:string;
    PolicyNo :string;
    Name:string;
    MobileNo :string;
    EmailId:string;
    NetPrice:number;
    PolicyStatus :string;
    PaymentMode:string;
}

export class PolicyDocumentRequest
{
    Id: number;
}
export class MyPoliciesStatusCount
{
    Pending: number;
    InProcess: number;
    Approved: number;
    Rejected: number;
    Active:number;
    Expire: number;
   
}

export class PoliciesAndQuotationStatusCount
{
    TotalActiveCount: number;
    TotalExpiredCount: number;
    TotalWaitingForApprovalCount: number;
    TotalWaitingForPayment: number;
    TotalNewRequest:number;   
}

export class SimpleStepsDetails
{
    Id: number;
    Content: string;
    Image: string;
}

export class AssoredPersonDocuments{
    AssuredId: string;
    Name: string;
    Age: string;
    RelationShip:string;
    Documents:RequiredDocuments[];
}

export class RequiredDocuments {
    DocTypeId: string;
    DocType: string;
    UserDocId: string;
    UploadedDocId:string;
    ExpiryDate:string;
    DocumentPath:string;
}

export class PolicyAssuredPersonRequest{
    public RequestedId: string;
    public LanguageTypeId: number;
    public  FirstName: string;
    public  MiddleName :string;
    public  LastName :string;
    //public  MaritalStatus:boolean;
    public  GenderId :number;
    public  DateOfBirth :string;
    public  PhoneNumber:string;
    public  MobileNumber: string;
    public  EmailAddress: string;
    public  CountryCodes_Fk_Id :number;
    public  PassportNumber : string;        
    public  CivilId: string;
    public  Address : string;
    public TravelAssuredPersonsRequest: TravelAssuredPersonRequest[]
}

export class TravelAssuredPersonRequest{
    public Id:number;
    public TravelIncTravellersDetailId:number
    public  FirstName: string;
    public  MiddleName: string;
    public  LastName: string;
    public  Relationship_Fk_Id:string;
    public RelationshipName: string;
    public  PassportNumber : string;        
    public  CivilId: string;
    public  DOB :string;
    public IsExtraPerson:boolean;
    public Age: number;
}

export class ApplyCouponRequest {
    public Flag : String;
    public InsuranceType_Fk_Id: number;
    public RequestedId: String;
    public CouponCode : String;
}
export class ApplyCarCouponRequest extends ApplyCouponRequest  {
    
    public OnlyPolicyPrice : number;
    public TotalAddOnsPrice: number;
    public NetPrice: number;        
    public SupervisionFees : number;
    public IssueFees : number;      
    public ExtraFees : number;      


}

export class PaymentModeList {
    public PaymentModeId : number;
    public PaymentModeType: String;
}


 