import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


import { CurrentAccessToken } from '../classes'; 
import { ToastrService } from 'ngx-toastr';
// import { LayoutHeaderComponent } from '../../shared';

@Injectable({ providedIn: 'root' })
export class TokenExpiredDoReloginGuard implements CanActivate {
    constructor(private toastr: ToastrService,
        private router: Router,      
        private currentAccessToken:CurrentAccessToken
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (!this.currentAccessToken.isTokenExpired()) { 
            //  /** Reload user information... */
            // LayoutHeaderComponent.updateUserStatus.next(true); // here!
            return true;
          }
       
       /** not logged in so redirect to login page with the return url...*/ 
      // this.router.navigate(['/auth/dologout'], { queryParams: { returnUrl: state.url } });
      
        this.toastr.warning("Dear customer your logged in time has expired. Please do login and try again.");
    return false;
    }
}