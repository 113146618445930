import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
// import { Observable } from 'rxjs';
// import { BannerCollection } from '../models/home/banners.model';
import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private jsonApiService: JsonApiService,
    private apiService: ApiService) { }

  /** GET banners from the server */
  getBannerList(RequestAuthentication): Observable<any> {

    return this.apiService.post(config.apiCorporate + "/GetBannerList", RequestAuthentication);
  }

  /** GET about from the server */
  getAboutResult(aboutRequest): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetAboutResult", aboutRequest);
  }

  /** Get insurance department list */
  getInsuranceDepartmentList(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetInsuranceDepartmentList", request);
  }

  /** Get insurance department list */
  getInsuranceClaimDepartmentList(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetInsuranceClaimDepartmentList", request);
  }

  /** Get insurance department details */
  getInsuranceDepartmentDetails(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetInsuranceDepartmentDetails", request);
  }

  /** GET contact type dropdown from the server */
  getContactType(DropdownRequest): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetContactType", DropdownRequest);
  }

  /** GET country code dropdown type from the server */
  getCountryCode(DropdownRequest): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetCountryCode", DropdownRequest);
  }

  /** GET contact type dropdown from the server */
  saveContactUs(ContactUsRequest): Observable<any> {
    //console.log(ContactUsRequest);
    return this.apiService.post(config.apiCorporate + "/SaveContactUs", ContactUsRequest);
  }

  /** GET service menu list from the server */
  getServiceMenuList(requestAuthentication) {
    return this.apiService.post(config.apiCorporate + "/GetServiceMenuList", requestAuthentication);
  }

  /** GET service list from the server */
  getServiceList(requestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetServiceList", requestAuthentication);
  }

  /** GET service details from the server */
  getServiceDetails(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetServiceDetails", request);
  }

  /** GET reinsurance partner from the server */
  getReinsurancePartnerList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetReinsurancePartnerList", RequestAuthentication);
  }

  /** GET facts and statics from the server */
  getFactsNStaticsList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetFactsNStaticsList", RequestAuthentication);
  }



  /** GET menus from the server */
  getMenuList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetMenuList", RequestAuthentication);
  }

  /** GET news from the server */
  getNews(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetNewsList", RequestAuthentication);
  }

  /** GET news from the server */
  GetDetailNewsList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetDetailNewsList", RequestAuthentication);
  }

  /** GET news  Details from the server */
  getNewsDetails(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetNewsDetails", RequestAuthentication);

  }

  /** GET team type from the server */
  getTeamTypeList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTeamTypeList", RequestAuthentication);
  }

  /** GET team list from the server */
  getTeamList(request: any): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTeamList", request);
  }

  /** GET team details for popup from the server */
  getTeamDetails(request: any): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTeamDetails", request);
  }

  /** GET team details list for details page from the server */
  getTeamDetailsList(request: any): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTeamDetailsList", request);
  }

  // getSingle(id: number): Observable<Project> {
  //     return this.apiService.get(routes.project(id));
  // }

  /** GET menus from the server */
  getSocialMediaLink(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetSocialMediaFooter", RequestAuthentication);
  }

  /** GET feature configuration tagline from the server */
  getFeatureConfigurationTagLine(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetFeatureConfigurationTagLine", request);
  }

  /** GET banners from the server */
  getTestimonialList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTestimonialList", RequestAuthentication);
  }

  /** Get insurance simple steps details **/
  getInsuranecSimpleStepsDetails(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetInsuranceSimpleStepsDetails", RequestAuthentication);
  }

  /** Get terms and conditions simple steps details **/
  getTermsAndContitions(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetTermsAndConditions", RequestAuthentication);
  }

  /** Get Required Attachment from the server */
  GetPolicyRelatedRequiredDocumentList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetPolicyRelatedRequiredDocumentList", RequestAuthentication);
  }

  /** Get about insurance layout details **/
  getAboutInsuranceLayoutList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetAboutInsuranceLayoutList", RequestAuthentication);
  }


  changePolicyStatus(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/ChangePolicyStatus", RequestAuthentication);
  }

  /** Get policyList  from the server */
  GetMyPoliciesAndQuotationList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetMyPoliciesAndQuotationList", RequestAuthentication);
  }
  GetMyCancelPolicyList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetMyCancelledPolicyList", RequestAuthentication);
  }

  GetMyReissuePolicyList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetMyReissuedPolicyList", RequestAuthentication);
  }
  

  /** Get about insurance layout details **/
  getPolicyWordingDocumentList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/GetPolicyWordingDocumentList", RequestAuthentication);
  }

  /** Create Pre Policy  from the server */
  CreateFailedCanceledPaymentDetails(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiCorporate + "/CreateFailedCanceledPaymentDetails", policyplanStep2Request);
  }

  /** GET banners from the server */
  getOptGroupDropDownList(RequestAuthentication): Observable<any> {

    return this.apiService.post(config.apiCorporate + "/GetOptGroupDropDownList", RequestAuthentication);
  }

  /** GET invoices from the server */
  GetDashboardMyInvoices(request): Observable<any> {
    return this.apiService.post(config.apiCorporate + '/GetDashboardMyInvoices', request);
  }

  /*Get Failed Payment details*/
  GetPaymentFailedInsuranceReceipt(request): Observable<any> {
    return this.apiService.PostMultiPart(config.apiCorporate + "/GetPaymentFailedInsuranceReceipt", request);
  }

   /** GET banners from the server */
   GetAboutUsDetails(RequestAuthentication): Observable<any> {

    return this.apiService.post(config.apiCorporate + "/GetAboutUsDetails", RequestAuthentication);
  }

  /*Get Failed Payment details*/
   GetMobilePaymentFailedCanceledDetails(request): Observable<any> {
    return this.apiService.PostMultiPart(config.apiCorporate + "/GetMobilePaymentFailedCanceledDetails", request);
  }



  // added by minaklshi  04/04/2023

  CreateFailedCanceledPaymentDetailsForSrilanka(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/CreateFailedCanceledPaymentDetailsForSrilanka", policyplanStep2Request);
  }



  GetPaymentFailedInsuranceReceiptForSrilanka(request): Observable<any> {
    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GetPaymentFailedInsuranceReceiptForSrilanka", request);
  }
}
