import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';


// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class DomesticService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

   

    /** GET addone from the server */
    getAddOnsList(addonRequest): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticAddOnsList", addonRequest);
    }
    /** GET policy cost from the server */
    getDomesticPlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticCost", policyplanRequest);
    }

    /** Save travel policy step 1 */
    SaveDomesticPolicyStep1(domesticInsurancePolicyRequest): Observable<any> {

        return this.apiService.post(config.apiDomestic + "/SaveDomesticInsurancePolicyStep1", domesticInsurancePolicyRequest);
    }

    /** GET Policy step2 from the server */
    PolicyStep2(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/SaveDomesticInsurancePolicyStep2", policyplanStep2Request);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/CreatePreCustomerPolicy", policyplanStep2Request);
    }


    /** GET Policy  from the server */
    GetDomesticInsurancePolicyDetails(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticInsurancePolicyDetails", policyplanStep2Request);
    }

    GetDomesticPolicyReceipt(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiDomestic + "/GetDomesticPolicyReceipt", RequestAuthentication);
    }

    MobileGetDomesticPolicyReceipt(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiDomestic + "/MobileGetDomesticPolicyReceipt", RequestAuthentication);
    }

    /** Get PolicyDetails  from the server */
    getDomesticPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticPolicyDetails", RequestAuthentication);
    }

    /** Get SponsorDetails  from the server */
    GetSponsorDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetSponsorDetails", RequestAuthentication);
    }

    /**  update PolicyDetails  from the server */
    updateSponsorDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/UpdateSponsorDetails", RequestAuthentication);
    }

    /** Delete policy required document **/
    getPolicyCompleteStatus(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiDomestic + "/GetPolicyCompleteStatus", request);
    }

    /** Get PolicyAssuredPerson  from the server */
    GetDomesticHelperDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticHelperDetails", RequestAuthentication);
    }

    /** update PolicyAssuredPerson  from the server */
    updatePolicyHelperDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/UpdatePolicyHelperDetails", RequestAuthentication);
    }

    getDomesticPolicyBenefits(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticPolicyBenefits", requestAuthentication);
    }

    /** Get Insurance Coverage from the server */
    GetInsuranceCoverage(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetInsuranceCoverage", RequestAuthentication);
    }

    /** Get PolicyFee and Payment  from the server */
    GetPolicyFeeANDPayment(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetPolicyFeeANDPayment", RequestAuthentication);
    }

    /** Get Required Attachment from the server */
    GetPolicyRelatedRequiredDocumentList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetRequiredDocumentslist", RequestAuthentication);
    }

    /** Save travel policy step 1 */
    SaveSponsorInformationStep4(SponsorPolicyRequest): Observable<any> {

        return this.apiService.post(config.apiDomestic + "/SaveDomesticSponsorDetails", SponsorPolicyRequest);
    }

    getSponsorInformationStep4(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiDomestic + "/GetDomesticSponsorDetails", RequestAuthentication);
    }
    /** Get Required Attachment from the server */
    GetDomesticLeadRequiredDocumentslist(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticLeadRequiredDocumentslist", RequestAuthentication);
    }
    UploadDomesticDocuments(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiDomestic + "/UploadDomesticDocuments", formData);
    }

      GetDomesticPolicyDetailsStep1(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticPolicyDetailsStep1", RequestAuthentication);
    }

    GetDomesticPolicyDetailsStep2(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticPolicyDetailsStep2", RequestAuthentication);
    }

    GenerateIssueCertificate(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiDomestic + "/GenerateCertificate", request);
    }

     /** Create Pre Policy  from the server */
     CreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/CreateCustomerPolicy", policyplanStep2Request);
    }

    
      /** Create Pre Policy  from the server */
      MobileCreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/MobileCreateCustomerPolicy", policyplanStep2Request);
    }
/// minakshi work start here

GetDomesticPolicyDetailsStep2ForSrilanka(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDomesticPolicyDetailsStep2ForSrilanka", RequestAuthentication);
}

PolicyStep2Srilankan(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/SaveDomesticPolicyForSrilanaknStep2", policyplanStep2Request);
}

getDomesticPlanCostInUsd(policyplanRequest): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDomesticCostInUsd", policyplanRequest);
}


SaveSponsorInformationForSrilankanStep4(SponsorPolicyRequest): Observable<any> {

    return this.apiService.post(config.apiOtherDomestic + "/SaveDomesticSponsorDetailsForSrilakan", SponsorPolicyRequest);
}



getSponsorInformationForSrilankanStep4(RequestAuthentication): Observable<any> {

    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GetDomesticSponsorDetailsForSrilankan", RequestAuthentication);
}


GetDomesticLeadDocumentslistForSrilankan(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDomesticLeadDocumentslistForSrilankan", RequestAuthentication);
}



UploadDomesticDocumentsForSrilankan(formData: FormData): Observable<any> {

    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/UploadDomesticDocumentsForSrilankan", formData);
}


GetDomesticInsurancePolicyDetailsForSrilankan(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDomesticInsurancePolicyDetailsForSrilankan", policyplanStep2Request);
}

CreatePreCustomerPolicyForSrilankan(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/CreatePreCustomerPolicyForSrilankan", policyplanStep2Request);

}


GetDomesticPolicyReceiptForSrilankan(RequestAuthentication): Observable<any> {
return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GetDomesticPolicyReceiptForSrilankan", RequestAuthentication);
}


// GenerateCertificateForSrilankan(request): Observable<any> {            
//     return this.apiService.PostMultiPart(config.apiDomestic + "/GenerateCertificateForSrilankan", request);
// }


GenerateCertificateForSrilankan(request): Observable<any> {            
    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GenerateCertificateForSrilankan", request);
}
CreateUsdCustomerPolicy(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/CreateUsdCustomerPolicy", policyplanStep2Request);
}

  /** Get DropDownList  from the server */
  GetYearsDropDownList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/DomesticPolicyYearsForSrilanakn", RequestAuthentication);
}

GetDropDownGenderList(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDropDownGenderList", RequestAuthentication);
}


GetDomesticPolicyKDPrice(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/GetDomesticPolicyKDPrice", RequestAuthentication);
}


// 30/03/2023



GenerateCertificateInsideKuwait(request): Observable<any> {            
    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GenerateCertificateInsideSrilankanForKuwait", request);
}


CreateFailedCanceledPaymentDetailsForSrilanka(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiOtherDomestic + "/CreateFailedCanceledPaymentDetailsForSrilanka", policyplanStep2Request);
  }



  GetPaymentFailedInsuranceReceiptForSrilanka(request): Observable<any> {
    return this.apiService.PostMultiPart(config.apiOtherDomestic + "/GetPaymentFailedInsuranceReceiptForSrilanka", request);
  }



  // minaklshi work  End here domesticpolicy


  

}