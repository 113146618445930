<!--<app-root></app-root>-->

<!-- <mat-progress-bar
  *ngIf="loading" color="accent" mode="indeterminate">
</mat-progress-bar>  *ngIf="ShowMenuHeader"-->
<header id="header" class="">
     <div class="navbar Bnavbar">
          <div class="container gh-menu">
               <button id="gh-svg-icons" class="gh-svg-wrapper" style="z-index: 0 !important;"
                    data-target=".navbar-responsive-collapse" data-toggle="collapse">
                    <span class="gh-text-replace">{{'Menu' | translate}}</span>
                    <svg x="0px" y="0px" width="100%" viewBox="0 0 96 96" class="gh-svg gh-svg-top"
                         enable-background="new 0 0 96 96">
                         <rect width="40" height="5" x="32" y="46" class="gh-svg-rect gh-svg-rect-top"></rect>
                    </svg>
                    <svg x="0px" y="0px" width="100%" viewBox="0 0 96 96" class="gh-svg gh-svg-center"
                         enable-background="new 0 0 96 96">
                         <rect width="40" height="5" x="32" y="46" class="gh-svg-rect gh-svg-rect-center"></rect>
                    </svg>
                    <svg x="0px" y="0px" width="100%" viewBox="0 0 96 96" class="gh-svg gh-svg-bottom"
                         enable-background="new 0 0 96 96">
                         <rect width="40" height="5" x="32" y="46" class="gh-svg-rect gh-svg-rect-bottom"></rect>
                    </svg>
               </button>
               <a routerLink="/">
                    <img class="min-logo gotop" src="assets/img/logo.png?v={{imgversion}}">
               </a>

               <div class="header-lang-translt">
                    <a *ngIf="IsShowChangeLanguge" class="lng-arb 2" (click)="switchLanguage(oppositeLang)"
                         href="javascript:void(0);">{{oppositeLang}}</a>






                    <span *ngIf="!IsUserLoggedIn" class="usr-img hidden-md hidden-lg"
                         style=" background: url(../assets/img/usr-img.png);"
                         (click)="openMobileNumberModal('');RemoveInForMobileView()"></span>
                    <span *ngIf="IsUserLoggedIn && (ProfileImage == null || ProfileImage == '' || ProfileImage == undefined)"
                         class="usr-img hidden-md hidden-lg" [routerLink]="['/account/dashboard' | localize]"
                         style=" background: url(../assets/img/usr-img.png);"></span>
                    <span *ngIf="IsUserLoggedIn && (ProfileImage != null && ProfileImage != '' && ProfileImage != undefined)"
                         class="usr-img hidden-md hidden-lg" [routerLink]="['/account/dashboard' | localize]"
                         [ngStyle]="{'background-image': 'url(' + ProfileImageUrl + ProfileImage + ')'}"></span>
               </div>
          </div>

          <!-- end container -->
          <!-- end navbar-collapse -->

          <!--   <div id="menuMask" *ngIf="showMenus" style="position: fixed;top: 0;left:0;right: 0;bottom: 0;height: 100vh !important;"
    ></div>
 (click)="switchMenu(true)"
     <button style="left: unset;left: unset;z-index: 999;position: absolute;right: 0px;" id="gh-svg-icons" class="gh-svg-wrapper" (click)="switchMenu(false)" data-target=".navbar-responsive-collapse" data-toggle="collapse">
     </button> -->



    


     <div id="navMenu" class="navbar-collapse navbar-responsive-collapse w100-sm collapse" aria-expanded="false">
          <div class="nav-bar-sm-container">
               <div class="container-home overflow-menu w100-sm">
                    <div class="main_menu">
                        
                              <div class="logo_head">
                                   <a routerLink="/" class="logo-anchor">
                                        <img src="assets/img/logo.png?v={{imgversion}}" class="logo gotop">
                                        <img class="min-logo-2 hidden gotop" src="assets/img/logo.png?v={{imgversion}}">
                                   </a>
                              </div>

                              <div class="right_menu">
                                   <ul class="nav navbar-nav Bnavlist menu nav-menu">

                                        <button style="float: right; left: unset; right: 0px; z-index: 999;"
                                             id="gh-svg-icons" class="gh-svg-wrapper navbar-toggle"
                                             data-target=".navbar-responsive-collapse" data-toggle="collapse">
                                             <img src="../../assets/img/cross-x.png?v={{imgversion}}" alt="">
                                        </button>
                                        <li class="mobileviewdiv hidden-lg hidden-md" *ngIf="!IsUserLoggedIn">
                                             <div class="left-header">
                                                  <span class="usr-img"
                                                       style="margin-top: 8px;background: url(../assets/img/usr-img.png);"
                                                       (click)="openMobileNumberModal('');RemoveInForMobileView()"></span>
                                                  <span style="padding-top: 9px;">
                                                       <!-- <h5>Hello,</h5> -->
                                                       <!-- <h3 style="text-transform: capitalize">{{UserName}}</h3> -->
                                                       <button style="background: transparent; border: none; padding: 3px 0;
                    display: block; font-size: 17px;" (click)="openMobileNumberModal('');RemoveInForMobileView()">{{ 'loginSignup' | translate}}</button>
                                                  </span>
                                             </div>
                                        </li>
     
                                        <li class="hidden-md visible-xs p-0" style="border-bottom: none;"
                                             *ngIf="IsUserLoggedIn">
                                             <div class="mobileviewdiv">
                                                  <div class="left-header">
     
                                                       <a class="padding-left-none"
                                                            [routerLink]="['/account/profile'] | localize"
                                                            (click)="RemoveInForMobileView()">
                                                            <span class="usr-img"
                                                                 *ngIf="ProfileImage == null || ProfileImage == '' || ProfileImage == undefined"
                                                                 style="display: inline-block;background: url(../assets/img/usr-img.png);"></span>
                                                            <span class="usr-img"
                                                                 *ngIf="ProfileImage != null && ProfileImage != '' && ProfileImage != undefined"
                                                                 style="display: inline-block;"
                                                                 [ngStyle]="{'background': 'url(' + ProfileImageUrl + ProfileImage + ')'}"></span>
                                                            <span style="
                                        display: inline-block;
                                        vertical-align: top;
                                    ">
                                                                 <!-- <h5>Hello,</h5> -->
                                                                 <h3 style="text-transform: capitalize">{{UserName}}
                                                                 </h3>
                                                            </span>
                                                       </a>
                                                  </div>
                                                  <div class="clearfix"></div>
                                                  <ul class="myacuntmenuhead">
                                                       <li><a [routerLink]="['/account/dashboard'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/mydashboard.svg"
                                                                      alt=""> {{'My_Dashboard' | translate}}</a></li>
                                                       <li><a [routerLink]="['/account/insurance/mypolicies','all'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/mypolicies.svg"
                                                                      alt="">{{'My_Policies' | translate}}</a></li>
     
     
                                                       <li><a [routerLink]="['/account/invoice'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/invoice.svg"
                                                                      alt="">{{'My_Invoice' | translate}}</a></li>
     
     
     
                                                       <li><a [routerLink]="['/account/profile'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/myprofile.svg"
                                                                      alt="">{{'My_Profile' | translate}}</a></li>
                                                       <li class="hidden"><a
                                                                 [routerLink]="['/account/notification'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 {{'Notifications' | translate}}</a></li>
                                                       <li><a [routerLink]="['/account/support'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/inbox.svg"
                                                                      alt="">{{'Support' | translate}}</a></li>
                                                       <li><a [routerLink]="['/account/changepassword'] | localize"
                                                                 (click)="RemoveInForMobileView()">
                                                                 <img class="mr10"
                                                                      src="../assets/img/change_password.svg"
                                                                      alt="">{{'Change_Password' | translate}}</a></li>
                                                       <!-- <li class="hidden-lg hidden-md loginbtn" *ngIf="IsUserLoggedIn"> -->
                                                       <li class="loginbtn" *ngIf="IsUserLoggedIn">
     
                                                            <a href="javascript:void(0)"
                                                                 (click)="logout();RemoveInForMobileView()">
                                                                 <img class="mr10" src="../assets/img/signout.svg"
                                                                      alt="">{{ 'logout' | translate}}</a>
     
                                                            <!-- <span *ngIf="!IsUserLoggedIn">
                    
                                                                      <button (click)="openMobileNumberModal('');RemoveInForMobileView()">{{ 'login' | translate}}</button>
                    
                                                                 </span> -->
                                                       </li>
                                                  </ul>
                                                  <div class="clearfix mb5"></div>
                                                  <hr
                                                       style="border: 1px solid #f3f1f1; margin-top: 0px; margin-bottom: 0px; margin-left: -22px;">
                                             </div>
                                        </li>
     
     
                                        <!-- <li class="" *ngFor="let menu of MenuCollection"> 
           <a href="/home/{{menu.OnClickPointToDivId}}"  class="">{{menu.MenuName}}</a>
      </li> -->
                                        <!-- <li class="" [ngClass]="{'active':MenuSelection=== 'home'}"> <a href="home#home"
                                                  [routerLink]="['/home'] | localize" fragment="policy"
                                                  (click)="MenuSelection = 'home'; RemoveInForMobileView()"
                                                  class="scrollTop"> <img class="mr10" src="../assets/img/home.svg"
                                                       alt="">{{ 'home' | translate}}</a> </li> -->
                                        <li class="" [ngClass]="{'active':MenuSelection=== 'policy'}"> <a
                                                  href="home#policy" [routerLink]="['/home'] | localize"
                                                  fragment="policy" class=""
                                                  (click)="MenuSelection = 'policy'; RemoveInForMobileView()"> <img
                                                       class="mr10" src="../assets/img/mypolicies.svg"
                                                       alt="">{{ 'policy' | translate}}</a> </li>
                                         <!-- <li class="" [ngClass]="{'active':MenuSelection=== 'fileclaim'}"> <a
                                                 href="home#fileclaim" [routerLink]="['/home'] | localize"
                                                 fragment="fileclaim" class=""
                                                 (click)="MenuSelection = 'fileclaim'; RemoveInForMobileView()"> <img
                                                      class="mr10" src="../assets/img/mypolicies.svg"
                                                      alt="">{{ 'fileclaim' | translate}}</a> </li> -->
                                        <li class="" [ngClass]="{'active':MenuSelection=== 'contact'}"> <a
                                                  [routerLink]="['/home'] | localize" fragment="home_footer" class=""
                                                  (click)="MenuSelection = 'contact'; RemoveInForMobileView()"><img
                                                       class="mr10" src="../assets/img/contactus.svg"
                                                       alt="">{{ 'contactus' | translate}}</a> </li>
                                                       <!-- href="http://kfhtakaful.com" -->
                                        <li class=""> <a href="{{corporateUrl}}" target="_blank" class=""
                                                  (click)="RemoveInForMobileView()"><img class="mr10"
                                                       src="../assets/img/myprofile.svg"
                                                       alt="">{{ 'aboutus' | translate}}</a> </li>
                                        <!-- <li class="" [ngClass]="{'active':MenuSelection=== 'aboutus'}"> <a href="http://148.66.142.181:881/takaful" target="_blank" (click)="MenuSelection = 'aboutus'" class="">About Us</a> </li> -->
                                        <!-- <li class=""> <a href="http://kfhtakaful.com" class="crpt-btn"
                                                  (click)="RemoveInForMobileView()" target="_blank"> <img class="mr10"
                                                       src="../assets/img/corporate.svg"
                                                       alt="">{{ 'corporates' | translate}}</a> </li> -->
     
                                        <!-- <li class=""> <a href="home#theteam" class="">The Team</a> </li>
     <li class=""> <a href="home#service" class="">Our Service</a> </li> -->
                                        <!--   -->
                                        <!-- <li class=""> <a href="home#Media" class="">News</a> </li> -->
     
                                        <!--<li class="userlogin hidden-lg hidden-md"><b>Welcome :</b> Guest User</li>
     <li class="hidden-lg hidden-md"> <a href="" class="">Individual</a> </li>
     <li class="hidden-lg hidden-md"> <a href="" class="">Corporates</a> </li> -->
     
     
     
                                        <!-- <li class="hidden-lg hidden-md">
          <span *ngIf="IsUserLoggedIn">
               <a [routerLink]="['/account/dashboard'] | localize" (click)="RemoveInForMobileView()">{{ 'myAccount' | translate}}</a>
          </span>
     </li> -->
     
     
                                   </ul>



                                   <ul class="wel_user">

                                        <li class="hidden" style="position: relative; margin-right: 10px;">
                                             <span *ngIf="IsUserLoggedIn && countTotalNotification > 0">
                                                  <a class="notification-count"
                                                       [routerLink]="['/account/notification' | localize]">
                                                       <i class="fa fa-bell-o"></i>
                                                       <span class="biconcnt">{{countTotalNotification}}</span>
                                                  </a>
                                             </span>
                                        </li>
                                        <li class="usrnameli" *ngIf="IsUserLoggedIn">
     
     
                                             <a [routerLink]="['/account/dashboard' | localize]"
                                                  style="text-transform: capitalize" class="usrnamelbl" (click)="MenuSelection = ''">
                                                  {{'Welcome' | translate}}, {{UserName}}
                                                  <!-- <i class="fa fa-angle-down"></i> -->
                                                  <!-- <i class="fa fa-angle-down"></i> -->
                                             </a>
                                        </li>
                                        <!-- <li class=" individual"> <a href=""  class="">Individual</a> </li>
               <li class=" corporates"> <a href=""  class="">Corporates</a> </li> -->
                                        <li class="hidden">
                                             <span *ngIf="IsUserLoggedIn">
                                                  <a
                                                       [routerLink]="['/account/dashboard' | localize]">{{'myAccount' | translate}}</a>
                                             </span>
                                        </li>
                                        <li>
                                             <span *ngIf="IsUserLoggedIn">
                                                  <!-- <a href="javascript:void(0)" (click)="logout()"><img src="assets/img/logout.png" style="    margin: 0 10px 0 0;" alt="">{{ 'logout' | translate}}</a> -->
                                                  <a href="javascript:void(0)" (click)="logout()">
                                                       <img src="assets/img/logout.png" class="mr10" style="filter: brightness(0) invert(1);"
                                                            alt="">{{ 'logout' | translate}}</a>
                                             </span>
                                             <span *ngIf="!IsUserLoggedIn">
                                                  <a href="javascript:void(0)"
                                                       (click)="openMobileNumberModal('');">{{ 'loginSignup' | translate}}</a>
                                             </span>
                                        </li>
                                        <li class="">
     
                                             <a *ngIf="IsShowChangeLanguge" class="lng-arb2"
                                                  (click)="switchLanguage(oppositeLang)"
                                                  href="javascript:void(0);">{{oppositeLang}}</a>
     
                                        </li>
                                   </ul>



                              </div>
                         
                    </div>
               </div>
          </div>
     </div>




     






          
     </div>
</header>