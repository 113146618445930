import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';

export abstract class BaseComponent implements OnDestroy{
  public subscriptions: Subscription[] = [];

  public ngOnDestroy(): void {
    //console.log("destroyed");
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}