<form action="javascript:void(0);" [formGroup]="forgotPasswordForm"  class="" (ngSubmit)="forgotTravelPassword()">

    <div class="response" id="cresponse" style="margin: 0px; color: #098b41; position: absolute; top: 15px; left: 0px; right: 0px; text-align: center; font-size: 14px;
    font-weight: 500; text-transform: capitalize;">{{SuccessMessage}}</div>


    <div class="modal-body login_modal">
      <div class="form-group">
      <div class="row">
        <div  class="col-md-12">
          <label>{{'Mobile_No' | translate}} </label>
          <div [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [separateDialCode]="true"
              [enablePlaceholder]="true"	 
              [maxLength]="10"	
              [selectedCountryISO]="selectedCountryISO" [phoneValidation]="false" name="phone" formControlName="Email"
              (keypress)="firstWhiteSpace($event)" >
            </ngx-intl-tel-input>
          </div>
        <!-- <div class="col-md-12 d-flex inpfcs p-0" style="border-left: 1px solid transparent; padding-left: 14px;direction:ltr;">
          
          <div>
              <select name="" style="min-width: 53px;padding: 6px;" class="form-control" disabled>
                <option value="">+ 965</option>
              </select>
            </div>
          <input type="text"id="inputemailmobile" name="cphone" class="form-control" placeholder="" maxlength="50"
          formControlName="Email" pattern="[0-9]*" (keypress)="numberOnly($event)" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">
        </div> -->
      </div>
      </div>




    </div>

    <div class="response" id="cresponse" style="text-align: left; margin: -10px 0px 0px 0px; margin-bottom: 10px;color:red; position: absolute; font-size: 12px;">{{ErrorMessage}}</div>

  </div>
  <div class="clearfix mb15"></div>
  <div class="modal-footer">
       <button type="submit" class="btn btn-primary b1 detailsbtn " id="btn1" *ngIf="IsFormSubmit == false" style="background-color: #098b41;">{{'Submit' | translate}}</button>
      <a href="javascript:void(0)" class="btn btn-primary b1 detailsbtn processing" *ngIf="IsFormSubmit == true" style="background-color: #098b41;">{{'Processing' | translate}}</a>
    </div>








</form>
