export enum ApiRequestTypesEnum{
    Direct = 0,
    ForgotPassword=1,
    Travel= 2,
    Domestic = 3,
    House=4,
    Car=5,
    MarineHull=6,
    TplMotor=7,
    Manasek=9,
    CarClaim=102,
    OtherDomestic=103

}