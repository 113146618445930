import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, TravelService, ApiRequestTypesEnum, InsuranceTypeEnum } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService, ILoginContext, CurrentLanguage } from '@app/core';
import { LayoutHeaderComponent } from '../../../../shared';
import { MatButtonToggleGroup } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router'; 
import { Guid } from 'guid-typescript';
import { PasswordValidators } from '@app/shared/PasswordValidators';
import { spaceValidator } from '@app/modules/domestic/pages/NoSpaceValidator';
@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})

export class CustomerDetailsComponent extends CurrentLanguage implements OnInit {
  @Input() child: { rid: string, rtype: number, policytype: number, travelTo: number, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, VerifiedOtpRef: string, PolicyId: number, EmailMobileNo: string, IsforgotPass: boolean, MobileObject:any };
  rid: string;
  rtype: number;
  travelTo: number;
  CountryCodes_Fk_Id: number;
  CountryCode: string;
  MobileNo: string;
  VerifiedOtpRef: string;
  PolicyId: number;
  /////////////////////////////////////////////
  returnUrl: string;
  submitted = false;
  showSuccess = false;
  IsFormSubmit = false;
  showPassword: boolean = false;
  customerDetailsForm: FormGroup;
  request = { LanguageTypeId: 1, Name: "", EmailId: null, Password: "", RequestId: "", RequestType: 0, VerifiedOtpRef: "" };
  emailPattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
  
  StrongPasswordRegx: RegExp =/^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-])(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
  namepattern=/^[A-Za-z\u0600-\u06FF\s]+$/;
  ErrorMessage = "";
  policytype: number = 0;
  lang: string;
  displayMobileNo:string = "";
  constructor(
    public bsModalRef: BsModalRef,
    // private layoutHeaderComponent:LayoutHeaderComponent,
    // private modalService: ModalService,
    private accountService: AccountService,
    private authService: AuthService,
    private travelService: TravelService,
    private activeroute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private localize: LocalizeRouterService) {
      super(translate);
    this.lang = this.translate.currentLang;
    this.customerDetailsForm = this.customerFormValidation();
    // get batchId from url bar '/'
    // this.rid = this.activeroute.snapshot.queryParams['rid'];
    // get return url from route parameters or default to '/'
    this.returnUrl = this.activeroute.snapshot.queryParams['returnUrl'] || '/';
  }


  ngOnInit() {
   
    if (this.rtype === undefined || this.rtype == null){
      this.rtype = this.child.rtype;
    }

    if (this.PolicyId === undefined || this.PolicyId == null){
      this.PolicyId = this.child.PolicyId;
    }

    if (this.travelTo === undefined || this.travelTo == null){
      this.travelTo = this.child.travelTo;
    }
    // this.child.rid =this.rid;
    // this.child.rtype =this.rtype;
    // this.child.CountryCodes_Fk_Id =this.CountryCodes_Fk_Id; 
    this.displayMobileNo = this.child.MobileObject.dialCode + "-" + this.child.MobileNo;// =this.CountryCode;  
    // this.child.MobileNo =this.MobileNo;
    // this.child.VerifiedOtpRef =this.VerifiedOtpRef;
    // this.child.PolicyId =this.PolicyId;
  }

  get f() { return this.customerDetailsForm.controls; }

  updateCustomerDetails() {
    
    if (this.rid === undefined || this.rid === null || this.rid === "") {
      this.rid = this.child.rid;
    }

    this.submitted = true;    
    this.request = this.customerDetailsForm.value;
    this.request.LanguageTypeId = this.appLangId;
    //this.request.Name = "Guest user";
    //this.request.EmailId = "Email id not available";
    if (this.rtype === ApiRequestTypesEnum.Direct) {
      this.request.RequestId = "00000000-0000-0000-0000-000000000000";
    }
    else {
      this.request.RequestId = this.rid;
    }
    if (this.child != undefined && this.child.policytype == 5) {
      this.policytype = 5;
    }
    this.request.RequestType = this.rtype > 0 ? this.rtype : this.child.rtype;    
    this.request.VerifiedOtpRef = this.VerifiedOtpRef == undefined ? this.child.VerifiedOtpRef : this.VerifiedOtpRef;
    if (this.customerDetailsForm.invalid) {
      return;
    }
    else {
      this.IsFormSubmit = true;
      var result = this.authService.updateCustomerDetails(this.request);
      result.subscribe(resp => {
         if( resp.ResponseStatus===10)
         {
         this.ErrorMessage = this.translate.instant('Invalid_password_formate');
         this.IsFormSubmit = false;
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);
         }
        else if (resp.ResponseStatus == 1) {
          /** Reload user information... */
          LayoutHeaderComponent.updateUserStatus.next(true);
          if (this.policytype == 5) {
            this.rtype = 5;
          }
          if (this.rtype === undefined || this.rtype === null) {
            let translatedPath: any = this.localize.translateRoute('/account/dashboard');
            this.router.navigate([translatedPath]);
          }
          else if (this.rtype == ApiRequestTypesEnum.Travel) {
            /** Route to next step... */
            //let translatedPath: any = this.localize.translateRoute('/travel/buy/travelstep3');            
            //this.router.navigate([translatedPath, this.PolicyId, this.rid, this.travelTo]);
            let translatedPath: any = this.localize.translateRoute('/travel/buy/travelstep2'); 
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId,1,'0','1',this.travelTo]);
          }
          else if (this.rtype == ApiRequestTypesEnum.Domestic) {
            /** Route to next step... */
            //let translatedPath: any = this.localize.translateRoute('/domestic/buy/domesticstep3');
            //this.router.navigate([translatedPath, this.PolicyId, this.rid, 0]);
            let translatedPath: any = this.localize.translateRoute('/domestic/buy/domesticstep2');
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId, 1, '0', '1', '1']);
          }
          else if (this.rtype == ApiRequestTypesEnum.OtherDomestic) {
            /** Route to next step... */
            //let translatedPath: any = this.localize.translateRoute('/domestic/buy/domesticstep3');
            //this.router.navigate([translatedPath, this.PolicyId, this.rid, 0]);
            let translatedPath: any = this.localize.translateRoute('/domestic/buy/usdstep2');
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId, 1, '0', '1', '1']);
          }
          else if (this.rtype == ApiRequestTypesEnum.House) {
            /** Route to next step... */
            // let translatedPath: any = this.localize.translateRoute('/homeinc/buy/homestep3');
            // this.router.navigate([translatedPath, this.PolicyId, this.rid, 0]);

            let translatedPath: any = this.localize.translateRoute('/homeinc/buy/homestep2');
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId, 1,'0','1','1']);

          }
          else if (this.rtype == ApiRequestTypesEnum.Car) {
            /** Route to next step... */
            //this.router.navigate(['car/buy/carstep3', this.PolicyId, this.rid, 0]); 
            let translatedPath: any = this.localize.translateRoute('/car/buy/carstep2');            
            this.router.navigate([translatedPath, InsuranceTypeEnum.CarInsurance, this.rid]);
          }
          else if (this.rtype == ApiRequestTypesEnum.MarineHull) {
            /** Route to next step... */
            // let translatedPath: any = this.localize.translateRoute('/marine/buy/marinehullstep3');
            // this.router.navigate([translatedPath, this.PolicyId, this.rid, 0]);

            let translatedPath: any = this.localize.translateRoute('/marine/buy/marinehullstep2');
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId, 1, '0', '1', 0]);
          }
          else if (this.rtype == ApiRequestTypesEnum.TplMotor) {
            /** Route to next step... */
            //this.router.navigate(['car/buy/carstep3', this.PolicyId, this.rid, 0]); 
            let translatedPath: any = this.localize.translateRoute('/tplmotor/buy/tplmotorstep2');            
            this.router.navigate([translatedPath, InsuranceTypeEnum.TplMotorInsurance, this.rid]);
          }
          else if (this.rtype == ApiRequestTypesEnum.Manasek) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/manasek/buy/manasekstep2'); 
            this.router.navigate([translatedPath, this.PolicyId, this.request.RequestId]);
          }
          else if (this.rtype == ApiRequestTypesEnum.CarClaim) {
            /** Route to next step... */
            let translatedPath: any = this.localize.translateRoute('/carclaim/claimstep1');
            this.router.navigate([translatedPath, 2, Guid.create().toString()]);
          }
          else if (this.returnUrl !== "" && this.returnUrl !== "/" && this.returnUrl !== "//" && this.returnUrl !== "\\") {
            this.router.navigateByUrl(this.returnUrl);
          }
          else {
            let translatedPath: any = this.localize.translateRoute('/account/dashboard');
            this.router.navigate([translatedPath]);
          }

          /** Hide modal popup... */
          //this.bsModalRef.hide();
        }
        else {
          this.IsFormSubmit = false;
          this.ErrorMessage = resp.ResponseMessage;
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);
        }
        this.submitted = false;
    
      });
    }
  }

  customerFormValidation() {
    return this.formBuilder.group({
      Name: ['', [Validators.required,spaceValidator,Validators.pattern(this.namepattern)]],
      EmailId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      // Password: ['', Validators.required]
      // Password: ['', [Validators.required,Validators.pattern(this.StrongPasswordRegx)]]
      Password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        PasswordValidators.patternValidator(new RegExp("(?=.*[0-9])"), {
          requiresDigit: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[A-Z])"), {
          requiresUppercase: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[a-z])"), {
          requiresLowercase: true
        }),
        PasswordValidators.patternValidator(new RegExp("(?=.*[$@^!%*?&])"), {
          requiresSpecialChars: true
        })
      ])],

    });
  }



  get requiredValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("required");
  }

  get minLengthValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("minlength");
  }

  get requiresDigitValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("requiresDigit");
  }

  get requiresUppercaseValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("requiresUppercase");
  }

  get requiresLowercaseValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("requiresLowercase");
  }

  get requiresSpecialCharsValid() {
    return !this.customerDetailsForm.controls["Password"].hasError("requiresSpecialChars");
  }




get  passwordFormField() {

    return this.customerDetailsForm.get('Password');
    
  }


  togglePasswordVisibility() {
   
   this.showPassword = !this.showPassword;
   
 }



}
