import { Injectable,OnDestroy } from '@angular/core'; 
import { environment as env } from '@env/environment'; 
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class CurrentLanguage  implements OnDestroy{
    public subscriptions: Subscription[] = [];

   
  /** Current language class constructor...*/
  constructor(private translation: TranslateService) {}


  /** Return language id of current selected 
   *  language value. If selected language is 
   *  english return 1 for arabic return 2.
  */
  
  public get appLangId() : number {
    return (this.translation.currentLang === env.lang.ArText ? env.lang.Ar : env.lang.En);
  }

 
  /** Return current selected language code...*/
  public get appLangName():string {      
    return this.translation.currentLang;
  }


  public ngOnDestroy(): void {
    //console.log("destroyed");
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}





