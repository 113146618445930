<!-- <div [hidden]="isShowError" class="alert alert-danger mssgcls">
    {{ ErrorMessage }}
  </div> -->
  <div [hidden]="isShowSuccess" class="alert alert-success mssgcls">
    {{ SuccessMessage }}
  </div>
<div class="clearfix mb10"></div>
    <fieldset [disabled]="isLoading">
    
      <form   (ngSubmit)="resetpassword()" [formGroup]="resetPasswordForm" >


          <div class="form-group">
            <p>{{'Change_your_Password_Make_sure_your_password_cannot_be_easily_guess_by_others'|translate}}</p>
        </div>
          <div class="form-group">
            <label class="d-block" style="width:100%;">
             <input type="{{showPassword ? 'text' : 'Password' }}" id="inputpassword" class="form-control" placeholder="{{'Password' | translate}}" 
               formControlName="Password" [ngClass]="{ 'is-invalid': submitted && f.Password.errors }"  maxlength="15">
            </label>
            <!-- <button (click)="togglePasswordVisibility()"  class="eye_icon">
              <i class="fa" [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"></i>
            </button> -->

            <span class="input-group-text" class="eye_icon">
              <i  class="fa" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"   (click)="togglePasswordVisibility()" ></i>
            </span> 
           
          </div>


          <!-- *ngIf="passwordFormField?.dirty" -->
          

          <div class="form-group">
            <label class="d-block"  style="width:100%;">
              <input type="{{ConformshowPassword ? 'text' : 'Password' }}"   maxlength="15" class="form-control" placeholder="{{'Confirm_Password' | translate}}" formControlName="ConfirmPassword" [ngClass]="{ 'is-invalid': submitted && f.ConfirmPassword.errors }">
            </label>
          
            <span class="input-group-text" class="eye_icon">
              <i  class="fa" [ngClass]="{'fa-eye-slash': !ConformshowPassword, 'fa-eye': ConformshowPassword}"   (click)="ConformtogglePasswordVisibility()" ></i>
            </span> 
               
          </div>


          <div  *ngIf="passwordFormField?.dirty" class="invalid-feedback">
            <div id="validation-rules" class="mt-3" *ngIf="requiredValid">
              <!-- minlength -->
              <div [ngClass]="{ 'text-success': minLengthValid, 'text-danger': !minLengthValid }" class="valid_pass">
                <i [attr.class]="minLengthValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                {{'Six_characters_long' | translate}}
              </div>
  
              <!-- requires digit -->
              <div [ngClass]="{ 'text-success': requiresDigitValid, 'text-danger': !requiresDigitValid }" class="valid_pass">
                <i [attr.class]="requiresDigitValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                {{'One_number' | translate}}
              </div>
  
              <!-- requires uppercase -->
              <div [ngClass]="{ 'text-success': requiresUppercaseValid, 'text-danger': !requiresUppercaseValid }" class="valid_pass">
                <i [attr.class]="requiresUppercaseValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                {{'One_upper_case_letter' | translate}}
              </div>
  
              <!-- requires lowercase -->
              <div [ngClass]="{ 'text-success': requiresLowercaseValid, 'text-danger': !requiresLowercaseValid }" class="valid_pass">
                <i [attr.class]="requiresLowercaseValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                {{'One_lower_case_letter' | translate}}
              </div>
  
              <!-- requires special characters -->
              <div [ngClass]="{ 'text-success': requiresSpecialCharsValid, 'text-danger': !requiresSpecialCharsValid }" class="valid_pass">
                <i [attr.class]="requiresSpecialCharsValid ? 'ri-checkbox-circle-fill' : 'ri-close-circle-fill'"></i>
                {{'One_special_character' | translate}}
              </div>
            </div>
          </div>


          <!-- <div class="passwrd_wrap">

          <ul class="text-red-400 text-sm passwrd_verfy" *ngIf="passwordFormField?.dirty" >

            <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('.{6,}')}">{{'Password_Lenght'|translate}}</li>
         
            <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[a-zA-Z])')}">{{'Password_char'|translate}}</li >
         
            <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(.*[0-9].*)')}">{{'Password_digit'|translate}}</li>
         
            <li class="block" [ngClass]="{'text-success': passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">{{'Password_special_character'|translate}}</li>
         
    
          </ul>
        </div> -->


          <!-- <div> <label class="response" id="cresponse" style="text-align: left;color:red;font-size: 12px;">{{'Password_Formate' | translate}}</label></div> -->
          <div class="form-group modal-footer">
            <button style="font-size: 16px;" type="submit" class="btn btn-primary btn-block detailsbtn btn-raised" [disabled]="isLoading">
              <span [hidden]="isLoading" >Reset password</span>
              <span [hidden]="!isLoading">
                <fa-icon icon="asterisk" size="3x" [spin]="true"></fa-icon>
              </span>
            </button>
          </div>
         
      </form>
    </fieldset>
    
