import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class TravelService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    GetTravellersArray(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetTravellersListStep1", policyplanRequest);
    }
    GetTravelPolicyDetailsStep2(request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetTravelInsurancePolicyDetailsStep2", request);
    }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

    /** GET addone from the server */
    getAddOnsList(addonRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetAddOnsList", addonRequest);
    }
    /** GET policy cost from the server */
    getPlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetTravelCost", policyplanRequest);
    }

    getTravelPolicyPlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetTravelPolicyPlanCost", policyplanRequest);
    }

    GetTravellerAssuredInformation(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetAdditionalTravellersDetails", policyplanRequest);
    }

    SaveTravelAssuredPersonDetails(policyRequest): Observable<any> {
        return this.apiService.post(config.apiTravel + "/SaveTravelAssuredPersonDetails", policyRequest);
    }

    /** GET Policy step2 from the server */
    PolicyStep2(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/SaveTravelInsurancePolicyStep2", policyplanStep2Request);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/CreatePreCustomerPolicy", policyplanStep2Request);
    }

    /** Create Pre Policy  from the server */
    CreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/CreateCustomerPolicy", policyplanStep2Request);
    }

     /** Create Pre Policy  from the server */
     MobileCreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/MobileCreateCustomerPolicy", policyplanStep2Request);
    }

    
     /** GET Policy  from the server */
     PolicyStep3(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetTravelInsurancePolicyHolderDetails", policyplanStep2Request);
    }
    /** Save travel policy step 1 */
    SaveTravelPolicyStep1(travelInsurancePolicyRequest): Observable<any> {
        //console.log("hello"+travelInsurancePolicyRequest.RequestedId);
        return this.apiService.post(config.apiTravel + "/SaveTravelInsurancePolicyStep1", travelInsurancePolicyRequest);
    }

    getPolicyPlanBenefitsDetails(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetPolicyPlanBenefitsDetails", requestAuthentication);
    }

    /** Get policyList  from the server */
    GeMyPolicyList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetMyPolicies", RequestAuthentication);
    }
    /** Get policyList  from the server */
    GetMyPoliciesStatusCount(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetMyPoliciesStatusCount", RequestAuthentication);
    }
    
    /** Get PolicyDetails  from the server */
    getPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetMyPoliciesDetails", RequestAuthentication);
    }
    /** Get PolicyHolder  from the server */
    GetPolicyHolder(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetPolicyHolder", RequestAuthentication);
    }

    /** Get PolicyAssuredPerson  from the server */
    GetPolicyAssuredPerson(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetPolicyAssuredPerson", RequestAuthentication);
    }

    /**  update PolicyDetails  from the server */
    updatePolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/SetPolicyHolder", RequestAuthentication);
    }
    /** update PolicyAssuredPerson  from the server */
    updatePolicyAssuredPerson(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/SetPolicyAssuredPerson", RequestAuthentication);
    }

    /** Get Required Attachment from the server */
    GetRequiredAttachmentType(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDocumentslist", RequestAuthentication);
    }

    /** Update Attachment */
    UpdateAttachment(request): Observable<any> {
        return this.apiService.post(config.apiTravel + "/UpdateDocumentApproved", request);
    }

    /** Get Insurance Coverage from the server */
    GetInsuranceCoverage(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetInsuranceCoverage", RequestAuthentication);
    }

    /** Get PolicyFee and Payment  from the server */
    GetPolicyFeeANDPayment(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetPolicyFeeANDPayment", RequestAuthentication);
    }

    saveRequiredDocuments(formData:FormData): Observable<any> {
                
        return this.apiService.PostMultiPart(config.apiTravel + "/UploadDocument", formData);
    }
  
    
  GetPolicyReceipt(RequestAuthentication): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/GetPolicyReceipt", RequestAuthentication);
  }

  MobileGetPolicyReceipt(RequestAuthentication): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/MobileGetPolicyReceipt", RequestAuthentication);
  }

  GetReceiptOnMail(RequestAuthentication): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/GetReceiptOnMail", RequestAuthentication);
  }

  /** Delete policy required document **/
  DeletePolicyDocument(request): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/DeletePolicyDocument", request);
  }

  /** get complete status **/
  getPolicyCompleteStatus(request): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/GetPolicyCompleteStatus", request);
  }

  /** Get Required Attachment from the server */
  GetAssuredPersonDocumentslist(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiTravel + "/GetRequiredDocumentslist", RequestAuthentication);
}

RequiredDocumentsUpload(formData:FormData): Observable<any> {
                
    return this.apiService.PostMultiPart(config.apiTravel + "/UploadRequiredDocuments", formData);
}

  /** Get Required Attachment from the server */
  GetTravelLeadRequiredDocumentslist(RequestAuthentication): Observable<any> {
    return this.apiService.post(config.apiTravel + "/GetTravelLeadRequiredDocumentslist", RequestAuthentication);
}
UploadTravellerDocuments(formData:FormData): Observable<any> {
                
    return this.apiService.PostMultiPart(config.apiTravel + "/UploadTravellerDocuments", formData);
}

GenerateIssueCertificate(request): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/GenerateCertificate", request);
  }

  GetPaymentFailedInsuranceReceipt(request): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiTravel + "/GetPaymentFailedInsuranceReceipt", request);
  }

  ApplyPromoCode(applyCouponRequest): Observable<any> {
      
    return this.apiService.post(config.apiTravel + "/applycoupon", applyCouponRequest);
}

}
