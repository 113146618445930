// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import env from './.env';

export const environment = {
  production:true,
  imgversion: "0.0.2",
  version: env.npm_package_version + '-dev', 
  appUrl:'/',  


   // corporateUrl:'https://kfhtakaful.com/',
  //  serverUrl: 'https://services.kfhtakaful.com/api',
  //  profileImageUrl: 'https://services.kfhtakaful.com/Images/Profile/',  
  //  policyDocumentPath: 'https://services.kfhtakaful.com/Images/PolicyDocument/',
  //  baseUrl:'https://services.kfhtakaful.com/',
  //  paymentUrl:'https://payment.kfhtakaful.com/',

  corporateUrl:'http://148.66.142.181:3001/',
  serverUrl: 'https://qaservices.kfhtakaful.com/api',
  profileImageUrl: 'https://qaservices.kfhtakaful.com/Images/Profile/',  
  policyDocumentPath: 'https://qaservices.kfhtakaful.com/Images/PolicyDocument/',  
  baseUrl:'https://qaservices.kfhtakaful.com/',
  paymentUrl:'https://qapayment.kfhtakaful.com/',

  //  serverUrl: 'http://localhost:1993/api',
  //  profileImageUrl: 'http://localhost:1993/Images/Profile/',
  //  policyDocumentPath: 'http://localhost:1993/Images/PolicyDocument/',
  //  baseUrl:'http://localhost:1993/',
  //  paymentUrl: 'http://localhost:1176/',
    

  envName: 'DEV',
  localStorageKeyName: 'itpl_ci',
  isUserLoggedIn: false,
  lang: {
    "En": 1,
    "EnText": "en",
    "Ar": 2,
    "ArText": "ar"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
