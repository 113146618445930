export enum TravelTypesEnum {

    WorldwideincludingUSACanada = 1,
    WorldwideexcludingUSACanada = 2,
    EuropeAndMiddleEast = 3,
    Schengen = 4,
    SchengenMappingId=16

}

// export enum InsuranceTypeEnum {
//     TravelInsurance = 28,
//     DomesticInsurance = 29,
//     HomeInsurance = 34
// }