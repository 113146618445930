import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import {RequestAuthentication} from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

@Injectable({
    providedIn: 'root'
})
  
export class SupportService 
{
  
    constructor(
    private jsonApiService: JsonApiService,
    private apiService: ApiService) {}

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

    /** Get support mail list */
    GetSupportMailList(request): Observable<any> {
        return this.apiService.post(config.apiSupport + "/GetCustomerSupportList", request);
    }

    /** Compose mail */
    ComposeMail(request): Observable<any> {
        return this.apiService.post(config.apiSupport + "/CustomerComposeMail", request);
    }

    /** Get support mail thread */
    GetSupportMailDetails(request): Observable<any> {
        return this.apiService.post(config.apiSupport + "/GetSupportMailDetails", request);
    }

    /** Compose mail */
    ReplyMail(request): Observable<any> {
        return this.apiService.post(config.apiSupport + "/ReplyMailByCustomer", request);
    }
    
}