import { Injectable ,OnDestroy } from '@angular/core';
import { environment as env } from '@env/environment';
import { BehaviorSubject, of, Observable, Subscription, throwError } from 'rxjs';
import { User } from '../models/user.model';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class CurrentAccessToken implements OnDestroy {
  public loggedInUserSubject: BehaviorSubject<User>;

  constructor() {
    this.loggedInUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(env.localStorageKeyName)));

  }

  ngOnDestroy() {
    this.loggedInUserSubject.next(null);
    this.loggedInUserSubject.complete();
  }
  public loggedInUserInfoObserve(): Observable<User> {

  //  if (this.loggedInUserSubject.value === null) {
      this.loggedInUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(env.localStorageKeyName)));

    //}
    return this.loggedInUserSubject;
  }

  public get loggedInUserInfo(): User {

    //  if (this.loggedInUserSubject.value === null) {
        this.loggedInUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(env.localStorageKeyName)));
  
      //}
      return this.loggedInUserSubject.value;
    }

    
  public getToken() {
    // remove user from local storage to log user out
    return localStorage.getItem(env.localStorageKeyName);
  }


  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }


  public logout(): Observable<boolean> {

    // remove user from local storage to log user out
    localStorage.removeItem(env.localStorageKeyName);
    this.loggedInUserSubject.next(null);
    this.loggedInUserSubject.complete();
    //   this.currentUserSubject.next(null);
    return of(false);
  }


  public isTokenExpired(token?: string): boolean {

    var isExpired = this.checkTokenExpiry(token);

    /** do logout on token expiry..*/
    if (isExpired) {
      // remove user from local storage to log user out
      localStorage.removeItem(env.localStorageKeyName);
      this.loggedInUserSubject.next(null);
      this.loggedInUserSubject.complete();
    }

    /** return true or false...*/
    return isExpired;
  }

  private checkTokenExpiry(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }



}