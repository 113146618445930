import { Component, Input, Output, EventEmitter, OnInit,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, TravelService, CurrentLanguage } from '@app/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  BsModalRef } from 'ngx-bootstrap/modal'; 
import { AuthService,ILoginContext } from '@app/core';
import { LayoutHeaderComponent } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends CurrentLanguage implements OnInit 
{
  @Input()child: {rid: string, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, VerifiedOtpRef: string, PolicyId: number ,EmailMobileNo: string,IsforgotPass:boolean, MobileObject:any};
  @Output() messageEvent = new EventEmitter<any>();
 
  submitted = false;
  showSuccess = false;
  IsFormSubmit = false;
  IsFogotPassword=true;
  forgotPasswordForm: FormGroup;
  authActiveStepIndex = 5;
  request = { LanguageTypeId: 1, Email: "", CountryDialCode:""};

  emailPattern =/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
  ErrorMessage = "";
  SuccessMessage = "";

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  selectedCountryISO:any=CountryISO.Kuwait;
  //PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Kuwait
  ];
  PhoneReg:RegExp= new RegExp("^[0-9]{8,12}$");
  DialCode:any;

  constructor(public bsModalRef: BsModalRef,
    // private layoutHeaderComponent:LayoutHeaderComponent,
    // private modalService: ModalService,
    private accountService: AccountService,
    private authService: AuthService,
    private travelService: TravelService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder, private router: Router) {
      super(translate);
      this.forgotPasswordForm = this.forgotPasswordFormValidation();
    }

  ngOnInit() {
  
    this.selectedCountryISO=this.child.MobileObject.countryCode;
    if(this.child.IsforgotPass)
    {
    // this.forgotPasswordForm.controls.Email.setValue(this.child.EmailMobileNo);
    this.forgotPasswordForm.controls["Email"].setValue(this.child.MobileNo);
    }
  }

  get f() { return this.forgotPasswordForm.controls; }

  forgotTravelPassword() {
  
    this.submitted = true;
   
    this.request = this.forgotPasswordForm.value;
   var iss=this.forgotPasswordForm.invalid;
    if(this.forgotPasswordForm.get("Email").value.number.length < 8 || this.forgotPasswordForm.get("Email").value.number.length > 12 || !this.PhoneReg.test(this.forgotPasswordForm.get("Email").value.number)) 
    {
      this.forgotPasswordForm.controls['Email'].setErrors({'incorrect': true});
    }  
   if (this.forgotPasswordForm.invalid)
    {

      this.ErrorMessage = "Please enter valid  mobile no";
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);
      

      return;
    }
    else {
     
      this.IsFormSubmit = true;
      this.child.IsforgotPass=true;
      this.DialCode=this.forgotPasswordForm.get("Email").value.dialCode;
      this.child.EmailMobileNo=this.forgotPasswordForm.get("Email").value.number;//this.request.Email;
      this.request.LanguageTypeId = this.appLangId;
      this.request.Email = this.forgotPasswordForm.get("Email").value.number;
      this.request.CountryDialCode = this.forgotPasswordForm.get("Email").value.dialCode;
      var result = this.accountService.forgotTravelPasswordWeb(this.request);          
      result.subscribe(resp => {
        if (resp.ResponseStatus == 1) {
          this.otpverification();
          this.SuccessMessage = "Otp password mail sent";
          setTimeout(() => {
            this.SuccessMessage = "";
          }, 100);
        }
        else {
          this.ErrorMessage = resp.ResponseMessage;
          setTimeout(() => {
            this.ErrorMessage = "";
          }, 4000);
        }
        this.submitted = false;
        this.IsFormSubmit = false;
      });
    }
  }

  forgotPasswordFormValidation() {
    return this.formBuilder.group({
      //Email: ['', [Validators.required, Validators.pattern(this.emailPattern)]]
      Email: ['', [Validators.required]]
    });
  }
 
  otpverification() {
    const obj = {
      VerifiedOtpRef: "",
      PolicyId: this.child.PolicyId,
      authActiveStepIndex: 2,
      IsforgotPass:true,
      MobileNo: this.child.MobileNo,
      CountryCode: this.DialCode,
    }
    this.messageEvent.emit(obj);
  }
  ngAfterViewInit() {
    setTimeout(()=>{
     
        document.getElementById("inputemailmobile").focus();
    
    },100);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  firstWhiteSpace(event: any) {
    
    if (event.which === 32 && !event.target.value.length)
      event.preventDefault();
  }
}
