export class UpdateProfileRequest
{
    LanguageTypeId: number;
    Name: string;
    CountryCodes_Fk_Id: number;
    DOB: string;
    Gender_Fk_Id: number;
    CivilId:string;
    PassportNumber:string;
}

export class UpdateContactRequest
{
    LanguageTypeId: number;
    MobileNo: string;
    EmailId: string;
    PhoneNo: boolean;
    City:string;
    Address:string;
}

export class UpdateEmailRequest
{
    LanguageTypeId: number;    
    EmailId: string;
}

export class ChangePasswordRequest
{
    LanguageTypeId: number;
    OldPassword: string;
    Password: string;
    ConfirmPassword: string;
}