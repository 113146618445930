export class User {
    constructor(){
      this.AccessToken= new TokenDetails();
    }
    AccessToken: TokenDetails;
    UserName: string; 
    UserEmail: string;
    ProfileImage: string;
}

export class TokenDetails{
    access_token:string;
    token_type:string;
    expires_in:number;
}