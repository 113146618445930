export const config: any = {
    apiAuthentication: '/accounts',
    apiCorporate:'/home',
    apiTravel:'/travelPolicy',
    apiManasek:'/manasekpolicy',
    apiDomestic:'/domesticPolicy',
    apiOtherDomestic:'/otherdomesticPolicy',
    apiHomeInsurance:'/homePolicy',
    apiMarineHullInsurance:'/marinehullPolicy',
    apiCarInsurance:'/carPolicy',
    apiSupport:'/support',
    apiPolicyRequest:'/customerpolicyrequest',
    apiShared: '/shared',
    apiTplMotor:'/tplmotorpolicy',
    apiCarClaim:'/carClaim',
    apiCoupon:'/coupon',
};
