import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';


// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class HomeInsuranceService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

    /** Get home policy step 1 */
    GetHomeInsurancePolicyStep1(RequestAuthentication): Observable<any> {        
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeInsurancePolicyStep1", RequestAuthentication);
    }
    /** Save home policy step 1 */
    SaveHomePolicyStep1(homeInsurancePolicyRequest): Observable<any> {        
        return this.apiService.post(config.apiHomeInsurance + "/SaveHomeInsurancePolicyStep1", homeInsurancePolicyRequest);
    }

    /** Get home policy step 2 */
    GetHomeInsurancePolicyStep2(RequestAuthentication): Observable<any> {        
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeInsurancePolicyStep2", RequestAuthentication);
    }

    /** GET Policy step2 from the server */
    PolicyStep2(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/SaveHomeInsurancePolicyStep2", policyplanStep2Request);
    }

    /** Get home policy step 2 */
    GetHomeInsurancePolicyStep3(RequestAuthentication): Observable<any> {        
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeInsurancePolicyStep3", RequestAuthentication);
    }

    /** Get Required Attachment from the server */
    GetHomeLeadRequiredDocumentslist(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeLeadRequiredDocumentslist", RequestAuthentication);
    }

    UploadHomeDocuments(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/UploadHomeDocuments", formData);
    }

    /** Save home policy step 3 */
    SaveHomeInsurancePolicyStep3(request): Observable<any> {        
        return this.apiService.post(config.apiHomeInsurance + "/SaveHomeInsurancePolicyStep3", request);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/CreatePreCustomerPolicy", policyplanStep2Request);
    }


    /** GET Policy  from the server */
    GetHomeInsurancePolicyDetails(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeInsurancePolicyDetails", policyplanStep2Request);
    }

    GetDomesticPolicyReceipt(RequestAuthentication): Observable<any> {
            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GetDomesticPolicyReceipt", RequestAuthentication);
      }

      /** Get PolicyDetails  from the server */
    getHomePolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomePolicyDetails", RequestAuthentication);
    }

    /** Get PolicyHolder  from the server */
    GetPolicyHolder(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetPolicyHolder", RequestAuthentication);
    }
    
    /**  update PolicyDetails  from the server */
    updatePolicyHolderDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/SetPolicyHolder", RequestAuthentication);
    }
    
    /** Get PolicyAssuredPerson  from the server */
    GetDomesticHelperDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticHelperDetails", RequestAuthentication);
    }

    /** update PolicyAssuredPerson  from the server */
    updatePolicyHelperDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/UpdatePolicyHelperDetails", RequestAuthentication);
    }

    getDomesticPolicyBenefits(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiDomestic + "/GetDomesticPolicyBenefits", requestAuthentication);
    }

    GetHomePlans(requestAuthentication):Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomePlans", requestAuthentication);
    }

    GetHomePackage(requestAuthentication):Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomePackage", requestAuthentication);
    }

    /** GET policy cost from the server */
    getHomePlanCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetHomeCost", policyplanRequest);
    }
  
    GetHomeInsurancePolicyReceipt(RequestAuthentication): Observable<any> {
            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GetHomeInsurancePolicyReceipt", RequestAuthentication);
    }

    MobileGetHomeInsurancePolicyReceipt(RequestAuthentication): Observable<any> {
            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/MobileGetHomeInsurancePolicyReceipt", RequestAuthentication);
    }

    /** Get Insurance Coverage from the server */
    GetInsuranceCoverage(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetInsuranceCoverage", RequestAuthentication);
    }

      /** Get PolicyFee and Payment  from the server */
    GetPolicyFeeANDPayment(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/GetPolicyFeeANDPayment", RequestAuthentication);
    }

    /** Update Attachment */
    UpdateAttachment(request): Observable<any> {
        return this.apiService.post(config.apiHomeInsurance + "/UpdateDocumentApproved", request);
    }

    /** Get Required Attachment from the server */
    GetRequiredAttachmentType(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDocumentslist", RequestAuthentication);
    }

    saveRequiredDocuments(formData:FormData): Observable<any> {
                
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/UploadDocument", formData);
    }

    /** Delete policy required document **/
    DeletePolicyDocument(request): Observable<any> {
            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/DeletePolicyDocument", request);
    }

    /** Get assured building property details **/
    GetAssuredBuildingPropertyDetails(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GetAssuredBuildingPropertyDetails", request);
    }

    /** Get assured content property details **/
    GetAssuredContentPropertyDetails(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GetAssuredContentPropertyDetails", request);
    }

    /** Set assured building property details **/
    SetAssuredBuildingPropertyDetails(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/SetAssuredBuildingPropertyDetails", request);
    }

    /** Set assured content property details **/
    SetAssuredContentPropertyDetails(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/SetAssuredContentPropertyDetails", request);
    }

    /** Delete assured content property details **/
    DeleteAssuredContentPropertyDetails(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiHomeInsurance + "/DeleteAssuredContentPropertyDetails", request);
    }

    /** get complete status **/
  getPolicyCompleteStatus(request): Observable<any> {
            
    return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GetPolicyCompleteStatus", request);
  }

  GenerateIssueCertificate(request): Observable<any> {            
    return this.apiService.PostMultiPart(config.apiHomeInsurance + "/GenerateCertificate", request);
}
 /** Create Pre Policy  from the server */
 CreateCustomerPolicy(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiHomeInsurance + "/CreateCustomerPolicy", policyplanStep2Request);
}
 /** Create Pre Policy  from the server */
 MobileCreateCustomerPolicy(policyplanStep2Request): Observable<any> {
    return this.apiService.post(config.apiHomeInsurance + "/MobileCreateCustomerPolicy", policyplanStep2Request);
}
}