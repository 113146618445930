// export enum InsuranceTypeEnum {
//     CarInsurance = 27,
//     TravelInsurance = 28,
//     DomesticInsurance = 29,
//     HomeInsurance = 34  ,  
//     MarineHullInsurance = 30  
// }
export enum InsuranceTypeEnum {
    MotorThirdPartyLiability=1,
    CarInsurance = 2,
    TravelInsurance = 3,
    ManasekInsurance = 9,
    DomesticInsurance = 4,
    MarineHullInsurance = 5,
    MarineThirdPartyLiability=6,
    HomeInsurance = 7,
    TplMotorInsurance = 8  
}