import { Component, Input, Output, ViewChild, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, ApiRequestTypesEnum, CurrentLanguage } from '@app/core';
import { tap, delay, finalize, catchError } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryCodeResult } from '../../../../core/models/home/contact.model';
import { TravelInsuranceStep1Component } from '@app/modules/travel/pages/buy/step1/first.component';
// import{ForgotPasswordComponent} from "../forgot-password/forgot-password.component"
// import { ModalService } from '../../../../shared/services/modal-popup.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService, ILoginContext } from '@app/core';
import { LayoutHeaderComponent } from '../../../../shared';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent extends CurrentLanguage implements OnInit {

  @Input() child: { rid: string, rtype: number, travelTo: number, CountryCodes_Fk_Id: number, CountryCode: string, MobileNo: string, PolicyId: number, EmailMobileNo: string, IsforgotPass: boolean, MobileObject:any };

  @Output() messageEvent = new EventEmitter<any>();
  Redirect = { rid: "", rtype: 0, stepid: 0 };
  submitted = false;
  showSuccess = false;
  IsFormSubmit = false;
  IsFogotPassword = false;
  verifyOtpForm: FormGroup;
  VerifiedOtpRef: string;
  returnUrl: string;
  timeLeft: number = 59;
  interval;
  request = { LanguageTypeId: 1, RequestId: "", RequestType: 0, CountryCodes_Fk_Id: 0, MobileNo: "", OneTimePassword: "", IsFogotPassword: false, CountryDialCode:null };
  ErrorMessage = "";
  SuccessMessage = "";
  constructor(
    public bsModalRef: BsModalRef,

    private accountService: AccountService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private localize: LocalizeRouterService) {
    super(translate);
    this.verifyOtpForm = this.verifyOtpFormValidation();
   

  }

  ngOnInit() {
    this.OtpTimer();
    this.verifyOtp();
  }

  OtpTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }


  get f() { return this.verifyOtpForm.controls; }

  verifyOtp() {
   
    this.submitted = true;
    this.request = this.verifyOtpForm.value;
    this.request.RequestId = this.child.rid;
    this.request.RequestType = this.child.IsforgotPass ? ApiRequestTypesEnum.ForgotPassword : this.child.rtype;
    this.request.CountryDialCode = this.child.CountryCode;
    this.request.CountryCodes_Fk_Id = this.child.CountryCodes_Fk_Id;
    this.request.MobileNo = this.child.IsforgotPass ? this.child.EmailMobileNo : this.child.MobileNo;
    this.request.LanguageTypeId = this.appLangId;
    if (this.verifyOtpForm.invalid) {
      return;
    }
    else {
      this.IsFormSubmit = true;

      var result = this.accountService.verifyOtpWeb(this.request);
      result.subscribe(resp => {
        if (resp.ResponseStatus == 1) {
          const obj = {
            rid: this.request.RequestId,
            VerifiedOtpRef: resp.Response.VerifiedOtpRef,
            PolicyId: this.child.PolicyId,
            authActiveStepIndex: this.child.IsforgotPass == true ? 6 : 4,
            rtype: this.child.rtype,
            travelTo: this.child.travelTo
          }
          this.messageEvent.emit(obj);
          /** Only close the popup window if request type is not direct..*/
          //if (this.child.rtype !== ApiRequestTypesEnum.Direct && !this.child.IsforgotPass) {
          /** Hide modal popup... */
          //this.bsModalRef.hide();


          /** Reload user information... */
          // LayoutHeaderComponent.updateUserStatus.next(true);

          // if (this.child.rtype == ApiRequestTypesEnum.Travel) {
          //   /** Route to next step... */
          //   let translatedPath: any = this.localize.translateRoute('/travel/buy/travelstep2');
          //   this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid,this.child.CountryCodes_Fk_Id,this.child.MobileNo,resp.Response.VerifiedOtpRef,this.child.travelTo]);

          // }
          // else if (this.child.rtype == ApiRequestTypesEnum.Domestic) {
          //   /** Route to next step... */
          //   let translatedPath: any = this.localize.translateRoute('/domestic/buy/domesticstep2');
          //   this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid,this.child.CountryCodes_Fk_Id,this.child.MobileNo,resp.Response.VerifiedOtpRef,0]);

          // }
          // else if (this.child.rtype == ApiRequestTypesEnum.House) {
          //   /** Route to next step... */
          //   let translatedPath: any = this.localize.translateRoute('/homeinc/buy/homestep2');
          //   this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid,this.child.CountryCodes_Fk_Id,this.child.MobileNo,resp.Response.VerifiedOtpRef,0]);
          // }
          // else if (this.child.rtype == ApiRequestTypesEnum.Car) {
          //   /** Route to next step... */
          //   let translatedPath: any = this.localize.translateRoute('/car/buy/carstep2');
          //   this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid,this.child.CountryCodes_Fk_Id,this.child.MobileNo,resp.Response.VerifiedOtpRef,0]);

          // } 
          // else if (this.child.rtype == ApiRequestTypesEnum.MarineHull) {
          //   /** Route to next step... */
          //   let translatedPath: any = this.localize.translateRoute('/marine/buy/marinehullstep2');
          //   this.router.navigate([translatedPath, this.child.PolicyId, this.child.rid,this.child.CountryCodes_Fk_Id,this.child.MobileNo,resp.Response.VerifiedOtpRef,0]);

          // } 
          // else if (this.returnUrl !== "" && this.returnUrl !== "/"  && this.returnUrl !== "//" && this.returnUrl !== "\\"  ) {
          //   this.router.navigateByUrl(this.returnUrl);
          // }
          // else{
          //   let translatedPath: any = this.localize.translateRoute('/account/dashboard');
          //   this.router.navigate([translatedPath]);
          // }

          //}

          //this.router.navigate([ 'travel/buy/travelstep3', this.request.RequestId]);

        }
        else {
          this.toastr.error(resp.ResponseMessage);
        }
        this.submitted = false;
        this.IsFormSubmit = false;
      });
    }
  }

  verifyOtpFormValidation() {
    return this.formBuilder.group({
      OneTimePassword: ['123456', Validators.required]
    });
  }

  ResendOtp() {
    this.request.RequestId = this.child.rid;
    this.request.RequestType = this.child.rtype;
    this.request.CountryCodes_Fk_Id = this.child.CountryCodes_Fk_Id;
    this.request.MobileNo = this.child.IsforgotPass ? this.child.EmailMobileNo : this.child.MobileNo;
    this.IsFogotPassword = this.child.IsforgotPass;
    this.request.IsFogotPassword = this.child.IsforgotPass;
    const result = this.accountService.resendOtp(this.request);
    result.subscribe(resp => {
      if (resp.ResponseStatus === 1) {
        // this.SuccessMessage = "OTP sent on your number";
        // setTimeout(() => {
        //   this.SuccessMessage = "";
        // }, 4000);
        // this.router.navigate([ 'travel/buy/travelstep3', this.request.RequestId]);
        this.timeLeft = 59;
        this.OtpTimer();
      } else {
        // this.submitted = false;
        // this.IsFormSubmit = false;
        this.ErrorMessage = resp.ResponseMessage;
        setTimeout(() => {
          this.ErrorMessage = '';
        }, 4000);

      }
      this.submitted = false;
      this.IsFormSubmit = false;
    });
  }

  ChangeMobileNumber() {

    const obj = {
      VerifiedOtpRef: "",
      PolicyId: this.child.PolicyId,
      CountryCode: this.child.CountryCode,
      selectedISO:this.child.MobileObject.countryCode,
      MobileNo: this.child.MobileNo,
      authActiveStepIndex: this.child.IsforgotPass == true ? 5 : 1,
      IsforgotPass:this.child.IsforgotPass

    }
    this.messageEvent.emit(obj);
  }
  ngAfterViewInit() {
    setTimeout(() => {

      document.getElementById("inputotp").focus();

    }, 100);
  }

}
