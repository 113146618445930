import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class MarineHullService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    getPurposeList(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetPurposeList", requestAuthentication);
    }

    /** Get marine hull limit plans **/
    getMarineHullLimitPlans(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullLimitPlans", requestAuthentication);
    }

    /** GET addone from the server */
    getAddOnsList(addonRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetAddOnsList", addonRequest);
    }
    /** GET policy cost from the server */
    getMarineHullCost(policyplanRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullCost", policyplanRequest);
    }


    /** GET Policy step2 from the server */
    PolicyStep2(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/SaveMarineHullInsurancePolicyStep2", policyplanStep2Request);
    }

    /** Create Pre Policy  from the server */
    CreatePreCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/CreatePreCustomerPolicy", policyplanStep2Request);
    }


    /** GET Policy  from the server */
    PolicyStep3(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullInsurancePolicyDetails", policyplanStep2Request);
    }

    /** Get Marine Hull policy step 1 */
    GetMarineHullInsurancePolicyStep1(marineHullInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullInsurancePolicyStep1", marineHullInsurancePolicyRequest);
    }

    /** Save Marine Hull policy step 1 */
    SaveMarineHullPolicyStep1(marineHullInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/SaveMarineHullInsurancePolicyStep1", marineHullInsurancePolicyRequest);
    }

    /** Get Marine Hull policy step 2 */
    GetMarineHullInsurancePolicyStep2(marineHullInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullInsurancePolicyStep2", marineHullInsurancePolicyRequest);
    }

    /** Get Marine Hull policy step 3 */
    SaveMarineHullInsurancePolicyStep3(marineHullInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/SaveMarineHullInsurancePolicyStep3", marineHullInsurancePolicyRequest);
    }

    /** Get Marine Hull policy step 3 */
    GetMarineHullInsurancePolicyStep3(marineHullInsurancePolicyRequest): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullInsurancePolicyStep3", marineHullInsurancePolicyRequest);
    }

    getPolicyPlanBenefitsDetails(requestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetPolicyPlanBenefitsDetails", requestAuthentication);
    }

    /** Get policyList  from the server */
    GeMyPolicyList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMyPolicies", RequestAuthentication);
    }
    /** Get policyList  from the server */
    GetMyPoliciesStatusCount(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMyPoliciesStatusCount", RequestAuthentication);
    }

    /** Get PolicyDetails  from the server */
    getPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMyPoliciesDetails", RequestAuthentication);
    }
    /** Get PolicyHolder  from the server */
    GetPolicyHolder(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetPolicyHolder", RequestAuthentication);
    }

    /** Get PolicyAssuredPerson  from the server */
    GetPolicyAssuredPerson(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetPolicyAssuredPerson", RequestAuthentication);
    }

    /**  update PolicyDetails  from the server */
    updatePolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/SetPolicyHolder", RequestAuthentication);
    }
    /** update PolicyAssuredPerson  from the server */
    updatePolicyAssuredPerson(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/SetPolicyAssuredPerson", RequestAuthentication);
    }

    /** Get Required Attachment from the server */
    GetMarineHullRequiredDocumentslist(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullRequiredDocumentslist", RequestAuthentication);
    }

    /** Update Attachment */
    UpdateAttachment(request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/UpdateMarineHullDocumentApproved", request);
    }

    /** Get Insurance Coverage from the server */
    GetMarineHullPolicyInsuranceCoverage(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullPolicyInsuranceCoverage", RequestAuthentication);
    }




    GetPolicyReceipt(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GetMarineHullInsuranceReceipt", RequestAuthentication);
    }

    MobileGetMarineHullInsuranceReceipt(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/MobileGetMarineHullInsuranceReceipt", RequestAuthentication);
    }

    GetReceiptOnMail(RequestAuthentication): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GetReceiptOnMail", RequestAuthentication);
    }

    /** Get Marine Hull Policy Details  from the server */
    GetMarineHullPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullPoliciesDetails", RequestAuthentication);
    }

    /** Get Marine Hull Policy Complete Status  from the server */
    GetMarineHullPolicyCompleteStatus(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GetMarineHullPolicyCompleteStatus", request);
    }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }

    /** Get Marine Hull Policy owner details  from the server */
    GetMarineHullPolicyOwnerDetails(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GetMarineHullPolicyOwnerDetails", request);
    }

    /** Save Marine Hull Policy owner details  from the server */
    SaveMarineHullPolicyOwnerDetails(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/SaveMarineHullPolicyOwnerDetails", request);
    }


    /** Get Marine Hull Policy assured Vessel details  from the server */
    GetMarineHullPolicyAssuredVessel(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GetMarineHullAssuredVesselInfo", request);
    }

    /** Save Marine Hull Policy assured Vessel details details  from the server */
    SaveMarineHullPolicyAssuredVessel(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/SaveMarineHullAssuredVesselInfo", request);
    }

    /** Delete policy required document **/
    DeleteMarineHullPolicyDocument(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/DeleteMarineHullPolicyDocument", request);
    }

    SaveMarineHullRequiredDocuments(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/UploadMarineHullRequiredDocuments", formData);
    }

    /** Get PolicyFee and Payment  from the server */
    GetMarineHullPolicyFeeANDPayment(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullPolicyFeeANDPayment", RequestAuthentication);
    }

    GenerateIssueCertificate(request): Observable<any> {            
        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/GenerateCertificate", request);
    }

    /** Get Required Attachment from the server */
    GetMarineHullLeadRequiredDocumentslist(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/GetMarineHullLeadRequiredDocumentslist", RequestAuthentication);
    }

    UploadMarineHullDocuments(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiMarineHullInsurance + "/UploadMarineHullDocuments", formData);
    }

    /** Create Pre Policy  from the server */
    CreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/CreateCustomerPolicy", policyplanStep2Request);
    }

     /** Create Pre Policy  from the server */
     MobileCreateCustomerPolicy(policyplanStep2Request): Observable<any> {
        return this.apiService.post(config.apiMarineHullInsurance + "/MobileCreateCustomerPolicy", policyplanStep2Request);
    }
}
