<!-- <div>An OTP has been sent on {{child.CountryCode}}-{{child.MobileNo}} <a href="javascript:void(0);">Change?</a></div> -->


<form action="javascript:void(0);" [formGroup]="verifyOtpForm" id="verify-form" class="" (ngSubmit)="verifyOtp()"
  autocomplete="off">
  <div class="modal-body">
    <div class="form-group">
      <div class="row">
        <div class="col-md-12 pr0md" style="">
          <p>
            <span *ngIf="child.IsforgotPass">{{'AtextmessagewithdigitverificationOTPsenttoyourmobileno' | translate}}
            </span>
            <span
              *ngIf="child.IsforgotPass==false">{{'AtextmessagewithdigitverificationOTPsenttoyourmobileno' | translate}}
            </span>
            <!-- {{child.CountryCode}} -->
            <strong *ngIf="child.IsforgotPass==false">{{child.MobileNo}} </strong>
            <strong *ngIf="child.IsforgotPass">{{child.EmailMobileNo}} </strong>
            <a style="color: #006FFF;" *ngIf="appLangId == 1" href="javascript:void(0);" (click)="ChangeMobileNumber()">
              {{'Change' | translate}}?</a>
              <a style="color: #006FFF;" *ngIf="appLangId == 2" href="javascript:void(0);" (click)="ChangeMobileNumber()">
               {{'Change' | translate}}؟</a>

          </p>
          <!-- <label class="otp-sent">OTP sent on your number...</label> -->
        </div>
      </div>
    </div>
    <!-- <div class="form-group">
                <div class="row">
                  <div class="col-md-12 pr0md" style="margin-bottom: 10px;">                    
                    <label></label>
                  </div>
                </div>
              </div> -->
    <div class="form-group">
      <div class="row">
        <div class="col-md-12">
          <label>{{'Enter_the_OTP' | translate}}</label>
          <input type="text" id="inputotp" name="cphone" class="form-control" placeholder="" maxlength="6"
            formControlName="OneTimePassword" [ngClass]="{ 'is-invalid': submitted && f.OneTimePassword.errors }"
            autocomplete="off" autofocus>
        </div>
      </div>
    </div>
    <!-- <div class="response" id="cresponse" style="text-align: left; margin: 0;    margin-bottom: 10px;color:red;">{{ErrorMessage}}</div>
          <div class="response" id="cresponse" style="text-align: left; margin: 0;    margin-bottom: 10px;color:#098b41;">{{SuccessMessage}}</div> -->
  </div>
  <div class="clearfix mb10"></div>
  <div class="modal-footer" style="text-align: left;">
    <div style="display:flex;justify-content: space-between;position: relative;">
      <div>
        <div class="response" id="cresponse"
          style="text-align: left;position: absolute;top: -30px;bottom: 0;line-height: 30px;left: 0;right: 0px;height: 28px; color:red;">
          {{ErrorMessage}}</div>
        <div class="response" id="cresponse"
          style="text-align: left;position: absolute;top: -30px;bottom: 0;line-height: 30px;left: 0;right: 0px;height: 28px;color:#098b41;">
          {{SuccessMessage}}</div>
      </div>
      <button type="submit" class="btn btn-primary b1 detailsbtn" id="btn1" *ngIf="IsFormSubmit == false"
        style="background-color: #098b41;">{{'Verify' | translate}} </button>

    </div>

    <p>
      <a href="javascript:void(0)" class="btn btn-primary b1 detailsbtn processing" *ngIf="IsFormSubmit == true"
        style="background-color: #098b41;">{{'Continue' | translate}}...</a>
    </p>

    <div *ngIf="timeLeft > 0">
      <p style="padding: 9px;width: 130px;margin: 10px auto 10px auto;font-size: 18px;letter-spacing: 0.8px;font-weight: 600;"
        class="text-center">00 : {{timeLeft}}</p>
    </div>

    <div *ngIf="timeLeft > 0">
      <p style="margin-top: 18px; opacity: 0.5;">
        {{'YouWillreceiveanOTPifyoudidnotReceivetheOTP' | translate}} <a style="color: #006FFF;cursor: default;">
          {{'Click_here' | translate}}</a>
        {{'to_Send_Again' | translate}}</p>
    </div>
    <div *ngIf="timeLeft == 0">
      <p style="margin-top: 18px;">{{'YouWillreceiveanOTPifyoudidnotReceivetheOTP' | translate}} <a
          href="javascript:void(0);" style="color: #006FFF;" (click)="ResendOtp()"> {{'Click_here' | translate}}</a>
        {{'to_Send_Again' | translate}}</p>

    </div>


    <!-- background: #48AE06;
      background: -webkit-linear-gradient(right, #48AE06, #098B42);
      background: -moz-linear-gradient(right, #48AE06, #098B42);
      background: linear-gradient(to left, #48AE06, #098B42); -->

  </div>

</form>