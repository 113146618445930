export class MarineAddons {
    public Id: number;
    public AddOnName: String;
    public CostType: String;
    public Cost: number
}

export class PolicyPurpose {
    public PurposeName:string;
}

export class TPLLimitPlan {
    public Id: number;
    public PlanName:string;
    public Cost: number;
}

export class MarineHullInsurancePolicyRequest{
    LanguageTypeId: number;
    Customer_Id: number;
    RequestedId: string;
    SessionId: string;
    PolicyGroup_Fk_Id: number;
    PolicyPlanType_Fk_Id: number;
    InsuranceType_Fk_Id: number;
}

export class MarinePolicyRequest{
    Purpose_Fk_Id: number;
    VesselName: string;
    VesselRegistrationNo: string;
    YearBuilt: number;
    WhereBuilt: number;
    Length: number;
}







///////////////////////////////////////////////////////



export class MarinePolicyStep2 {
    LanguageTypeId: number;
    public PolicyId: number;
    public RequestedId: String;
    public PolicyStartDate: string;
    public PolicyExpiryDate: string;
    public TPLLimitPlans_Fk_Id: number;
    public adOns: MarineAdOns[];
}

// export class PolicyStep4 {
//     LanguageTypeId: number;
//     public RequestedId: String;
//     public PaymenType : number;
   
// }
export class MarineAdOns {
    public Id: number;

}

export class MarineHullPolicyDetailsStep3{    
    public FirstName: string;
    public MiddleName :string;
    public LastName :string;
    public MobileNumber: string;
    public EmailAddress: string;
    public CountryCodes_Fk_Id :number;
    public Address : string;        
    public CivilId: string;
    public DateOfBirth :string;
    public City:string;
    public Occupation:string;
    public BuildMaterial_Fk_Id: number;
    public BuildingAddress: string;
    public Latitude: string;
    public Longitude: string;
    public Measurement_Fk_Id: number;
    public Length: number;
    public Breadth: string;
    public Width: string;
    public EngineMake_Fk_Id: number;
    public NumberOfEngine_Fk_Id: number;
    public Power_Fk_Id: number;
    public GrossRegisteredTonnage: number;
    public DeadWeightTonnage: number;
    public HorsePower :number;
}

export class MarineHullPolicyStep3Request{
    public LanguageTypeId: number;
    public RequestedId: string;    
    public FirstName: string;
    public MiddleName :string;
    public LastName :string;
    public MobileNumber: string;
    public EmailAddress: string;
    public CountryCodes_Fk_Id :number;
    public Address : string;        
    public CivilId: string;
    public DateOfBirth :string;
    public City:string;
    public Occupation:string;
    public BuildMaterial_Fk_Id: number;
    public BuildingAddress: string;
    public Latitude: string;
    public Longitude: string;
    public Measurement_Fk_Id: number;
    public Length: number;
    public Breadth: string;
    public Width: string;
    public EngineMake_Fk_Id: number;
    public NumberOfEngine_Fk_Id: number;
    public Power_Fk_Id: number;
    public GrossRegisteredTonnage: number;
    public DeadWeightTonnage: number;
    public HorsePower :number;
}

export class MarinePolicyStep3 {
    public Id: number;
    public PlanType: string;
    public PurchaseDate: Date;
    public PolicyStartDate: Date;
    public PolicyEndDate: Date;
    public Name: string;
    public EmailId: string;
    public MobileNo: string;
    public CivilId: string;
    public NetPrice: number;
    public SupervisionFees:number;
    public IssueFees:number;
    public ExtraFees:number;
    public IsCouponAvailable:number; 
    public CouponCode: string;
    public CouponDiscount:number;
    public PolicyPremium: number;
    public TotalAddOnsPrice: number; 
    public PurposeName: string;
    public YearBuilt : number;
    public Length : number;
    public Measurement: number;
    public PolicyAddOnList: MarineHullPolicyAddOnDetails[]=[];
    public PaymentModeList: MarinePaymentMode[]
}

export class MarinePaymentMode {
        public PaymentModeId: number;
    public PaymentModeType: string;

}
export class MarineHullPolicyAddOnDetails {

    public AddOnName: string;
}

export class MarinePolicysuccess
{ TransactionNo:string;
    PolicyNo :string;
    Name:string;
    MobileNo :string;
    EmailId:string;
    NetPrice:number;
    PolicyStatus :string;
    PaymentMode:string;
}

export class PolicyInsuranceCoverage
{
    public  Id :number;
    public  PolicyNo: string;
    public  PolicyName :string;
    public  Date :Date
    public  TransactionNo:string;
    public  RefrenceNo:string;
    public  PaymentType :string;
    public  OnlyPolicyPrice:number;
    public  NetPrice:number;
    public TotalAddonPrce :number;
    public SupervisionFees: number;
    public IssueFees: number;
    public ExtraFees: number;
    public IssueCertificate: string;
    public IssueCertificateUrl: string;
    public  AddOn:MarineAddons[];

}

// export class PolicyHolder{
//         LanguageTypeId: number;
//         public Id:number;
//         public CustomerPolicy_Fk_Id:number
//         public  FirstName: string;
//         public  MiddleName :string;
//         public  LastName :string;
//         public  MaritalStatus:boolean;
//         public  Gender :number;
//         public  PhoneNumber:string;
//         public  MobileNumber: string;
//         public  EmailAddress: string;
//         public  CountryCodes_Fk_Id :number;
//         public  Address : string;
//         public  PassportNumber : string;        
//         public  CivilId: string;
//         public  DOB :string;
//         public IsPolicyHolder:boolean;
// }


// export class PolicyISuranceCoverage
// {
//     public  Id :number;
//     public  PolicyNo: string;
//     public  PolicyName :string;
//     public  Date :Date
//     public  TransactionNo:string;
//     public  RefrenceNo:string;
//     public  PaymentType :string;
//     public  OnlyPolicyPrice:number;
//     public  NetPrice:number;
//     public TotalAddonPrce :number;
//     public SupervisionFees: number;
//     public IssueFees: number;
//     public ExtraFees: number;
//     public  AddOn:MarineAddons[];

// }


// export class Policysuccess
// { TransactionNo:string;
//     PolicyNo :string;
//     Name:string;
//     MobileNo :string;
//     EmailId:string;
//     NetPrice:number;
//     PolicyStatus :string;
//     PaymentMode:string;
// }



// export class SimpleStepsDetails
// {
//     Id: number;
//     Content: string;
//     Image: string;
// }


export class SaveMarineHullPolicyOwner{
    public LanguageTypeId: number;
    public Flag: string;
    public Id:number;
    public CustomerPolicy_Fk_Id:number
    public  CivilId: string;
    public  OwnerFirstName :string;
    public  OwnerMiddleName :string;
    public  OwnerLastName:string; 
    public  MobileNumber: string;
    public  EmailAddress: string;
    public  CountryCode_Fk_Id :number;
    public  Address : string;
    public  Occupation : string;   
}

export class SaveMarineHullPolicyAssuredVessel{
    public LanguageTypeId: number;
    public Flag: string;
    public Id:number;
    public CustomerPolicy_Fk_Id:number
    public  VesselName: string;
    public  VesselRegistrationNo :string;
    public PolicyPurpose_Fk_Id:number
    public YearBuilt:number
    public WhereBuilt:number
    public BuildMaterial_Fk_Id:number
    public  Length :string;
    public  Breadth:string; 
    public  Width: string;
    public  EngineMake_Fk_Id: number;
    public  Power_Fk_Id :number;
    public  GrossRegisteredTonnage : number;
    public  DeadWeightTonnage : number;
    public Measurement_Fk_Id:number   
}