import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';
//import { Observable } from 'rxjs';
//import { BannerCollection } from '../models/home/banners.model';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';

// const routes = {
//     projects: '/projects',
//     project: (id: number) =>  `/projects/${id}`
// };

@Injectable({
    providedIn: 'root'
})
export class QuotationService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    GetDropDownList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    }
    /** Get customer pre Policy list from the server*/
    GeMyPolicyRequestList(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiPolicyRequest + "/GetPreCustomerPolicyList", RequestAuthentication);
    }

    /** Get Pre Policy Car Holder Details  from the server */
    getCustomerCarInformation(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiPolicyRequest + "/GetCustomerCarInformation", RequestAuthentication);
    }

    /** Get Pre Policy Details  from the server */
    getPreCustomerPolicyDetails(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiPolicyRequest + "/GetPreCustomerPolicyDetails", RequestAuthentication);
    }

    /** Get Pre Policy Car Holder Details  from the server */
    getPrePolicyCustomerInformation(RequestAuthentication): Observable<any> {
        return this.apiService.post(config.apiPolicyRequest + "/GetCustomerPersonalInformationList", RequestAuthentication);
    }  

    /** Get Required Attachment from the server */
    GetPreCustomerPolicyCarDocument(RequestAuthentication): Observable<any> {             
        return this.apiService.post(config.apiPolicyRequest + "/GetCarPolicyAttachmentInformation", RequestAuthentication);
    }

    /** Update personal information */
    UpdatePersonalInformation(Request): Observable<any> {             
        return this.apiService.post(config.apiPolicyRequest + "/UpdatePersonalInformation", Request);
    }

    /** Update car information */
    UpdateCarInformation(Request): Observable<any> {             
        return this.apiService.post(config.apiPolicyRequest + "/UpdateCarInformation", Request);
    }

    getQuotationRequestCompleteStatus(request): Observable<any> {

        return this.apiService.post(config.apiPolicyRequest + "/GetQuotationRequestCompleteStatus", request);
    }

    changeQuotationRequestStatus(RequestAuthentication):Observable<any> {
        return this.apiService.post(config.apiPolicyRequest+"/ChangeQuotationRequestStatus",RequestAuthentication);
    } 
    /** Delete policy required document **/
    DeletePreCarPolicyDocument(request): Observable<any> {

        return this.apiService.PostMultiPart(config.apiPolicyRequest + "/DeletePreCarPolicyDocument", request);
    }

    UpdatePreCarPolicyRequiredDocument(formData: FormData): Observable<any> {

        return this.apiService.PostMultiPart(config.apiPolicyRequest + "/UpdatePreCarPolicyRequiredDocument", formData);
    }
}
