import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';


import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';



@Injectable({
    providedIn: 'root'
})
export class CarClaimService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

    /** Get DropDownList  from the server */
    // GetDropDownList(RequestAuthentication): Observable<any> {
    //     return this.apiService.post(config.apiTravel + "/GetDropDownList", RequestAuthentication);
    // }
    getCarClaimList(request): Observable<any> {
        return this.apiService.post(config.apiCarClaim + "/GetCarClaimList", request);
    }
    GetCarClaimRequiredDocumentList(request): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarClaim + "/GetCarClaimRequiredAttachmentList", request);
    }
    SaveCarClaimAttachmentInformation(formData: FormData): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarClaim + "/SaveCarClaimAttachmentInfo", formData);
    }

    SaveClaimUploadDocumentDescription(claimRequest): Observable<any> {
        return this.apiService.post(config.apiCarClaim + "/SaveCarClaimInformation", claimRequest);
    }
    GetCarClaimDetails(RequestAuthentication): Observable<any> {
        return this.apiService.PostMultiPart(config.apiCarClaim + "/GetCarClaimDetails", RequestAuthentication);
    }

    RemoveCarClaimAttachment(request):Observable<any>{
        return this.apiService.PostMultiPart(config.apiCarClaim + "/RemoveCarClaimAttachment", request);
    }
}
