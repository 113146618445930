import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HomeService, AccountService, ApiRequestTypesEnum, BaseComponent, CurrentLanguage } from '@app/core';
import { LocaleService } from '../../services/locale.service';
import { MenuCollection } from '../../../core/models/shared/layout.model';
import { RequestAuthentication } from '../../../core/models/request-authentication.model';
import { environment as env } from '@env/environment';
import { CurrentAccessToken } from '../../../core/classes';
import { Subject, interval } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MobileModalComponent } from '@app/modules/modal/pages/verify-mobile/mobile-modal.component';
import { Router,ActivatedRoute } from '@angular/router';
import { Event,  NavigationStart, NavigationCancel, NavigationEnd, NavigationError, NoPreloading, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-layout-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.css']
})
export class LayoutHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  public static updateUserStatus: Subject<boolean> = new Subject();
  public static updateNotificationCount: Subject<boolean> = new Subject();
  public static showMenuHeader: Subject<boolean> = new Subject();
  notificationRequest = { LanguageTypeId: 1, IsSeen: false, CurrentPage: 1, RecordPerPage: 1000 };
  stop$: boolean = false;
  modalRef: BsModalRef;
  imgversion:string = env.imgversion;
  IsUserLoggedIn: boolean = false;
  UserName: string = "Guest User";
  ProfileImage: string = "";
  ProfileImageUrl: string = env.profileImageUrl;
  MenuSelection: string = "home";
  MenuCollection: MenuCollection[];
  requestAuthentication = { LanguageTypeId: 1 };
  countTotalNotification: number = 0;
  CurrentLang: string = this.translate.currentLang;
  isArabic: boolean = false;
  oppositeLang: string = (this.translate.currentLang === env.lang.ArText ? 'Eng' : 'عربى');
  ShowMenuHeader:boolean=true;
  // showMenus:boolean=false;
  IsShowChangeLanguge:boolean=false;
  corporateUrl:string = env.corporateUrl;

  constructor(
    private router: Router,
    private modalService: BsModalService,
    private currentAccessToken: CurrentAccessToken,
    private headerService: HomeService,
    private accountService: AccountService,
    private localeId: LocaleService, private localizeService: LocalizeRouterService, private translate: TranslateService
    , private activerout: ActivatedRoute
   
    
    
    
    ) {
    /** Call base class constructor..*/
    super();


    this.isArabic = (this.translate.currentLang === env.lang.ArText);
    this.corporateUrl = this.corporateUrl + this.translate.currentLang;
    //oppositeLang:(this.translate.currentLang === env.lang.ArText ? env.lang.EnText : env.lang.ArText);
    // oppositeLang:(this.translate.currentLang === env.lang.ArText ? env.lang.EnText : 'عربى');
    oppositeLang: (this.translate.currentLang === env.lang.ArText ? 'Eng' : 'عربى');

    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url.indexOf('/home') >=0 && routerEvent.url.indexOf('/homeinc') < 0 || routerEvent.url=='/') {
            
             this.IsShowChangeLanguge = true;
        } else {
          this.IsShowChangeLanguge = false;
        }

        //routerEvent.url.indexOf('/homeinc')===-1

      }

      // if (routerEvent instanceof NavigationEnd) { 
      //   if (routerEvent.url.indexOf(this.translate.currentLang + '/calls/new') === 0 && routerEvent.url.indexOf(this.translate.currentLang + '/cs/') == 0) { 
       
      //   }
      //   } 
      //   else if (routerEvent instanceof NavigationCancel || routerEvent instanceof NavigationError) {
        
      //   }
        });

  }

  

  ngOnInit(): void {

    if (!this.currentAccessToken.isTokenExpired()) {
      const currentLoggedInUser = this.currentAccessToken.loggedInUserInfo;

      this.UserName = currentLoggedInUser.UserName;
      this.ProfileImage = currentLoggedInUser.ProfileImage;
      if (this.UserName === '') {
        this.UserName = 'Guest User';
      }
      }

    this.getUnSeenNotificationCount();
    this.CurrentLang = this.translate.currentLang;
    this.requestAuthentication.LanguageTypeId = this.localeId.getCurrentLocale;
    /** Load user info first time. */
    this.reloadUserInfo();
    // emit value in sequence every 1 second
    const source = interval(1000);
    const subscribe = source.subscribe(val => this.reloadUserInfo());

    window.addEventListener('storage', function(event) {
      // console.log(event.key);
      if (event.key === env.localStorageKeyName) {
        // LayoutHeaderComponent.reloadUserInfo();
        location.reload();
          // ..
      }
    }, false);



      /** Reload user information... */
      const subsLayoutHeaderComp = LayoutHeaderComponent.updateUserStatus
      .subscribe(res => {
        this.reloadUserInfo();  
          this.getUnSeenNotificationCount();
     
      });

      /** Reload notification count... */
      const subsLayoutHeaderNotification = LayoutHeaderComponent.updateNotificationCount
      .subscribe(res => {      
        this.getUnSeenNotificationCount();
      });

      /** Show Hide Menu Header... */
      const showHideLayoutMenuHeader = LayoutHeaderComponent.showMenuHeader
      .subscribe(res => {    
      this.ShowMenuHeader=res;
      });
  

    /** Add to subscription list for future unsubscription on destroy..*/
    this.subscriptions.push(subsLayoutHeaderComp);
    this.subscriptions.push(subsLayoutHeaderNotification);
    this.subscriptions.push(showHideLayoutMenuHeader);
    
  }



  // switchMenu(show:boolean){
  //   this.showMenus=show;
  // }

  switchLanguage(language: string) {
    
    language = language === 'عربى' ? env.lang.ArText : (language === 'Eng' ? env.lang.EnText : env.lang.ArText);
    this.localizeService.changeLanguage(language);
  }

  /** Set user name on home page*/
  reloadUserInfo(): void {
    this.setMenuSelection();
    if (!this.currentAccessToken.isTokenExpired()) {

      let currentLoggedInUser = this.currentAccessToken.loggedInUserInfo;
      this.IsUserLoggedIn = true;
      this.UserName = currentLoggedInUser.UserName;
      this.ProfileImage = currentLoggedInUser.ProfileImage;
      if (this.UserName === "")
        this.UserName = "Guest User";

        // this.getUnSeenNotificationCount();
    }
    else {
      this._internalLogout();
    }
  }

  /** Get menu list for header **/
  getMenuList(requestAuthentication: RequestAuthentication) {

    const subsMenuList = this.headerService.getMenuList(this.requestAuthentication)
      .subscribe(resp => {
        this.MenuCollection = resp.ResponseCollection;
      });

    /** Add to subscription list for future unsubscription on destroy..*/
    this.subscriptions.push(subsMenuList);
  }

  getUnSeenNotificationCount() {
    this.countTotalNotification=0;
    if (!this.currentAccessToken.isTokenExpired()) {  
    this.notificationRequest.IsSeen = false;
    this.notificationRequest.LanguageTypeId = this.localeId.getCurrentLocale;
    var result = this.accountService.getNotificationList(this.notificationRequest);
    result      
      .subscribe(resp => {       
        this.countTotalNotification = resp.ResponseCollection.length;
      });
    }
  }


  private _internalLogout(): void {
    this.IsUserLoggedIn = false;
    this.UserName = "Guest User";
    this.currentAccessToken.logout();
  }

  public logout(): void {
    this._internalLogout();
    this.countTotalNotification = 0;
    var lang=this.translate.currentLang; 
    this.router.navigate([ '/'+lang+'/home']);
  }






  public openMobileNumberModal(rid: string) {    
    /** Set request Id...*/
    
    var initialState = { rid: rid, rtype: ApiRequestTypesEnum.Direct, travelTo: -1 };
    /** Backdrop for background transparent layer...*/
    this.modalService.config.backdrop = true;
    /** False to stop popup close action on Esc press from keyboard...*/
    this.modalService.config.keyboard = false;
    /** True to prevent popup modal close on anywhere click from screen...*/
    this.modalService.config.ignoreBackdropClick = true;

    if(this.translate.currentLang=='en')
    {
    this.modalService.config.class = "w-500";
    }
    else
    {
      this.modalService.config.class = "w-500 loginarb";

    }


    /** Open popup...*/
    this.modalRef = this.modalService.show(MobileModalComponent, { initialState });
    this.modalRef.content.closeBtnName = 'Close';
  }

  public closeMobilePopupMoal(): void {
    this.modalRef.hide();
  }

  setMenuSelection(){
    /** Menu selection **/
    
      if(!this.router.url.match("/home")) {
        this.MenuSelection = '';
      }
  }

  RemoveInForMobileView(){
    document.getElementById("ins-body").classList.remove("modal-open");
    document.getElementById("navMenu").classList.remove("in");
    // document.getElementById("gh-svg-icons").classList.remove("gh-show-nav");
  }
}
