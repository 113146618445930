import { Component, OnInit } from '@angular/core';
import { HomeService, CurrentLanguage, ThemeService } from '@app/core';
import { environment as env } from '@env/environment';
import {SocialLink } from '../../../core/models/shared/layout.model';
import { TranslateService } from '@ngx-translate/core';
import { ContactUsRequest, DropdownRequest, ServiceResponse, ContactTypeResult, CountryCodeResult } from '@app/core/models/home/contact.model';
import { AboutCollection, AboutRequest } from '@app/core/models/home/about.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {RequestAuthentication} from '@app/core/models/request-authentication.model';
import { spaceValidator } from '@app/modules/domestic/pages/NoSpaceValidator';
import { heLocale } from 'ngx-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Directive, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class LayoutFooterComponent extends CurrentLanguage implements OnInit {
  imgversion:string = env.imgversion;
  submitted = false;
  showSuccess= false;
  IsFormSubmit = false;
  ServiceResponse: ServiceResponse;
  //dropdownRequest = {RequestAuthentication:{LanguageTypeId:1}, Flag:""};
  dropdownRequest = {LanguageTypeId:1, Flag:""};
  requestAuthentication= {LanguageTypeId:1};
  ContactUsRequest: ContactUsRequest;
  ContactTypeResult: ContactTypeResult[];
  CountryCodeResult: CountryCodeResult[];
  contactForm: FormGroup;
  socialMediaLink:SocialLink;
  MenuSelection: string = "home";
  emailPattern =/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
  phonePattern = "^((\\+91-?)|0)?[0-9]{8,12}$";
  namePattern=/^[A-Za-z\u0600-\u06FF\s]+$/;
  urlPattern = /^(http|https):\/\/\S+/;
  // Its not validate Ip Addrsss it validate  Only URL 
  // expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
   // Its Validate URL and IP Address Both
  expression=/((?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+%,;=.]+)/igm;
  regex = new RegExp(this.expression);
  preview = '';
  aboutRequest = {LanguageTypeId:1,TitleKey:"", RouteKey:""};
  AboutCollection: AboutCollection[];
  ContactData: string;
  LocationData: string;
  VisitUsData: string;
   URLmessage:string;
   showmessage:string;
   messege=false;
   selectedform = 1;
  constructor(private footerService: HomeService, private formBuilder: FormBuilder, private translate: TranslateService ,private el: ElementRef) {
    super(translate);
  }

  ngOnInit() {
     
  //   this.contactForm = this.formBuilder.group({
  //     Name: ['', [Validators.required,spaceValidator, Validators.pattern(this.namePattern) ]],
  //     Email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
  //     CountryNameNCodes_FK_Id: ['1', Validators.required],
  //     Phone: ['', [Validators.required,Validators.pattern(this.phonePattern)]],
  //     ContactType_FK_Id: ['', Validators.required],
  //     Message: ['',[Validators.required]]   
  //  }); 
   this.SendMessegeValidate();
   this.aboutRequest.TitleKey = "about_contact";
   this.getAboutResult(this.aboutRequest); 
   this.getContactTypeResult(this.dropdownRequest);
   this.getSocialMediaLink(this.requestAuthentication);
   this.getCountryCodeResult(this.dropdownRequest);
 
  //  this.preview = this.addAnchorsToText(this.contactForm.controls.Message.value);
  }

  /** Get ContactType list for dropdown **/
  getContactTypeResult(dropdownRequest: any){
    this.dropdownRequest.Flag = "ContactType";
    this.dropdownRequest.LanguageTypeId = this.appLangId;
    this.footerService.getContactType(this.dropdownRequest)
    .subscribe(resp => {
      this.ContactTypeResult = resp.ResponseCollection;      
    });
  }

  /** Get CountryCode list for dropdown **/
  getCountryCodeResult(dropdownRequest: any){
  
    this.dropdownRequest.Flag = "CountryCode";
    this.dropdownRequest.LanguageTypeId = this.appLangId;
    this.footerService.getCountryCode(this.dropdownRequest)
    .subscribe(resp => {
      this.CountryCodeResult = resp.ResponseCollection;      
    });
  }

  get f() { return this.contactForm.controls; }

  SaveContact(){
  
      this.submitted = true;
      this.ContactUsRequest = this.contactForm.value;
      if (this.contactForm.invalid) {
        //console.log("invalid");
        return;
      }
      else
      {
          this.ContactUsRequest.LanguageTypeId = this.appLangId;
          this.ContactUsRequest = this.contactForm.value;
          this.URLmessage=this.ContactUsRequest.Message;
           if (this.URLmessage.match(this.regex)) 
           {
            this.messege=true;
              this.showmessage="URL not allow please type valid text."
              this.URLmessage='';
              
              setTimeout(() => {
                this.messege= false;
                this.submitted = false;
              },4000);
              return;
          }
        } 
        this.IsFormSubmit = true;
        this.ContactUsRequest.LanguageTypeId = this.appLangId;
        this.ContactUsRequest.ContactType_FK_Id=3;
          this.footerService.saveContactUs(this.ContactUsRequest)
          .subscribe(resp => {
            if(resp.ResponseStatus == 1)      {
              this.showSuccess= true;
              this.SendMessegeValidate();
              // this.contactForm = this.formBuilder.group({
              //   Name: ['',[Validators.required,spaceValidator, Validators.pattern(this.namePattern)]] ,
              //   Email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
              //   CountryNameNCodes_FK_Id: ['1', Validators.required],
              //   Phone: ['', [Validators.required,Validators.pattern(this.phonePattern)]],
              //   ContactType_FK_Id: ['', Validators.required],
              //   Message: ['', [Validators.required]]
            
              // });

              this.footerService = null;
              this.submitted = false;
              this.IsFormSubmit = false;
            
              setTimeout(() => {this.showSuccess= false;},4000);
            }

          });
    } 
  /** Get menu list for header **/
  getSocialMediaLink(requestAuthentication: RequestAuthentication){
    this.requestAuthentication.LanguageTypeId = this.appLangId;
    this.footerService.getSocialMediaLink(this.requestAuthentication)
    .subscribe(resp => {
      this.socialMediaLink = resp.Response;
    });
  }

  /** Get about result for contact page **/
  getAboutResult(aboutRequest: any){
 
    this.aboutRequest.LanguageTypeId = this.appLangId;
    this.footerService.getAboutResult(this.aboutRequest)
    .subscribe(resp => {
      this.AboutCollection = resp.ResponseCollection;
      if(this.aboutRequest.TitleKey == 'about_contact') {
        if(resp.ResponseCollection != null && resp.ResponseCollection != undefined && resp.ResponseCollection.length > 0) {
          this.ContactData = resp.ResponseCollection[0].AboutValue;
          //console.log(this.ContactData);
          this.aboutRequest.TitleKey = "about_location";
          this.getAboutResult(this.aboutRequest);
        }
      }
      else if(this.aboutRequest.TitleKey == 'about_location') {
        if(resp.ResponseCollection != null && resp.ResponseCollection != undefined && resp.ResponseCollection.length > 0) {
          this.LocationData = resp.ResponseCollection[0].AboutValue;
          this.aboutRequest.TitleKey = "about_visitus";
          this.getAboutResult(this.aboutRequest);
        }
      }
      else if(this.aboutRequest.TitleKey == 'about_visitus') {
        if(resp.ResponseCollection != null && resp.ResponseCollection != undefined && resp.ResponseCollection.length > 0) {
          this.VisitUsData = resp.ResponseCollection[0].AboutValue;
        }
      }
      
    });
  }

  clearForm() {
    this.contactForm.reset();
    this.submitted = false;
    this.SendMessegeValidate();
    // this.getCountryCodeResult(this.dropdownRequest);
   // this.ContactUsRequest.CountryNameNCodes_FK_Id=1;
    // this.contactForm.value.CountryNameNCodes_FK_Id=1;
  //   this.contactForm = this.formBuilder.group({
  //    CountryNameNCodes_FK_Id: ['1', Validators.required],   
  //  }); 
  
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  SendMessegeValidate(): void {
  this.contactForm = this.formBuilder.group({
    Name: ['',[Validators.required,spaceValidator, Validators.pattern(this.namePattern)]] ,
    Email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    CountryNameNCodes_FK_Id: ['1', Validators.required],
    Phone: ['', [Validators.required,Validators.pattern(this.phonePattern)]],
    ContactType_FK_Id: ['', Validators.required],
    Message: ['', [Validators.required]]

  });

}



}
