<style type="text/css">


</style>

<footer id="home_footer">

    <div id="site_footer" class="site_footer">
        <div class="container-home">
            <div class="row">
                <div class="col-lg-4">
                    <div class="footer_logo">
                        <img src="../../../../assets/img/logo.png?v={{imgversion}}" alt="">
                    </div>
                    <div class="social_links text-center">
                        <a href="https://www.facebook.com/kfh.takafulkwt/" target="_blank"><i
                                class="fa fa-facebook-official"></i></a>
                        <a href="https://twitter.com/kfhtakaful" target="_blank"><svg width="40" height="40"
                                viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_1_204327)">
                                    <path
                                        d="M23.7232 16.9373L38.2939 0H34.8412L22.1894 14.7064L12.0845 0H0.429688L15.7103 22.2387L0.429688 40H3.88267L17.2432 24.4695L27.9148 40H39.5696L23.7224 16.9373H23.7232ZM18.9939 22.4346L17.4457 20.2201L5.12683 2.59935H10.4304L20.3719 16.8199L21.9201 19.0344L34.8428 37.5189H29.5392L18.9939 22.4354V22.4346Z"
                                        fill="black" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_204327">
                                        <rect width="40" height="40" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></a>
                        <!-- <a href="https://twitter.com/kfhtakaful" target="_blank"><i class="fa fa-twitter"></i></a> -->
                        <a href="https://www.instagram.com/kfhtakaful/" target="_blank"><i
                                class="fa fa-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/kfh-takaful/" target="_blank"><i
                                class="fa fa-linkedin-square"></i></a>
                    </div>
                    <div class="appstore">

                        <a href="https://apps.apple.com/in/app/kfh-takaful/id1475208467" target="_blank"><img alt=""
                                src="assets/img/appstr.png"></a>
                        <a href="https://play.google.com/store/apps/details?id=kfh.takaful.app" target="_blank"><img
                                alt="" src="assets/img/gplay.png"></a>

                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="contact_info">
                        <h4>{{'Contact_Us' | translate}}</h4>

                        <p> <span class="arabicspan">{{'Phone' | translate}}</span> : <a href="tel:+9651803030"
                                class="fr_arbic">

                                <span style="padding-right: 5px;">(965)</span>
                                <span>1803030</span>
                            </a>
                        </p>
                        <p> <span class="arabicspan">{{'Email' | translate}}</span> : <a
                                href="mailto:contact-us@kfhtakaful.com" target="_blank">contact-us@kfhtakaful.com</a>
                        </p>
                        <p> <span class="arabicspan">{{'Fax' | translate}}</span> : <a>22917006/22917009</a> </p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="visit">
                        <h4>{{'visitus' | translate}}</h4>
                        <!-- <p>{{'KFH_Takaful_Individual_Insurance' | translate}}</p> -->
                    </div>
                    <div class="headoff">
                        <h6 class="">{{'KFH_Takaful_Individual_Insurance' | translate}}</h6>
                        <!-- <a href="https://goo.gl/maps/C9Es4tmweSMrcdtSA" target="_blank"><p>{{'Sharq, Riyad Center (Behind Al-Hamra Tower)'|translate}}</p></a> -->
                        <a href="https://maps.app.goo.gl/PcRXJnTrMAk1MQN4A" target="_blank">
                            <p>{{'Sharq, Riyad Center (Behind Al-Hamra Tower)'|translate}}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="foot_l">
        <div class="container-home">
            <h3>{{"Suggestions_And_Complaints"|translate}}</h3>

            <div class="contact-data" [innerHTML]="ContactData"></div>
            <!-- <p [innerHtml]="ContactData">Our clients can communicate with us by whatever means Telephone, Fax, e-mail or through the website. Our aim is always to support and help our clients to establish a successful business relationship. Below is the contact info of all departments. You can always expect a prompt reply from us. We look forward to hearing from you.</p> -->
            <!-- <div class="paraheight">
                    <div style="display: block;width: 285px;
                    display: -webkit-box;
                    max-width: 400px;
                    /* height: 135.2px; */
                    margin: 0 auto;
                    font-size: 26px;
                    line-height: 1.4;
                    /* -webkit-line-clamp: 6; */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;" [innerHtml]="ContactData">                    
                    </div>
                </div> -->
            <!-- <a href="javascript:void(0);"  data-target="#contactpopup" data-toggle="modal" class="btn  b1" style="background-color: #000000;margin-top: 20px;color: #fff !important;" [routerLink]="['/contact-us',5,1] | localize">{{"sendmessage"|translate}}</a> -->
            <button class="btn b1" data-target="#contactpopup" data-toggle="modal" id="btn1"
                style="background-color: #008b3e;margin-top: 20px;color: #fff !important;" type="button"
                (click)="clearForm()">{{'sendmessage'| translate}}</button>

        </div>
    </div>

    <div class="bottom_foot">
        <a href="javascript:void(0)" [routerLink]="['/webcontent/',1,'isweb'] | localize" class="text-white"
            target="_blank">{{'Terms_Conditions'|translate}}</a>
        <a href="javascript:void(0)" [routerLink]="['/webcontent/',2,'isweb'] | localize" class="text-white"
            target="_blank">{{'Privacy_Policy'|translate}}</a>
        <a href="javascript:void(0)" [routerLink]="['/webcontent/',10,'isweb'] | localize" class="text-white"
            target="_blank">{{'Complaints_Policy'|translate}}</a>
    </div>








    <!-- scrll-down -->


    <!-- <a href="javascript:void(0);" id="scrollTop" class="scrollTop" [ngClass]="{'scrll-down':scrolled}" (click)="ScrolTop()">
    
            <img style="width: 52px; height: 52px; position: fixed; bottom: 20px; right: 20px; z-index: 10; text-indent: -9999px; background: #098b41;
            border-radius: 3px; display: block" class="scrollup" src="../../../../assets/images/scrollTotop.png">
            </a> -->




    <!-- <div class="container">
        <div class="row">
            <div class="col-sm-4 footerleft">
                <p>{{'2019copyrightKFHTakaful_AllRightsReserved' | translate}}.</p>
                <p>&nbsp;</p>
                
                
            </div>

            <div class="col-sm-4 " style="text-align: center;">
                <ul class="sociallinks" style="margin-bottom: 0;">
                    <li class="hidden-lg mb-15-sm" style="    font-size: 12px;"> <a href="javascript:void(0)" [routerLink]="['/webcontent/',1,'isweb'] | localize" class="mr15 text-white">{{'Terms_Conditions'|translate}}</a>
                   <a href="javascript:void(0)" [routerLink]="['/webcontent/',2,'isweb'] | localize" class="text-white">{{'Privacy_Policy'|translate}}</a></li>
                    <li class="sociallinks-item"><a class="sociallinks-link-facebook" target="_blank" href="{{socialMediaLink?.FbLinks}}"><i class="fa fa-facebook"></i></a></li>
                    <li class="sociallinks-item"><a class="sociallinks-link-twitter" target="_blank" href="{{socialMediaLink?.TwitterLinks}}"><i class="fa fa-twitter"></i></a></li>
                    <li class="sociallinks-item"><a class="sociallinks-link-linkedin" target="_blank" href="{{socialMediaLink?.InstagramLinks}}"><i class="fa fa-instagram"></i></a></li>
                    <li class="sociallinks-item"><a class="sociallinks-link-linkedin" target="_blank" href="{{socialMediaLink?.LinkedInLinks}}"><i class="fa fa-linkedin"></i></a></li>
                
                 
                </ul>
                <a href="http://intigate.co.in/" target="_blank" class="hidden-lg">
                    <p class="mt10">{{'Designed_Developed_By_Intigate_Technologies' | translate}}</p>
                </a>
                <div class="footer-collapse"></div>
            </div>
            <div class="col-sm-4 footerright hidden-xs">
                    <a href="javascript:void(0)" [routerLink]="['/webcontent/',1,'isweb'] | localize" class="mr15 text-white">{{'Terms_Conditions'|translate}}</a>
                    <a href="javascript:void(0)" [routerLink]="['/webcontent/',2,'isweb'] | localize" class="text-white">{{'Privacy_Policy'|translate}}</a>
                </div>
            <div class="col-sm-4 footerright">
                <div class="appstore">
                        
                    <a href="https://apps.apple.com/in/app/kfh-takaful/id1475208467"><img alt="" src="assets/img/appstr.png"></a>
                    <a href="https://play.google.com/store/apps/details?id=kfh.takaful.app"><img alt="" src="assets/img/gplay.png"></a>
                    
                </div>
            </div>
        </div>
    </div> -->







    <a class="scrollTop" href="javascript:void(0);"><img class="scrollup"
            src="../../../../assets/img/scrollTotop.png?v={{imgversion}}"
            style="width: 52px; height: 52px; position: fixed; bottom: 20px; right: 20px; z-index: 10; text-indent: -9999px; background: rgb(9, 139, 65); border-radius: 3px; display: block;"></a>

    <a href="https://api.whatsapp.com/send?phone=+96598555710" target="_blank" class="wsbapm">
        <span class="img_w"><img src="../../../../assets/img/whatsapp.png?v={{imgversion}}"></span>
        <span class="txt_w">TAP TO WHATSAPP</span>
    </a>




</footer>

<!-- Modal -->
<div class="modal fade" id="contactpopup" tabindex="-1" role="dialog" aria-labelledby="contactpopupLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="contactpopupLabel">{{'Enquiry' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    style="margin: -22px 0px 0px 0px;" (click)="clearForm()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class=" backg_end active" id="contact_add">
                    <form action="javascript:void(0);" [formGroup]="contactForm" id="contact_us" class=""
                        (ngSubmit)="SaveContact()">
                        <div class="form-group">
                            <input type="text" name="cname" class="form-control" placeholder="{{'Name' | translate}}"
                                id="cname" formControlName="Name"
                                [ngClass]="{ 'is-invalid': submitted  && f.Name.errors }" maxlength="50">

                        </div>
                        <div class="form-group">
                            <input type="text" name="cemail" class="form-control" placeholder="{{'Email' | translate}}"
                                id="cemail" formControlName="Email"
                                [ngClass]="{ 'is-invalid': submitted && f.Email.errors }">

                        </div>
                        <div class="form-group">
                            <div class="d-flex row">
                                <div class="col-md-3 padding-right-none pr0md"
                                    style="margin-bottom: 10px; min-width: 100px;">
                                    <select class="form-control arrow-hidden" id="drpcountrycode" name="cucountrycode"
                                        formControlName="CountryNameNCodes_FK_Id"
                                        [ngClass]="{ 'is-invalid': submitted && f.CountryNameNCodes_FK_Id.errors }"
                                        disabled>

                                        <option value="{{code.Value}}" *ngFor="let code of CountryCodeResult">
                                            {{code.Text}}</option>

                                    </select>

                                </div>
                                <div class="col-md-9" style="width: 100%;">
                                    <input type="text" name="cphone" class="form-control"
                                        placeholder="{{'Phone_Number' | translate}}" minlength="8" maxlength="12"
                                        formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': submitted && f.Phone.errors }"
                                        (keypress)="numberOnly($event);">

                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <select class="form-control" name="messegetype" formControlName="ContactType_FK_Id"
                                [ngClass]="{ 'is-invalid': submitted && f.ContactType_FK_Id.errors }">
                                <option value="">{{'SelectType' | translate}}</option>
                                <option value="{{ccType.Value}}" *ngFor="let ccType of ContactTypeResult">
                                    {{ccType.Text}}</option>

                            </select>

                            <input name="hdnMessageTypeText" type="hidden">
                            <p class="text-danger"></p>
                        </div>
                        <div class="form-group">
                            <textarea name="ccontent" style="min-height: 70px !important;" class="form-control" rows="3"
                                placeholder="{{'Message' | translate}}" id="ccontent" maxlength="256"
                                formControlName="Message" [ngClass]="{ 'is-invalid': submitted && f.Message.errors }">
                                    </textarea>
                            <span *ngIf="submitted==true && messege == true"
                                class="show_msg">{{'showmessage'|translate}}</span>
                            <!-- <div *ngIf="f.Message.touched && f.Message.invalid" class="alert alert-danger">
                                        
                                        <div *ngIf="f.Message.errors.pattern">You have enter a URL.</div>
                                    </div> -->

                        </div>
                        <div id="success" *ngIf="showSuccess == true">{{'QuerySubmittedSuccessfully' | translate}}</div>
                        <div class="response" id="cresponse" style="text-align: right; margin: 0;"></div>
                        <button type="submit" class="btn btn-primary b1" id="btn1" *ngIf="IsFormSubmit == false"
                            style="background-color: #098b41;">{{'sendmessage' | translate}}</button>
                        <a href="javascript:void(0)" class="btn btn-primary b1"
                            *ngIf="IsFormSubmit == true && messege==true"
                            style="background-color: #098b41;">{{'sendmessage' | translate}}...</a>
                    </form>
                </div>
            </div>
            <div class="modal-footer hidden">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Close' | translate}}</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>