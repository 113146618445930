import { Injectable } from '@angular/core';
import { JsonApiService } from './json-api.service';

import { RequestAuthentication } from '../models/request-authentication.model';
import { ApiService } from './base-api/api.service';
import { Observable, from } from 'rxjs';
import { config } from '../app-config';


@Injectable({
    providedIn: 'root'
})
export class CouponService {

    constructor(
        private jsonApiService: JsonApiService,
        private apiService: ApiService) { }

  ApplyPromoCode(applyCouponRequest): Observable<any> {
      
    return this.apiService.post(config.apiCoupon + "/applycoupontravel", applyCouponRequest);
}
ApplyCarCoupon(applyCouponRequest): Observable<any> {
    
  return this.apiService.post(config.apiCoupon + "/ApplyCarCoupon", applyCouponRequest);
}




}
