<!-- Modal content-->


<form action="javascript:void(0);" [formGroup]="loginForm" id="login-form" class=""
  (ngSubmit)="LoginWithPolicyPurchase()" autocomplete="off">
  <div class="modal-body login_modal">
    <div class="form-group" style="margin-bottom: 10px;">
      <div class="row">
        <!-- <div class="col-md-12 pr0md" style="margin-bottom: 10px;">
                  <label>{{child.CountryCode}}-{{child.MobileNo}}</label>
                </div> -->
        <div class="col-md-12">
          <!--class="col-md-12" <a href="javascript:void(0);" (click)="ChangeMobileNumber()" style="position: absolute;right: 17px; top: 0;color: #006FFF;font-weight: 600;font-size: 14px;">Change</a> -->



          <!-- <input style="margin-bottom: 11px;" type="text" name="cphone" class="form-control"  placeholder="{{child.CountryCode}}-{{child.MobileNo}}"
                    > -->

          <!-- <div class="col-md-3 pr0md">
                      <select style="background: #f9f9f9;" class="form-control" id="drpcountrycode" name="cucountrycode" formControlName="CountryCodes_Fk_Id"
                        [ngClass]="{ 'is-invalid': submitted && f.CountryCodes_Fk_Id.errors }">
                        <option value="{{code.Value}}" *ngFor="let code of CountryCodeResult">{{code.Text}}</option>

                      </select>
                    </div> -->


          <label class="">{{'Mobile_No' | translate}}</label>
          <div [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }">
            <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
              [separateDialCode]="true" [enablePlaceholder]="true" [maxLength]="10"
              [selectedCountryISO]="selectedCountryISO" [phoneValidation]="false" name="phone"
              formControlName="MobileNo" (keypress)="firstWhiteSpace($event)" [readonly]="IsShowRighrIcon">
            </ngx-intl-tel-input>
          </div>
          <!-- <div class="col-md-12 d-flex inpfcs p-0" style="border-left: 1px solid transparent;direction: ltr;">
           
            <div>
              <select name="" style="min-width: 53px;padding: 6px;" class="form-control" disabled>
                <option value="">+ 965</option>
              </select>             
            </div>
            <input type="text" id="inputphone" name="cphone" class="form-control" placeholder="" minlength="8"
              maxlength="12" formControlName="MobileNo" pattern="[0-9]*" [ngClass]="{ 'is-invalid': submitted && f.MobileNo.errors }" (keypress)="numberOnly($event)" [readonly]="IsShowRighrIcon">
              <img *ngIf="IsShowRighrIcon" src="../../../../../assets/img/RightIcon.png"  class="ShowMobileRighrIconEn" >
             </div> -->
        </div>

        <div class="clearfix mb15"></div>

        <div class="col-md-12">
          <label>{{'Enter_password' | translate}}</label>
          <input type="password" id="inputpassword" name="cpassword" class="form-control passwrd_bx" placeholder=""
            formControlName="Password" [ngClass]="{ 'is-invalid': submitted && f.Password.errors }"
            autocomplete="new-password">
          <a class="Forgotlnk" href="javascript:void(0);" style="" (click)="ForgotPassword()">{{'Forgot' | translate}}</a>
          <!-- <label class="response" id="cresponse"
          style="text-align: left;color:red;font-weight: 500;font-size: 12px;">{{'Password_Formate'|translate}}</label> -->
        </div>

      </div>
    </div>
    <div class="response" id="cresponse"
      style="text-align: left;color:red;font-weight: 500;font-size: 12px; margin-top: 10px;">
      {{ErrorMessage}}</div>

    <div class="clearfix mb10"></div>

    <div class="modal-footer">
      <button type="submit" class="btn btn-primary b1 detailsbtn" id="btn1" *ngIf="IsFormSubmit == false"
        style="background-color: #098b41;">{{'Continue' | translate}}</button>
      <a href="javascript:void(0)" class="btn btn-primary b1 detailsbtn processing" *ngIf="IsFormSubmit == true"
        style="background-color: #098b41;">{{'Processing' | translate}}</a>

    </div>

  </div>
</form>