import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { ToastrModule } from 'ngx-toastr';
import { CoreModule } from '@app/core';

import {  HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import { TokenInterceptor,ErrorInterceptor } from './core/interceptors';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MobileModalComponent,VerifyOtpComponent} from './modules/modal';
import { LoginModalComponent } from './modules/modal/pages/login/login.component'
import { CustomerDetailsComponent} from './modules/modal/pages/customer-details/customer-details.component';
import { ForgotPasswordComponent } from './modules/modal/pages/forgot-password/forgot-password.component';
import { AutofocusDirective } from './autofocus.directive';
import { ResetPasswordComponent } from './modules/modal/pages/reset-password/reset-password.component';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorModule } from 'ngx-device-detector';
import{AboutCmsPageComponent}from './shared/components/about-insurance/about-cms/about-cms.component';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { UrlBlockerDirective } from './shared/components/url-blocker-directive';
import { SafeHtmlPipe } from './shared/directives/safe-html';
// import { PageNotFoundComponent} from './modules/page-not-found/pages/page-not-found.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${env.appUrl}assets/i18n/`, '.json'); 
}
@NgModule({
  imports: [
    ModalModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    BrowserModule,
    ToastrModule.forRoot({
            timeOut: 4000,
            closeButton:true,
            positionClass: 'toast-top-center',
            preventDuplicates: true,
    }),
    // app
    AppRoutingModule,    
    // core & shared
    CoreModule,
    SharedModule,
   
    NgxIntlTelInputModule,
    // 3rd party 
    BrowserAnimationsModule,
    // // Material
    // MatToolbarModule,
    // MatProgressBarModule,
    // MatButtonModule,
    // MatCardModule
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    
    SlimLoadingBarModule.forRoot()
  ],
  declarations: [
    MobileModalComponent,
    VerifyOtpComponent,
    LoginModalComponent,
    CustomerDetailsComponent,
    AppComponent,
    AuthLayoutComponent, 
    ForgotPasswordComponent, 
    AutofocusDirective, 
    ResetPasswordComponent,
    AboutCmsPageComponent,
    UrlBlockerDirective,
    SafeHtmlPipe
    // PageNotFoundComponent,
  ],
  entryComponents: [
    CustomerDetailsComponent,
    MobileModalComponent
  ],
  exports: [
    TranslateModule,
    CustomerDetailsComponent,
    SafeHtmlPipe    
  ],
   providers: [    
    //  ModalService,
    
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // 
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  } }
