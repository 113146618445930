import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes,RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMasonryModule } from 'ngx-masonry'; 
// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

import { MatButtonModule, MatCardModule, MatProgressBarModule, MatToolbarModule } from '@angular/material';
//import { NumberDirective } from './directives/numbers-only.directive';
import { LayoutHeaderComponent } from './components/layout-header/header.component';
import { LayoutFooterComponent } from './components/layout-footer/footer.component';
 
import { ControlMessagesComponent } from './components/control-messages/control-messages.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FilterPipe } from './directives/filter.pipe';
const homeRoutes: Routes = [ 
   
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule.forRoot(),
        FontAwesomeModule,
        TranslateModule.forChild(),
        LocalizeRouterModule.forChild(homeRoutes),
        // // Material
        // MatToolbarModule,
        // MatProgressBarModule,
        // MatButtonModule,
        // MatCardModule
    ],
    declarations: [
      //ModalComponent,
      ControlMessagesComponent,
      SpinnerComponent,
      LayoutHeaderComponent,
      LayoutFooterComponent,
      //NumberDirective,
      FilterPipe
    ],
    providers: [LayoutHeaderComponent, LayoutFooterComponent],
    exports: [ 
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      MaterialModule,
      NgbModule,
      FontAwesomeModule,
      NgxMasonryModule,  
      ControlMessagesComponent,
      SpinnerComponent,      
      LayoutHeaderComponent,
      LayoutFooterComponent,
      FilterPipe
    ]
})
export class SharedModule { }
