import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  // styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
// // import { Component, OnInit } from '@angular/core';
// import { Component, OnInit } from '@angular/core';
// import { Router, ActivatedRoute } from '@angular/router';
// import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';

// import { ILoginContext,AuthService } from '../../core/services/auth-api/auth.service';

// @Component({
//   selector: 'app-auth-layout',
//   templateUrl: './auth-layout.component.html',
//   //styleUrls: ['./auth-layout.component.scss']
// })

 

// export class AuthLayoutComponent implements OnInit {
//   loginForm: FormGroup;
//     loading = false;
//     submitted = false;
//     returnUrl: string;
//     error = '';





//   constructor(private formBuilder: FormBuilder,
//     private route: ActivatedRoute,
//     private router: Router,
//     private authenticationService: AuthService) {}

// ngOnInit() {
//     this.loginForm = this.formBuilder.group({
//         username: ['', Validators.required],
//         password: ['', Validators.required]
//     });

//     // reset login status
//     this.authenticationService.logout();

//     // get return url from route parameters or default to '/'
//     this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
// }

// // convenience getter for easy access to form fields
// get f() { return this.loginForm.controls; }

// onSubmit() {
//     this.submitted = true;

//     // stop here if form is invalid
//     if (this.loginForm.invalid) {
//         return;
//     }

//     this.loading = true;

//    let loginContext = {Email:this.f.username.value,Password : this.f.password.value};

//     this.authenticationService.login(loginContext)
//         .pipe(first())
//         .subscribe(
//             data => {
//                 this.router.navigate([this.returnUrl]);
//             },
//             error => {
//                 this.error = error;
//                 this.loading = false;
//             });
//   }

// }
