
<div class="" [ngClass]="{'arabic':isArabic,'english':!isArabic}"> 
 

<app-layout-header *ngIf="!ismobile"></app-layout-header> 
<ng2-slim-loading-bar></ng2-slim-loading-bar>
<div style="min-height: calc(100vh - 220px); display: block;">
    <router-outlet class="layout-content-panel onload_img_window" (activate)="onActivate($event)"></router-outlet>
</div> 
      
<app-layout-footer *ngIf="!ismobile"></app-layout-footer>
<!-- <div class="onload_modal">
    <div class="modal fade in" [ngClass]="{ 'close_modalimg': IsPopupLoaded }"  id="myModal" role="dialog">
      <div class="modal-dialog">   
        <div class="modal-content">        
          <div class="modal-body">
              <button class="close_btn" id="onloadClose" (click)="CloseOnload();" >
                  <img  alt="" src="assets/img/new-close.svg">
              </button>
            <div class="modal_img_box">
              <img src="../../../../../assets/img/pdf-img.PNG">
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </div> -->
</div>


<!-- <div class="onload_pdf_img " >
    <div class="img_inner">
     <div class="img_box">
       <img src="../../../../../assets/img/pdf-img.PNG">
     </div>
     <span class="close_btn">
       <i class="fa fa-times"></i>
     </span>
    </div>
  </div> -->


