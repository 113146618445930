import { Injectable ,OnDestroy} from '@angular/core';
import { environment as env } from '@env/environment';
import { BehaviorSubject, of, Observable, throwError } from 'rxjs';
import { User } from '../../../core/models/user.model';
import {ApiService} from '../base-api/api.service';
import { config } from '../../app-config';
import { map } from 'rxjs/operators';
import { CurrentAccessToken } from '../../../core/classes'; 
// import { CurrentAccessToken } from './current-access-token.service'; 

export class ILoginContext {
  Email: string;
  Password: string;
  }
 
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy{
  authRequest= new User();
  token: string; 
  // private currentUserSubject: BehaviorSubject<User>;
  // private currentUser: Observable<User>;

  constructor( private apiBase: ApiService,private currentAccessToken:CurrentAccessToken) { 
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(env.localStorageKeyName)));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  ngOnDestroy() { 
    // this.currentUserSubject.next(null);
    // this.currentUserSubject.complete();
  }
//  public get currentUserValue(): User {
//   //  
//         return this.currentUserSubject.value;
//     }

   login(loginContext: ILoginContext): Observable<any> {  
     //console.log(loginContext);
      // return this.apiBase.post(config.apiAuthentication+'/login', loginContext)     
        return this.apiBase.post(config.apiAuthentication+'/CustomerLogin', loginContext)
      .pipe(map(user => {
        // 
          // login successful if there's a jwt token in the response
          if (user && user.Response && user.ResponseStatus == 1) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));  this.currentAccessToken.loggedInUserSubject.next(user.Response);        
          }
          else {
              localStorage.removeItem(env.localStorageKeyName);
          }
          return user;
      })); 
    return throwError('Invalid username or password');
 
  
 
  }

  /** login during policy purchasing **/
  loginWithPolicyPurchase(request):Observable<any>{
    return this.apiBase.post(config.apiAuthentication+'/LoginWithPolicyPurchase', request)
    .pipe(map(user => { 
        // login successful if there's a jwt token in the response
        if (user && user.Response && user.ResponseStatus == 1) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));    
            this.currentAccessToken.loggedInUserSubject.next(user.Response);        
        }
        else {
            localStorage.removeItem(env.localStorageKeyName);
        }
        return user;
    })); 
  return throwError('Invalid username or password');
  }

  loginWithPolicyPurchaseWeb(request):Observable<any>{
    return this.apiBase.post(config.apiAuthentication+'/LoginWithPolicyPurchaseWeb', request)
    .pipe(map(user => { 
        // login successful if there's a jwt token in the response
        if (user && user.Response && user.ResponseStatus == 1) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));    
            this.currentAccessToken.loggedInUserSubject.next(user.Response);        
        }
        else {
            localStorage.removeItem(env.localStorageKeyName);
        }
        return user;
    })); 
  return throwError('Invalid username or password');
  }

  /** Verify otp for mobile number verification during policy purchasing **/
  verifyOtp(request):Observable<any>{
    return this.apiBase.post(config.apiAuthentication+'/VerifyOtp', request)
    .pipe(map(user => { 
        // login successful if there's a jwt token in the response
        if (user && user.Response && user.ResponseStatus == 1) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));   
            this.currentAccessToken.loggedInUserSubject.next(user.Response);        
        }
        else {
            localStorage.removeItem(env.localStorageKeyName);
        }
        return user;
    }));
    //return this.apiService.post(config.apiAuthentication+"/VerifyOtp",request);
  }

  /** Update customer details **/
  updateCustomerDetails(request):Observable<any>{
    return this.apiBase.post(config.apiAuthentication+'/UpdateCustomerDetails', request)
    .pipe(map(user => { 
        // login successful if there's a jwt token in the response
        if (user && user.Response && user.ResponseStatus == 1) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));   
            this.currentAccessToken.loggedInUserSubject.next(user.Response);        
        }
        else {
            localStorage.removeItem(env.localStorageKeyName);
        }
        return user;
    }));
    //return this.apiService.post(config.apiAuthentication+"/UpdateCustomerDetails",request);
  }

  /** Customer registration **/
  CustomerRegistration(request):Observable<any>{
    return this.apiBase.post(config.apiAuthentication+"/CustomerRegistration",request);
  }

  /** ResetPassword **/
  ResetPassword(request):Observable<any>{
    //return this.apiBase.post(config.apiAuthentication+"/ResetPassword",request);
    return this.apiBase.post(config.apiAuthentication+'/ResetPassword', request)
      .pipe(map(user => {
         
          // login successful if there's a jwt token in the response
          if (user && user.Response && user.ResponseStatus == 1) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));    this.currentAccessToken.loggedInUserSubject.next(user.Response);        
          }
          else {
              localStorage.removeItem(env.localStorageKeyName);
          }
          return user;
      })); 
    return throwError('Invalid username or password');
  }

  // logout(): Observable<boolean> {
  //     // remove user from local storage to log user out
  //     localStorage.removeItem(env.localStorageKeyName);
  //     this.currentUserSubject.next(null);
  //   return of(true);
  // }

  // getToken() {
  //   return this.currentUserValue?this.currentUserValue.AccessToken.access_token:"";
 
  //  // return this.getToken;
  // }

  // getTokenExpirationDate(token: string): Date {
  //   const decoded = jwt_decode(token);
  //   if (decoded.exp === undefined) return null;
  //   const date = new Date(0);
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }

  // isTokenExpired(token?: string): boolean {
   
  //   var isExpired = this.checkTokenExpiry(token);    
  //   /** do logout on token expiry..*/
  //   if (isExpired) {
  //     this.logout();
  //   }
  //   /** return true or false...*/
  //   return isExpired;
  // }

  // private checkTokenExpiry(token?: string): boolean {
  //   if (!token) token = this.getToken();
  //   if (!token) return true;

  //   const date = this.getTokenExpirationDate(token);
  //   if (date === undefined) return false;
  //   return !(date.valueOf() > new Date().valueOf());
  // }


  /** ResetPassword **/
  ConfirmEmail(request):Observable<any>{
    //return this.apiBase.post(config.apiAuthentication+"/ResetPassword",request);
    return this.apiBase.post(config.apiAuthentication+'/ConfirmEmail', request)
      .pipe(map(user => {
          //this commented code removing the existing storge account  
          // // login successful if there's a jwt token in the response
          // if (user && user.Response && user.ResponseStatus == 1) {
          //     // store user details and jwt token in local storage to keep user logged in between page refreshes
          //     localStorage.setItem(env.localStorageKeyName, JSON.stringify(user.Response));    this.currentAccessToken.loggedInUserSubject.next(user.Response);        
          // }
          // else {
          //     localStorage.removeItem(env.localStorageKeyName);
          // }
          return user;
      })); 
    return throwError('Invalid username or password');
  }

}
