import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HomeService, CurrentLanguage } from '@app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-about-cms-page',
  templateUrl: './about-cms.component.html',
  styleUrls: ['./about-cms.component.css']
})
export class AboutCmsPageComponent extends CurrentLanguage implements OnInit, AfterViewInit {
  aboutRequest = { LanguageTypeId: 1, TitleKey: "" };
  typeId: string;
  titleKey: string;
  aboutDescription: string;
  aboutDescriptionData: string;
  Title: string;
  IsMobile;
  IsShowAboutUsData: boolean;
  showMergerData:boolean;
  IsLoaded: boolean = false;
  constructor(private homeService: HomeService, private activerout: ActivatedRoute, private translate: TranslateService, ) {
    super(translate);
  }

  ngOnInit() {
    // alert();
    // this.typeId = this.activerout.snapshot.paramMap.get('cmstype');
    this.IsShowAboutUsData = false;
    this.showMergerData=false;
    this.Title = "";
    this.aboutDescription = "";
  }
  ngAfterViewInit() {
    this.activerout.params.subscribe(params => {
      this.typeId = params["cmstype"];
      this.IsMobile = params["ismobile"];
      if ((this.typeId == "1" || this.typeId == "2" || this.typeId == "3" || this.typeId == "4"|| this.typeId == "10") && (this.IsMobile == "ismobile" || this.IsMobile == "isweb")) {
        this.IsShowAboutUsData = false;
        this.GetAboutUsDetails();
      }
      else {
        this.IsShowAboutUsData = true;
      }
    })
  }

  GetAboutUsDetails() {
    if (this.typeId == "1" && (this.IsMobile == "ismobile" || this.IsMobile == "isweb")) {
      this.titleKey = "terms_and_condition";
      this.Title=this.translate.instant('Terms_Conditions');
      // this.Title = "Terms & Condition";
    }
    else if (this.typeId == "2" && (this.IsMobile == "ismobile" || this.IsMobile == "isweb")) {
      this.titleKey = "privacy_policy";
      this.Title=this.translate.instant('Privacy_Policy');
      // this.Title = "Privacy Policy";
    }
    else if (this.typeId == "3" && this.IsMobile == "ismobile") {
      this.titleKey = "about_visitus_and_about_contact";
    }
    else if (this.typeId == "4" && this.IsMobile == "ismobile") {
      this.titleKey = "about_visitus_and_about_contact";
      this.Title=this.translate.instant('reachusanytime')
    }
    else if (this.typeId == "10" && (this.IsMobile == "ismobile" || this.IsMobile == "isweb")) {
      this.titleKey = "complaints_policy";
      this.Title=this.translate.instant('Complaints_Policy')
    }
    this.aboutRequest.TitleKey = this.titleKey;
    this.aboutRequest.LanguageTypeId = this.appLangId;
    var response = this.homeService.GetAboutUsDetails(this.aboutRequest);
    response
    .pipe(finalize(() =>{       
        setTimeout(() => {        
          this.IsLoaded=true;
        }, 1000);             
      }))
      .subscribe(resp => {
        if (this.titleKey == "about_visitus_and_about_contact") {
          this.aboutDescription = resp.ResponseCollection[0].Description;
          this.aboutDescriptionData = resp.ResponseCollection[1].Description;
          this.showMergerData=true;
        }
        else {
          this.aboutDescription = resp.ResponseCollection[0].Description;
        }
      });
  }

}
