import { NumberFormatStyle, NumberSymbol } from '@angular/common';
export class TplMotorInformationResult {    
    TplPolicyType_Fk_Id: number;
    TplUsage_Fk_Id: number;
    TplUsageSubType_Fk_Id: number;
    TplModel_Fk_Id: number;
    TplPolicyPeriod_Fk_Id: number;
    PolicyStartDate: string;
    PolicyEndDate: string;    
    CarNumber: string;
    ChasisNumber: string;
    TplShape_Fk_Id: number;
    TplManufacturer_Fk_Id: number;
    TplPassenger_Fk_Id: number;
    TplFuelType_Fk_Id: number;
    PrimaryColor: string;
    SecondaryColor: string;
    TplModelType_FK_Id: number;
    OnlyPolicyPrice: number;
}

export class TplMotorStep2InformationResult {   
    
    CarNumber: string;
    ChasisNumber: string;
    TplShape_Fk_Id: number;
    TplManufacturer_Fk_Id: number;
    TplManufacturer:string;
    TplFuelType_Fk_Id: number;
    PrimaryColor: string;
    SecondaryColor: string;
    TplModelType_FK_Id: number;
    OnlyPolicyPrice: number;
    ReferenceNumber: string;
    TplPrimaryColor_Fk_Id: number;
    TplSecondaryColor_Fk_Id: number;
}

export class PolicStep1Request {
    LanguageTypeId: number;
    RequestedId: string;
    TplPolicyType_Fk_Id: number;
    TplUsage_Fk_Id: number;
    TplUsageSubType_Fk_Id: number;
    TplModel_Fk_Id: number;
    TplPolicyPeriod_Fk_Id: number;
    PolicyStartDate: string;
    PolicyEndDate: string;
    CarNumber: string;
    ChasisNumber: string;
    TplShape_Fk_Id: number;
    TplManufacturer_Fk_Id: number;
    TplPassenger_Fk_Id: number;
    TplFuelType_Fk_Id: number;
    PrimaryColor: string;
    SecondaryColor: string;
}

export class PolicStep2Request {
    LanguageTypeId: number;
    RequestedId: string;
    CarNumber: string;
    ChasisNumber: string;
    TplShape_Fk_Id: number;
    TplManufacturer_Fk_Id: number;
    TplPassenger_Fk_Id: number;
    TplFuelType_Fk_Id: number;
    PrimaryColor: string;
    SecondaryColor: string;
}

export class TplPersonalInformationResult {
    FullName: string;
    Nationality_Fk_Id: number;
    CivilId: string;    
    MobileNumber: string;
    EmailAddress: string;
    TrafficGovernorate_Fk_Id: number;
    TplAreas_Fk_Id: number;
    Area: string;
    Block: string;
    Street: string;
    HouseNo: string;
}

export class TplMotorReviewPersonalInformation {
    FullName: string;
    CivilId: string;
    Area: string;
    Block: string;
    Street: string;
    HouseNo: string;
    MobileNo: string;
    EmailId: string;
}

export class TplMotorReviewInformation {
    TypeOfPolicy: string;
    Usage:string;
    ModelNo: string;
    PolicyPeriod: string;
    CarNumber: string;
    ChasisNumber: string;
    VehicleShape: string;
    Manufacturer: string;
    ModelType: number;
    NoOfPassenger: string;
    FuelType: string;
    PrimaryColor: number;
    SecondaryColor: string;
    OnlyPolicyPrice: number;
    SupervisionFees: number;
    IssuesFees: number;
    ExtraFees: number;
    NetPrice: number;
    ReferenceNumber:string;
    IsCouponAvailable:number; 
    CouponCode: string;
    CouponDiscount:number;
}